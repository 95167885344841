import React, { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import * as d3 from 'd3';
import * as dc from 'dc';
import { takeLastMonth } from './filters';
import { useMediaQuery } from 'react-responsive';

export const ReadingsGraph = ({ data }) => {
  const isTablet = useMediaQuery({ maxDeviceWidth: 1139 });
  const isMobile = useMediaQuery({ maxDeviceWidth: 767 });
  const xTicks = useMemo(() => (isMobile ? 5 : isTablet ? 10 : undefined), [isTablet, isMobile]);
  const ref = useRef();

  useEffect(() => {
    const dimension = data.dimension(item => item.timestamp);
    const group = dimension.group().reduceSum(item => item.weight);

    const chart = dc
      .lineChart(ref.current)
      .height(300)
      .x(d3.scaleBand())
      .xUnits(dc.units.ordinal)
      .elasticY(true)
      .clipPadding(10)
      .renderArea(true)
      .dimension(dimension)
      .group(takeLastMonth(group, 'key'));

    chart
      .xAxis()
      .tickFormat((date, i, elements) =>
        !xTicks || i % Math.floor(elements.length / xTicks) === 0 ? moment(date).format('MMM D') : null
      );
    chart.yAxis().tickFormat(weight => `${weight}oz`);

    chart.render();
  }, [ref, xTicks, data]);

  return <div ref={ref}></div>;
};

ReadingsGraph.propTypes = {
  data: PropTypes.object.isRequired,
};
