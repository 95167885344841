import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';

export const SwitchSuccess = () => {
  const history = useHistory();

  const onGoToProfile = useCallback(() => history.push('/'), [history]);

  return (
    <>
      <div className="mb-4">
        Your subscription has been updated to Subscription by Usage. You can expect a shipment of smart re-ordering
        scale soon.
      </div>
      <Button color="primary" outline onClick={onGoToProfile}>
        Go to Profile
      </Button>
    </>
  );
};
