import { useDataEffect } from '@bottomless/common/hooks';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UserStatuses } from '@bottomless/common/constants';
import {
  acceptPhoneAction,
  getMeAction,
  orderingAggresionAction,
  pauseAccountAction,
  rejectPhoneAction,
  setDumbPeriodAction,
} from '../../../store/user';
import { requestScaleReturnAction } from '../../../store/scale';
import { getInTransitionCountAction, getUpcomingOrderAction } from '../../../store/order';
import { optimisticLineItemsAmountsUpdateAction, updateLineItemsAmountsAction } from '../../../store/product';

export const useMe = () => {
  const dispatch = useDispatch();
  const action = useCallback(() => dispatch(getMeAction()), [dispatch]);
  const { data } = useSelector(({ user }) => ({ data: user.me }));

  const { error, isFetching: isLoading } = useDataEffect(action);

  const setOrderingAggression = useCallback(data => dispatch(orderingAggresionAction(data)), [dispatch]);

  const pauseAccount = useCallback(data => dispatch(pauseAccountAction(data)), [dispatch]);

  const setDumbPeriod = useCallback(
    async (data, place) => {
      const result = await dispatch(setDumbPeriodAction(data, place));

      await dispatch(getMeAction());

      await Promise.all([dispatch(getUpcomingOrderAction()), dispatch(getInTransitionCountAction())]);

      return result;
    },
    [dispatch]
  );

  const acceptPhone = useCallback(() => dispatch(acceptPhoneAction()), [dispatch]);
  const rejectPhone = useCallback(() => dispatch(rejectPhoneAction()), [dispatch]);

  const requestScaleReturn = useCallback(() => dispatch(requestScaleReturnAction()), [dispatch]);

  const isClosed = useMemo(() => data?.hibernate || data?.status === UserStatuses.Closed, [data]);

  const updateLineItemsAmounts = useCallback(data => dispatch(updateLineItemsAmountsAction(data)), [dispatch]);

  const optimisticLineItemsAmountsUpdate = useCallback(data => dispatch(optimisticLineItemsAmountsUpdateAction(data)), [
    dispatch,
  ]);

  return {
    data,
    isLoading,
    error,
    isClosed,
    setOrderingAggression,
    pauseAccount,
    setDumbPeriod,
    requestScaleReturn,
    acceptPhone,
    rejectPhone,
    updateLineItemsAmounts,
    optimisticLineItemsAmountsUpdate,
  };
};
