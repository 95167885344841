import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { PanelSection } from '../../../components/PanelSection';
import { SwitchToFixedSubscription } from './SwitchToFixedSubscription';
import { YourScale } from './YourScale';
import { useToggle } from '@bottomless/common/hooks';
import { useState } from 'react';
import { useEffect } from 'react';

export const YourScaleSection = ({
  me,
  stats,
  scaleStatus,
  cleanData,
  setOrderingAggression,
  getNextOrderDateFromNow,
  setDumbPeriod,
  requestScaleReturn,
}) => {
  const [originalDumbPeriod, setOriginalDumbPeriod] = useState(me.dumb_period);
  const [isOpen, toggle] = useToggle();

  useEffect(() => {
    if (!isOpen) {
      setOriginalDumbPeriod(me.dumb_period);
    }
  }, [me, isOpen]);

  if (originalDumbPeriod) {
    return null;
  }

  return (
    <PanelSection title="Your Scale" className={classNames('section-scale', { 'd-none': me.dumb_period })}>
      <YourScale
        me={me}
        stats={stats}
        status={scaleStatus}
        cleanData={cleanData}
        setOrderingAggression={setOrderingAggression}
      />
      <SwitchToFixedSubscription
        me={me}
        getNextOrderDateFromNow={getNextOrderDateFromNow}
        setDumbPeriod={setDumbPeriod}
        requestScaleReturn={requestScaleReturn}
        isOpen={isOpen}
        toggle={toggle}
      />
    </PanelSection>
  );
};

YourScaleSection.propTypes = {
  me: PropTypes.object,
  stats: PropTypes.array.isRequired,
  scaleStatus: PropTypes.object,
  cleanData: PropTypes.object,
  setOrderingAggression: PropTypes.func.isRequired,
  getNextOrderDateFromNow: PropTypes.func.isRequired,
  setDumbPeriod: PropTypes.func.isRequired,
  requestScaleReturn: PropTypes.func.isRequired,
};
