import React from 'react';
import PropTypes from 'prop-types';
import { Info } from 'react-feather';
import { SubmitButton } from '@bottomless/common/components';
import { SwitchForm } from './SwitchForm';
import { useMe } from '../hooks';

export const ConfirmationSection = ({ setDumbPeriod, onSuccess, onlyConfirmation, isSubmitting, onClick }) => {
  const me = useMe();

  return (
    <div className="mw-360">
      {!onlyConfirmation && <SwitchForm setDumbPeriod={setDumbPeriod} onSuccess={onSuccess} />}
      {onlyConfirmation && (
        <SubmitButton block size="default" isSubmitting={isSubmitting} onClick={onClick} className="mw-300 my-4">
          Confirm Switch
        </SubmitButton>
      )}
      <div className="switch-to-sbu-disclaimer">
        {!me.sbuSwitchDiscountUsed && (
          <div className="text-center mb-4 font-weight-bolder">Welcome Discount: Get 20% off your first order!</div>
        )}
        <div className="d-flex align-items-center">
          <Info width="20" height="20" strokeWidth="1.75" className="switch-to-sbu-info" />
          <div className="ml-3">
            By proceeding, you will receive a smart re-ordering scale. Set it up with your current product. Your next
            re-order will be triggered by the scale.
          </div>
        </div>
      </div>
    </div>
  );
};

ConfirmationSection.propTypes = {
  setDumbPeriod: PropTypes.func.isRequired,
  onSuccess: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool,
  onlyConfirmation: PropTypes.bool,
  onClick: PropTypes.func,
};
