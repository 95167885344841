export const takePositives = (group, property = 'value') => ({
  all: () => group.all().filter(d => d[property] > 0),
});

export const takeLastMonth = (group, property = 'value') => {
  const last30Days = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);

  return {
    all: () => group.all().filter(d => d[property] >= last30Days),
  };
};

export const isReading = item => item.type === 'reading';

export const isOrder = item => item.type === 'order';
