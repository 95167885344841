import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { SubmitButton, Form, Field, DateFormat } from '@bottomless/common/components';
import { useToggle } from '@bottomless/common/hooks';
import { READABLE_FORMAT } from '../../utils/dates';
import { useCanSwitchToSbu } from '../../hooks/use-can-switch-to-sbu.hook';
import { SwitchToSubscriptionByUsage } from '../SwitchToSubscriptionByUsage';
import { STEPS } from './steps';

const Schema = Yup.object().shape({
  override_fulfillment_date: Yup.date().required('This field is required'),
});

export const StepSkip = ({ nextFulfillmentDate, skipOrder, setNextStep }) => {
  const canSwitchToSbu = useCanSwitchToSbu();
  const [finished, toggleFinished] = useToggle();
  const onSuccess = useCallback(() => setNextStep(STEPS.SubscriptionUpdatedSBU), [setNextStep]);

  return (
    <>
      {!finished && (
        <>
          <Form
            initialValues={{ override_fulfillment_date: nextFulfillmentDate }}
            validationSchema={Schema}
            onSubmit={skipOrder}
            onSuccess={toggleFinished}
            className="mw-300"
          >
            {({ isSubmitting, values }) => (
              <>
                <Field type="hidden" name="override_fulfillment_date" />
                <div className="d-flex justify-content-between mb-4">
                  <div className="font-weight-bolder order-heading">Updated reorder date</div>
                  <div>
                    <DateFormat date={values.override_fulfillment_date} format={READABLE_FORMAT} />
                  </div>
                </div>
                <div className="mb-3">
                  <SubmitButton size="default" outline block isSubmitting={isSubmitting}>
                    Confirm Skip
                  </SubmitButton>
                </div>
              </>
            )}
          </Form>
          {canSwitchToSbu && (
            <>
              <div className="my-4 d-flex align-items-center">
                <hr className="my-0 w-100" />
                <span className="mx-4">or</span>
                <hr className="my-0 w-100" />
              </div>
              <SwitchToSubscriptionByUsage
                onSuccess={onSuccess}
                place="profile-skip-order"
                title="Switch to Subscribe By Usage instead?"
              />
            </>
          )}
        </>
      )}
      {finished && <div>Your order is skipped</div>}
    </>
  );
};

StepSkip.headingText = 'Skip Order';

StepSkip.propTypes = {
  nextFulfillmentDate: PropTypes.object,
  skipOrder: PropTypes.func.isRequired,
  setNextStep: PropTypes.func.isRequired,
};
