import React from 'react';
import PropTypes from 'prop-types';
import { SubscriptionType } from '@bottomless/common/constants';

export const AlreadySwitched = ({ me }) => {
  if (me.subscriptionType === SubscriptionType.Dynamic) {
    return <div>You already are on subscription by usage.</div>;
  }

  return <div>You can&apos;t switch to Subscription by Usage at the moment.</div>;
};

AlreadySwitched.propTypes = {
  me: PropTypes.shape({
    subscriptionType: PropTypes.string.isRequired,
  }).isRequired,
};
