import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const SendMagicLinkTypes = createAction('Auth', 'Send magic link');

export const sendMagicLinkAction = data => ({
  [RSAA]: {
    endpoint: '/apps/bottomless/auth/magic-link',
    method: 'POST',
    body: data,
    types: [SendMagicLinkTypes.REQUEST, SendMagicLinkTypes.SUCCESS, SendMagicLinkTypes.FAILURE],
  },
});

export const SetupPasswordActionTypes = createAction('Auth', 'Setup password');

export const setupPasswordAction = (token, data) => ({
  [RSAA]: {
    endpoint: `/apps/bottomless/auth/setup-password/${token}`,
    method: 'POST',
    body: data,
    types: [SetupPasswordActionTypes.REQUEST, SetupPasswordActionTypes.SUCCESS, SetupPasswordActionTypes.FAILURE],
  },
});

export const SetPasswordActionTypes = createAction('Auth', 'Set password');

export const setPasswordAction = data => ({
  [RSAA]: {
    endpoint: '/apps/bottomless/auth/set-password',
    method: 'POST',
    body: data,
    types: [SetPasswordActionTypes.REQUEST, SetPasswordActionTypes.SUCCESS, SetPasswordActionTypes.FAILURE],
  },
});

export const ChangeScaleTypes = createAction('Auth', 'Change scale');

export const changeScaleAction = data => ({
  [RSAA]: {
    endpoint: '/apps/bottomless/auth/change-scale',
    method: 'POST',
    body: data,
    types: [ChangeScaleTypes.REQUEST, ChangeScaleTypes.SUCCESS, ChangeScaleTypes.FAILURE],
  },
});
