import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useToggle } from '@bottomless/common/hooks';
import { PanelSection } from '../../../../components/PanelSection';
import { useSubscriptionFrequency } from '../../../../hooks/use-subscription-frequency.hook';
import { FrequencyForm } from './FrequencyForm';
import { SkipNextOrder } from './SkipNextOrder';
import './Frequency.scss';

export const Frequency = ({
  me,
  setDumbPeriod,
  pauseAccount,
  getNextOrderDateFromNow,
  getNextOrderDate,
  skipOrder,
  vendor,
}) => {
  const [isSkipOpen, skipToggle] = useToggle();
  const [isFrequencyOpen, frequencyToggle, frequencySetOpen] = useToggle();
  const [originalDumbPeriod, setOriginalDumbPeriod] = useState(me.dumb_period);

  useEffect(() => {
    if (!isSkipOpen && !isFrequencyOpen) {
      setOriginalDumbPeriod(me.dumb_period);
    }
  }, [me, isSkipOpen, isFrequencyOpen]);

  const { availableOtherOptions } = useSubscriptionFrequency();

  const showFrequencyAsInfo = useMemo(() => availableOtherOptions.length <= 1 && !me?.lineItems.length, [
    availableOtherOptions,
    me,
  ]);

  const isInternationalCustomer = useMemo(
    () =>
      !me?.verifiedAddress?.street1 || (me?.verifiedAddress?.countryCode && me.verifiedAddress.countryCode !== 'US'),
    [me]
  );

  const showFrequencyForm = useMemo(() => !showFrequencyAsInfo && !isInternationalCustomer, [
    showFrequencyAsInfo,
    isInternationalCustomer,
  ]);

  return (
    <>
      {!!originalDumbPeriod && (
        <PanelSection title="Frequency" className={!me.dumb_period ? 'd-none' : undefined}>
          {showFrequencyAsInfo && (
            <div>
              <div className="mb-2">
                Delivered every {availableOtherOptions[0]} {`week${availableOtherOptions[0] > 1 ? 's' : ''}`}
              </div>
              <hr className="my-2" />
            </div>
          )}
          <SkipNextOrder
            me={me}
            skipOrder={skipOrder}
            getNextOrderDate={getNextOrderDate}
            withHr={showFrequencyForm}
            toggle={skipToggle}
            isOpen={isSkipOpen}
            vendor={vendor}
          />

          {showFrequencyForm && (
            <FrequencyForm
              me={me}
              setDumbPeriod={setDumbPeriod}
              pauseAccount={pauseAccount}
              getNextOrderDateFromNow={getNextOrderDateFromNow}
              isOpen={isFrequencyOpen}
              toggle={frequencyToggle}
              setOpen={frequencySetOpen}
            />
          )}
        </PanelSection>
      )}
    </>
  );
};

Frequency.propTypes = {
  me: PropTypes.shape({
    dumb_period: PropTypes.number,
    lineItems: PropTypes.array,
    shopifySubscriptionContractId: PropTypes.string,
    verifiedAddress: PropTypes.object,
    subscriptionType: PropTypes.string.isRequired,
  }).isRequired,
  setDumbPeriod: PropTypes.func.isRequired,
  pauseAccount: PropTypes.func.isRequired,
  getNextOrderDateFromNow: PropTypes.func.isRequired,
  getNextOrderDate: PropTypes.func.isRequired,
  skipOrder: PropTypes.func.isRequired,
  vendor: PropTypes.object.isRequired,
};
