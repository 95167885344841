import { useCallback } from 'react';
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch } from 'react-redux';
import { clearCheckoutPricingRuleAction } from '../../../store/checkout/checkout.actions';

export const useCheckout = () => {
  const dispatch = useDispatch();
  const clearCheckoutPricingRule = useCallback(() => dispatch(clearCheckoutPricingRuleAction()), [dispatch]);

  const [
    { newCheckout: checkoutCookie, newFixedCheckout: checkoutFixedCookie, newSbuCheckout: newSbuCheckoutCookie },
    ,
    removeCookie,
  ] = useCookies(['newCheckout', 'newFixedCheckout', 'newSbuCheckout']);

  useEffect(() => {
    if (checkoutCookie) {
      removeCookie('newCheckout', { path: '/' });
    }

    if (checkoutFixedCookie) {
      removeCookie('newFixedCheckout', { path: '/' });
    }

    if (newSbuCheckoutCookie) {
      removeCookie('newSbuCheckout', { path: '/' });
    }

    if (checkoutCookie || checkoutFixedCookie || newSbuCheckoutCookie) {
      clearCheckoutPricingRule();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeCookie]);
};
