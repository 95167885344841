import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import '@power-elements/stripe-elements';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { useToast } from '@bottomless/common/hooks';
import { Form, Field, SubmitButton } from '@bottomless/common/components';
import { continueGiftAction } from '../../store/user';
import { PanelLayoutGuest } from '../../layouts/PanelLayoutGuest';
import { StripeLoaded, StripeLoader } from '../../components/StripeLoader';

const Schema = Yup.object().shape({
  token: Yup.string().required('This field is required'),
});

export const ContinueGiftSubscriptionComponent = ({ continueGift }) => {
  const form = useRef();
  const history = useHistory();

  const [error, setError] = useState(null);
  const [isProcessing, setProcessing] = useState(false);

  const stripeRef = useRef();

  const successToast = useToast('Billing details updated, your subscription is resumed!');

  const onSuccess = useCallback(() => {
    successToast();
    history.push('/profile');
  }, [successToast, history]);

  const onClick = useCallback(async () => {
    try {
      setProcessing(true);
      const { token } = await stripeRef.current.createToken();
      form.current.setFieldValue('token', token.id);
      form.current.submitForm();
      setProcessing(false);
    } catch (e) {
      setProcessing(false);
      setError(e.message);
    }
  }, [stripeRef]);

  return (
    <PanelLayoutGuest>
      <StripeLoader>
        <h1 className="mb-2 text-center">Continue Subscription</h1>
        <div className="text-center mb-2">Please enter your payment details to continue your subscription</div>
        <Row>
          <Col xs={12} md={{ offset: 3, size: 6 }}>
            <StripeLoaded>
              <Form
                innerRef={form}
                initialValues={{ token: '' }}
                validationSchema={Schema}
                onSubmit={continueGift}
                onSuccess={onSuccess}
                className="mw-300 d-flex justify-content-center flex-column"
              >
                {({ isSubmitting }) => (
                  <>
                    <div className="mb-3">
                      <stripe-elements
                        ref={stripeRef}
                        publishable-key={process.env.REACT_APP_STRIPE_KEY}
                      ></stripe-elements>
                      {error && <div className="text-sm text-danger mt-1">{error}</div>}
                      <Field type="hidden" name="token" />
                    </div>
                    <SubmitButton
                      type="button"
                      onClick={onClick}
                      size={null}
                      isSubmitting={isSubmitting || isProcessing}
                    >
                      Save
                    </SubmitButton>
                  </>
                )}
              </Form>
            </StripeLoaded>
          </Col>
        </Row>
      </StripeLoader>
    </PanelLayoutGuest>
  );
};

ContinueGiftSubscriptionComponent.propTypes = {
  continueGift: PropTypes.func.isRequired,
  me: PropTypes.object,
};

export const ContinueGiftSubscription = connect(null, dispatch => ({
  continueGift: data => dispatch(continueGiftAction(data)),
}))(ContinueGiftSubscriptionComponent);
