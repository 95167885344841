import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useModal } from '../../../../hooks/use-modal.hook';
import { Step1 } from './Step1';
import { Step2 } from './Step2';
import { Step3 } from './Step3';

const STEPS = [Step1, Step2, Step3];

export const SwitchToFixedSubscription = ({
  me,
  setDumbPeriod,
  requestScaleReturn,
  getNextOrderDateFromNow,
  isOpen,
  toggle: rawToggle,
}) => {
  const modalProps = useModal();
  const [step, setStep] = useState(0);

  const Component = useMemo(() => STEPS[step], [step]);

  const toggle = useCallback(() => {
    rawToggle();
    setTimeout(() => setStep(0), 300);
  }, [rawToggle]);

  const onSuccess = useCallback(() => {
    if (STEPS[step + 1]) {
      setStep(step + 1);
    } else {
      toggle();
    }
  }, [step, toggle]);

  const handleSetDumbPeriod = useCallback(data => setDumbPeriod(data, 'profile-switch-to-sbu'), [setDumbPeriod]);

  return (
    <>
      {!me.dumb_period && (
        <div className="text-center mb-5">
          <Button color="link" outline onClick={toggle}>
            Switch To Fixed Frequency Subscription
          </Button>
        </div>
      )}
      <Modal isOpen={isOpen} size="md" toggle={toggle} {...modalProps} className="modal-fixed-subscription">
        <ModalHeader toggle={toggle}>{Component.headingText}</ModalHeader>
        <ModalBody className="text-md-center">
          <Component
            me={me}
            toggle={toggle}
            setDumbPeriod={handleSetDumbPeriod}
            requestScaleReturn={requestScaleReturn}
            onSuccess={onSuccess}
            getNextOrderDateFromNow={getNextOrderDateFromNow}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

SwitchToFixedSubscription.propTypes = {
  me: PropTypes.object.isRequired,
  setDumbPeriod: PropTypes.func.isRequired,
  requestScaleReturn: PropTypes.func.isRequired,
  getNextOrderDateFromNow: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
};
