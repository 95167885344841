import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useToast } from '@bottomless/common/hooks';
import { useModal } from '../../../../hooks/use-modal.hook';

export const DiscountRemoveConfirmation = ({ isOpen, toggle, discount, onRemove }) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const removalToast = useToast('Discount code removed');
  const modalProps = useModal();

  const onSubmit = useCallback(async () => {
    setSubmitting(true);
    await onRemove(discount);
    setSubmitting(false);
    removalToast();
    toggle();
  }, [discount, onRemove, removalToast, toggle]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="md" {...modalProps}>
      <ModalHeader toggle={toggle}>Discount Removal Confirmation</ModalHeader>
      <ModalBody>
        <div className="mb-4 text-center">
          Are you sure that you want to remove <span className="font-weight-bold">{discount.title}</span>?
        </div>
        <div className="text-center">
          <Button color="primary" outline disabled={isSubmitting} onClick={onSubmit}>
            {isSubmitting ? 'Removing...' : 'Confirm'}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

DiscountRemoveConfirmation.propTypes = {
  discount: PropTypes.object,
  onRemove: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
};
