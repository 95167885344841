import React from 'react';
import { DataHandler, DataHandlerContent } from '@bottomless/common/components';
import { PanelSection } from '../../components/PanelSection';
import { Heading } from '../../components/Heading';
import { combineStates } from '../../utils/combine-states';
import {
  Credits,
  Frequency,
  PausedOrdering,
  PendingPhoneNumber,
  ProductPreference,
  YourScaleSection,
} from './components';
import {
  useMe,
  useQueue,
  useProducts,
  useAttributes,
  useMakeBottomless,
  useStats,
  useScaleStatus,
  useCleanData,
  useUpcomingOrder,
  useAddLineItems,
  useUpdateLineItems,
  useCredits,
  useLastSubscriptionOrder,
  useCheckout,
} from './hooks';
import { PanelLayout } from '../../layouts/PanelLayout';
import './SubscriptionPage.scss';
import { useLastOrderDate } from '../../hooks/use-last-order-date';
import { SubscriptionType } from '@bottomless/common/constants';
import { useMemo } from 'react';
import { Col, Row } from 'reactstrap';
import { ClosedAccount } from './components/ClosedAccount';
import { DiscountCode } from './components/DiscountCode';
import { useIsSimpleSubscription } from '../../hooks/use-is-simple-subscribtion.hook';

export const SubscriptionPage = () => {
  const me = useMe();
  const queue = useQueue();
  const products = useProducts({ me: me.data });
  const attributes = useAttributes();
  const makeBottomless = useMakeBottomless();
  const stats = useStats();
  const scaleStatus = useScaleStatus();
  const cleanData = useCleanData();
  const upcomingOrders = useUpcomingOrder();
  const addLineItem = useAddLineItems();
  const updateLineItems = useUpdateLineItems();
  const credits = useCredits();
  const lastSubscriptionOrder = useLastSubscriptionOrder();
  const isSimpleSubscription = useIsSimpleSubscription();

  useCheckout();

  const { getNextOrderDate, getNextOrderDateFromNow } = useLastOrderDate({
    lastOrder: lastSubscriptionOrder,
    upcomingOrders,
    user: me?.data,
  });

  const showFrequency = useMemo(
    () =>
      !me.isClosed &&
      (me.data?.subscriptionType !== SubscriptionType.Prepaid || (credits.data && !credits.data?.granted)),
    [me, credits]
  );

  const useShopifyDiscountCodes = useMemo(
    () => !me.isClosed && isSimpleSubscription && me.data?.vendor_id?.shopifyManifest?.useShopifyDiscountCodes,
    [me, isSimpleSubscription]
  );

  return (
    <PanelLayout>
      <div className="page page-profile">
        <Heading>Your Subscription</Heading>
        {me.data && (
          <Row>
            <Col xs="12" lg="6">
              <ClosedAccount
                me={me.data}
                isClosed={me.isClosed}
                pauseAccount={me.pauseAccount}
                getNextOrderDate={getNextOrderDate}
              />
              {me.data?.paused && !me.isClosed && <PausedOrdering me={me.data} pauseAccount={me.pauseAccount} />}
              <PendingPhoneNumber
                me={me.data}
                acceptShopifyPhone={me.acceptPhone}
                rejectShopifyPhone={me.rejectPhone}
              />
              {!me.isClosed && (
                <PanelSection title="Product Preference">
                  <DataHandler {...combineStates(me, attributes)} />
                  <DataHandlerContent {...combineStates(me, attributes)}>
                    <ProductPreference
                      me={me.data}
                      queue={queue.data}
                      productsState={products}
                      attributes={attributes.data}
                      makeBottomless={makeBottomless}
                      moveCustomListItem={queue.moveCustomListItem}
                      deleteCustomListItem={queue.deleteCustomListItem}
                      updateCustomList={queue.updateCustomList}
                      openQueue={queue.openQueue}
                      onModalClose={queue.onCloseQueue}
                      updateLineItemsAmounts={me.updateLineItemsAmounts}
                      optimisticLineItemsAmountsUpdate={me.optimisticLineItemsAmountsUpdate}
                      addLineItem={addLineItem}
                      updateLineItems={updateLineItems}
                    />
                  </DataHandlerContent>
                </PanelSection>
              )}
              {useShopifyDiscountCodes && <DiscountCode />}
            </Col>
            <Col xs="12" lg="6">
              <DataHandler {...combineStates(me, stats, scaleStatus, cleanData)} />
              <DataHandlerContent {...combineStates(me, stats, scaleStatus, cleanData)}>
                {!me.isClosed && (
                  <YourScaleSection
                    me={me.data}
                    stats={stats.data}
                    scaleStatus={scaleStatus.data}
                    cleanData={cleanData.data}
                    setOrderingAggression={me.setOrderingAggression}
                    getNextOrderDateFromNow={getNextOrderDateFromNow}
                    setDumbPeriod={me.setDumbPeriod}
                    requestScaleReturn={me.requestScaleReturn}
                  />
                )}
                {me.data?.subscriptionType === SubscriptionType.Prepaid && (
                  <PanelSection title="Gift Orders">
                    <DataHandler {...credits} />
                    <DataHandlerContent {...credits}>
                      <Credits credits={credits.data} />
                    </DataHandlerContent>
                  </PanelSection>
                )}
                {showFrequency ? (
                  <Frequency
                    me={me.data}
                    getNextOrderDateFromNow={getNextOrderDateFromNow}
                    setDumbPeriod={me.setDumbPeriod}
                    pauseAccount={me.pauseAccount}
                    getNextOrderDate={getNextOrderDate}
                    skipOrder={upcomingOrders.skipOrder}
                    vendor={upcomingOrders.data?.vendor}
                  />
                ) : (
                  <></>
                )}
              </DataHandlerContent>
            </Col>
          </Row>
        )}
      </div>
    </PanelLayout>
  );
};
