import React, { useMemo } from 'react';
import { DataLoading } from '@bottomless/common/components';
import { useHistory } from 'react-router-dom';
import { useMe, useAccounts } from '../Profile/hooks';
import { PanelLayoutGuest } from '../../layouts/PanelLayoutGuest';
import { BottomlessAccount, GeneralAccount } from './components';
import './PortalSelect.scss';
import { useConfigValue } from '../../hooks/use-config-value.hook';
import { NewSubscription } from '../../components/NewSubscription';
import { useQueryString } from '@bottomless/common/hooks';

export const PortalSelectPage = () => {
  const me = useMe();
  const { params } = useQueryString();
  const userAccounts = useAccounts();
  const history = useHistory();

  const useProductAccountWidget = useConfigValue('useProductAccountWidget');
  const preventNewScale = useConfigValue('preventNewScale');
  const useEmbedAccountPicker = useConfigValue('useEmbedAccountPicker');

  const isLoggedShopifyCustomer = useMemo(() => !!window.BottomlessAccount?.customer?.id, []);

  if (useEmbedAccountPicker) {
    isLoggedShopifyCustomer ? (window.location.href = '/account') : history.push('/subscriptions');
    return null;
  }

  if (userAccounts.isLoading) {
    return <DataLoading count={0} isLoading={true} />;
  }

  return (
    <PanelLayoutGuest>
      <h2 className="text-center mb-5">Choose Account</h2>
      <div className="choose-portal-accounts">
        {userAccounts.data?.accounts?.map(account => (
          <BottomlessAccount
            key={account._id}
            account={{ ...account, vendor_id: me.data?.vendor_id }}
            selected={params.alreadyLogged && account._id === me.data?._id}
          />
        ))}
        <GeneralAccount me={me?.data} />
        {useProductAccountWidget && !preventNewScale && (
          <NewSubscription newScaleCheckout={userAccounts.newScaleCheckout} me={me.data} />
        )}
      </div>
    </PanelLayoutGuest>
  );
};
