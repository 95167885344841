import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Field } from '@bottomless/common/components';
import { PanelSection } from '../../../components/PanelSection';
import { useProductFrequency } from '../../../hooks/use-subscription-frequency.hook';
import { CheckoutSource } from '@bottomless/common/src/constants';

export const Frequency = ({ checkout }) => {
  const { options: productOptions, availableOtherOptions } = useProductFrequency(checkout.product?.product, true);

  const options = useMemo(() => {
    if (checkout.source === CheckoutSource.ShopifySbu) {
      return { '': 'By Usage' };
    }

    return [...productOptions, ...availableOtherOptions].reduce(
      (all, option) => ({ ...all, [option * 7]: `${option} week${option > 1 ? 's' : ''}` }),
      {}
    );
  }, [productOptions, availableOtherOptions, checkout]);

  return (
    <PanelSection title="Frequency">
      <Field type="select" name="frequency" options={options} />
    </PanelSection>
  );
};

Frequency.propTypes = {
  checkout: PropTypes.shape({
    source: PropTypes.string,
    product: PropTypes.shape({
      product: PropTypes.object,
    }).isRequired,
  }).isRequired,
};
