import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import './Heatmap.scss';
import { useRef } from 'react';

const CELL_SIZE = 14;
const NUMBER_OF_COLORS = 6;

export const Heatmap = ({ data }) => {
  const heatmapRef = useRef();

  useEffect(() => {
    generate(data, heatmapRef);
  }, [data, heatmapRef]);

  return (
    <div ref={heatmapRef} className="heatmap d-flex flex-column align-items-center">
      <div className="js-heatmap" />
      <div className="js-months" />
      <div className="js-legend" />
    </div>
  );
};

const generate = (data, heatmapRef) => {
  const firstDate = new Date(Number(Object.keys(data.adjusted_weight)[0]));

  const startYear = firstDate.getYear() + 1900;
  const endYear = new Date().getYear() + 1901;

  const graphData = formatData(data, firstDate);

  createHeatMap(graphData, startYear, endYear, heatmapRef);
};

const formatData = (data, startDate) => {
  const timeFormat = d3.timeFormat('%Y-%m-%d');

  const builtObj = {
    dates: {},
    maxCount: 0,
    startDate,
  };

  const mergedDiffs = Object.entries(data.diff).reduce((all, [key, value]) => {
    const time = timeFormat(key);

    return { ...all, [time]: (all[time] || 0) + value };
  }, {});

  for (const time in mergedDiffs) {
    let d = mergedDiffs[time];

    if (d < 0.3) {
      d = 0;
    } else {
      d = Math.round(d);
      builtObj.dates[time] = { count: d };
    }

    if (d > builtObj.maxCount) {
      builtObj.maxCount = d;
    }
  }

  return builtObj;
};

function createHeatMap(data, startYear, endYear, heatmapRef) {
  const width = 780;
  const height = 110;
  const dx = 35;
  const gridClass = 'js-date-grid day';
  const formatColor = d3
    .scaleQuantize()
    .domain([0, data.maxCount])
    .range(d3.range(NUMBER_OF_COLORS).map(d => `color${d}`));

  const heatmapSvg = d3
    .select(heatmapRef.current.querySelector('.js-heatmap'))
    .html('')
    .selectAll('svg.heatmap')
    .remove()
    .enter()
    .append('svg')
    .data(d3.range(startYear, endYear))
    .enter()
    .append('svg')
    .attr('width', width)
    .attr('height', height)
    .attr('class', 'color');

  // Add a grid for each day between the date range.

  const dates = Object.keys(data.dates);
  const rect = heatmapSvg.append('g').attr('transform', `translate(${dx},0)`);

  // Add year label.
  rect
    .append('text')
    .attr('transform', `translate(-23,${CELL_SIZE * 3.5})rotate(-90)`)
    .style('text-anchor', 'middle')
    .style('font-weight', 'bold')
    .text(d => d);

  rect
    .append('text')
    .attr('transform', `translate(-7,${CELL_SIZE * 3.5})rotate(-90)`)
    .style('text-anchor', 'middle')
    .style('font-size', '0.65rem')
    .style('letter-spacing', '1.7px')
    .text('S F Tr W T M S');

  rect
    .selectAll('.day')
    // The heatmap will contain all the days in that year.
    .data(d => d3.timeDays(new Date(d, 0, 1), new Date(d + 1, 0, 1)))
    .enter()
    .append('rect')
    .attr('class', gridClass)
    .attr('width', CELL_SIZE)
    .attr('height', CELL_SIZE)
    .attr('x', d => d3.timeFormat('%U')(d) * CELL_SIZE)
    .attr('y', d => d.getDay() * CELL_SIZE)
    .attr('data-toggle', 'tooltip')
    .datum(d3.timeFormat('%Y-%m-%d'))
    // Add the grid data as a title attribute to render as a tooltip.
    .attr('title', d => {
      const countData = data.dates[d];
      const date = d3.timeFormat('%b %d, %Y')(new Date(d));
      if (!countData || !countData.count) return `No posts on ${date}`;
      else if (countData.count === 1) return `1 post on ${date}`;
      else return `${countData.count} posts on ${date}`;
    })
    .attr('date', d => d)
    // Add bootstrap's tooltip event listener.

    //TODO: fix tooltip
    // .call(() => $('[data-toggle="tooltip"]').tooltip({
    //   container: 'body',
    //   placement: 'top',
    //   position: { my: 'top' }
    // }))
    // Add the colors to the grids.
    .filter(d => dates.indexOf(d) > -1)
    .attr('class', d => `${gridClass} ${formatColor(data.dates[d].count)}`);

  // Render x axis to show months
  d3.select(heatmapRef.current.querySelector('.js-months'))
    .html('')
    .selectAll('svg.months')
    .enter()
    .append('svg')
    .data([1])
    .enter()
    .append('svg')
    .attr('width', 800)
    .attr('height', 20)
    .append('g')
    .attr('transform', 'translate(0,10)')
    .style('font-size', '0.9rem')
    .selectAll('.month')
    .data(() => d3.range(12))
    .enter()
    .append('text')
    .attr('x', d => d * (4.5 * CELL_SIZE) + dx)
    .text(d => d3.timeFormat('%b')(new Date(0, d + 1, 0)));

  // Render the grid color legend.
  d3.select(heatmapRef.current.querySelector('.js-legend'))
    .html('')
    .selectAll('svg.legend')
    .enter()
    .append('svg')
    .data([1])
    .enter()
    .append('svg')
    .attr('width', 800)
    .attr('height', 20)
    .append('g')
    .attr('transform', 'translate(644,0)')
    .selectAll('.legend-grid')
    .data(() => d3.range(7))
    .enter()
    .append('rect')
    .attr('width', CELL_SIZE)
    .attr('height', CELL_SIZE)
    .attr('x', d => d * CELL_SIZE + dx)
    .attr('class', d => `day color${d - 1}`);

  if (heatmapRef.current) {
    heatmapRef.current.scrollLeft = 1000;
  }
}

Heatmap.propTypes = {
  data: PropTypes.object.isRequired,
};
