import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { AuthenticatedRoutes } from './AuthenticatedRoutes';
import { SendMagicLinkPage } from '../pages/SendMagicLink';
import { SetupPasswordPage } from '../pages/SetupPassword';
import { CheckoutCompletedPage } from '../pages/CheckoutCompleted';
import { OrderPage } from '../pages/Order';
import { NewMagicLinkSentPage } from '../pages/NewMagicLinkSent/NewMagicLinkSent';

export const Routes = () => (
  <Switch>
    <Route path="/send-magic-link" exact component={SendMagicLinkPage} />
    <Route path="/new-link" exact component={NewMagicLinkSentPage} />
    <Route path="/setup_password/:token" exact component={SetupPasswordPage} />
    <Route path="/get_started_complete" exact component={CheckoutCompletedPage} />
    <Route path="/order/:id/status" exact component={OrderPage} />
    <Route path="/" component={AuthenticatedRoutes} />
  </Switch>
);
