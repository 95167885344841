import moment from 'moment';
import { useOnce } from '@bottomless/common/hooks';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  cancelOrderAction,
  getInTransitionCountAction,
  getOrderStatusAction,
  getUpcomingOrderAction,
  reportOrderProblemAction,
  setProductAction,
  skipOrderAction,
  triggerOrderAction,
} from '../../../store/order/order.actions';
import { overrideDateAction } from '../../../store/order';
import { useToast } from '@bottomless/common/hooks';
import { ValidationError } from '@bottomless/common/store';
import { verifyAddressAction } from '../../../store/user';
import { submitOrderNoteAction, submitOrderRateAction } from '../../../store/rate';
import { useConditionalDataEffect } from '@bottomless/common/hooks';
import { OrderStatuses } from '@bottomless/common/constants';
import { utcDate } from '@bottomless/common/utils';

export const useOrder = (id, productId, variantId, user, getNextOrderDate, upcomingOrder) => {
  const history = useHistory();
  const location = useLocation();
  const isPending = useMemo(() => id === 'pending', [id]);

  const dispatch = useDispatch();
  const action = useCallback(() => dispatch(getOrderStatusAction(id)), [dispatch, id]);

  const errorToast = useToast('Oops something went wrong. Please try again!', 'danger');
  const setProductSuccessToast = useToast('Product has been successfully saved');
  const verifyAddressSuccessToast = useToast('Address has been successfully saved');
  const rateSuccessToast = useToast('Thank you for your feedback!');
  const reportProblemSuccessToast = useToast('Problem successfully reported');

  const productUnavailableToast = useToast("The product you've selected is no longer available!", 'error');

  const { data: rawData, error, isFetching: isLoading } = useConditionalDataEffect(!isPending, action);

  const artificialOrder = useMemo(
    () =>
      user && {
        _id: 'pending',
        status: OrderStatuses.Initiated,
        quantity: user.quantity,
        override_fulfillment_date: utcDate(getNextOrderDate(user.dumb_period, false, 1, user)),
      },
    [getNextOrderDate, user]
  );

  const [data, setData] = useState(isPending ? { order: artificialOrder, user } : rawData);

  useEffect(() => {
    if (isPending && upcomingOrder?.orders?.length) {
      history.replace(`/order/${upcomingOrder.orders[0].order._id}/status${location.search}`);
    }
  }, [history, location, isPending, upcomingOrder]);

  useEffect(() => {
    if (!isPending) {
      setData(rawData);
    }
  }, [rawData, isPending]);

  useEffect(() => {
    if (isPending) {
      setData({ order: artificialOrder, user });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isPending]);

  const cancel = useCallback(async () => {
    const result = await dispatch(cancelOrderAction(id));

    setData({ ...data, order: { ...data.order, status: result.payload.status } });

    return result;
  }, [dispatch, id, data]);

  const overrideDate = useCallback(
    async (id, { override_fulfillment_date, ...params }) => {
      if (id === 'pending') {
        const result = await dispatch(
          triggerOrderAction({ override_fulfillment_date: moment(override_fulfillment_date).format('YYYY-MM-DD') })
        );

        setData({
          ...data,
          order: {
            ...data.order,
            _id: result.payload._id,
            override_fulfillment_date: result.payload.override_fulfillment_date,
          },
        });

        await dispatch(getUpcomingOrderAction());

        return result;
      }

      const result = await dispatch(
        overrideDateAction(id, {
          override_fulfillment_date: moment(override_fulfillment_date).format('YYYY-MM-DD'),
          ...params,
        })
      );

      setData({
        ...data,
        order: { ...data.order, override_fulfillment_date: result.payload.override_fulfillment_date },
      });

      return result;
    },
    [dispatch, data]
  );

  const setProduct = useCallback(
    async ({ product, variant }) => {
      try {
        const result = await dispatch(setProductAction(data.order._id, product, variant));

        const { error, payload } = result;

        if (!error && payload.status && ![404, 401].includes(payload.status)) {
          errorToast();
        }

        if (!error && payload) {
          setData({ ...data, order: { ...data.order, subproduct_id: payload.subproduct_id } });
          setProductSuccessToast();
        }

        return result;
      } catch (e) {
        const message = e instanceof ValidationError ? e.details.order || e.message : undefined;
        errorToast(message);
      }
    },
    [dispatch, errorToast, setData, data, setProductSuccessToast]
  );

  const verifyAddress = useCallback(
    async params => {
      const result = await dispatch(verifyAddressAction(params));

      setData({
        ...data,
        user: { ...data.user, verifiedAddress: result.payload.verifiedAddress },
        ...(data.order.address?.street1 ? { order: { ...data.order, address: result.payload.verifiedAddress } } : {}),
      });

      verifyAddressSuccessToast();

      return result;
    },
    [dispatch, setData, data, verifyAddressSuccessToast]
  );

  const sendFeedback = useCallback(
    async newData => {
      try {
        if (newData.content && newData.content !== '') {
          await dispatch(submitOrderNoteAction(data.order._id, newData));
        }
        const response = await dispatch(submitOrderRateAction(data.order._id, newData));
        setData({ ...data, order: { ...data.order, rate: response.payload.rate } });

        rateSuccessToast();
      } catch (err) {
        errorToast();
      }
    },
    [rateSuccessToast, errorToast, data, setData, dispatch]
  );

  const reportProblem = useCallback(
    async newData => {
      try {
        const response = await dispatch(reportOrderProblemAction(data.order._id, newData));
        setData({ ...data, order: { ...data.order, reportProblem: response.payload.reportProblem } });

        reportProblemSuccessToast();

        return response;
      } catch (err) {
        errorToast();
      }
    },
    [reportProblemSuccessToast, errorToast, data, setData, dispatch]
  );

  const skipOrder = useCallback(
    async ({ override_fulfillment_date, ...params }) => {
      const result = await dispatch(
        skipOrderAction({
          override_fulfillment_date: moment(override_fulfillment_date).format('YYYY-MM-DD'),
          ...params,
        })
      );

      const newOrderId = result.payload.orders.find(order => order.isNewOrder);

      if (newOrderId) {
        history.replace(`/order/${newOrderId.order._id}/status${location.search}`);
      }

      await dispatch(getInTransitionCountAction());

      return result;
    },
    [dispatch, history, location]
  );

  useOnce(async () => {
    if (!(productId && variantId)) {
      return;
    }

    const selectionAvailable = data.selections.some(s => s.product._id === productId && s.variant._id === variantId);

    if (selectionAvailable) {
      await setProduct({ product: productId, variant: variantId });
    } else {
      productUnavailableToast();
    }
  }, [data, setProduct, id, productId, variantId, setData, productUnavailableToast]);

  return {
    data,
    isLoading,
    error,
    cancel,
    overrideDate,
    setProduct,
    verifyAddress,
    sendFeedback,
    reportProblem,
    skipOrder,
  };
};
