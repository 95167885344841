import React from 'react';
import PropTypes from 'prop-types';
import { Image } from '@bottomless/common/components';
import { useVendorLock } from '@bottomless/common/hooks';
import { AboutSusbscroptionByUsage, ConfirmationSection } from './components';
import { useSwitch } from './hooks';
import './SwitchToSubscriptionByUsage.scss';

export const SwitchToSubscriptionByUsage = ({
  title,
  onSuccess,
  place,
  onlyConfirmation,
  isSubmitting,
  onConfirmClick,
  showMore,
}) => {
  const vendor = useVendorLock();
  const { setDumbPeriod } = useSwitch({ place });

  return (
    <div className="switch-to-sbu text-left">
      {title && <div className="switch-to-sbu-title text-center mw-360">{title}</div>}
      <div className="text-center">
        <Image
          src={vendor?.promoteSbuImage || '/shopify-account/promote-sbu/promote-sbu-generic.png'}
          className="switch-to-sbu-main-image"
        />
      </div>
      {onlyConfirmation && (
        <ConfirmationSection
          onSuccess={onSuccess}
          setDumbPeriod={setDumbPeriod}
          isSubmitting={isSubmitting}
          onClick={onConfirmClick}
          onlyConfirmation
        >
          Confirm Switch
        </ConfirmationSection>
      )}
      <AboutSusbscroptionByUsage
        onSuccess={onSuccess}
        setDumbPeriod={setDumbPeriod}
        withCofirmation={!onlyConfirmation}
        buttonOutline={onlyConfirmation}
        showMore={showMore}
      />
    </div>
  );
};

SwitchToSubscriptionByUsage.propTypes = {
  title: PropTypes.string,
  onSuccess: PropTypes.func,
  place: PropTypes.string,
  onlyConfirmation: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  onConfirmClick: PropTypes.func,
  showMore: PropTypes.func,
};
