import { useDataEffect } from '@bottomless/common/hooks';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { changeScaleAction } from '../../../store/auth/auth.actions';

export const useScaleChange = (id, path) => {
  const dispatch = useDispatch();
  const action = useCallback(() => dispatch(changeScaleAction({ account: id, path })), [dispatch, path, id]);

  const { data, error, isFetching: isLoading } = useDataEffect(action);

  return { data, isLoading, error };
};
