import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

export const DataHandlerEmptyState = ({ children, data, isLoading, error }) => {
  const noData = useMemo(() => !data || (Array.isArray(data) && !data.length), [data]);

  if (isLoading || error || !noData) {
    return null;
  }

  return <>{children}</>;
};

DataHandlerEmptyState.propTypes = {
  data: PropTypes.any,
  isLoading: PropTypes.bool,
  children: PropTypes.node,
  error: PropTypes.object,
  emptyState: PropTypes.node,
};
