import React, { useRef, useMemo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { CheckboxBox, Form } from '@bottomless/common/components';
import { useToast, useToggle } from '@bottomless/common/hooks';
import { PhoneNumberEdit } from './PhoneNumberEdit/PhoneNumberEdit';

const Schema = Yup.object().shape({
  email: Yup.bool(),
  text: Yup.bool(),
});

export const Notifications = ({ me, alertSettings, textAlerts, verifyPhone, onSubmit }) => {
  const form = useRef();
  const [isPhoneOpen, togglePhone] = useToggle();

  const initialValues = useMemo(
    () => ({
      email: Object.values(alertSettings.categorySettings).reduce((all, setting) => all || setting.email, false),
      text:
        !!me.phone &&
        Object.values(alertSettings.categorySettings).reduce((all, setting) => all || setting.text, false),
    }),
    [me, alertSettings]
  );

  const handleSubmit = useCallback(
    ({ email, text }) => {
      if (isPhoneOpen) {
        return () => {};
      }

      const mappedData = {
        categorySettings: Object.entries(alertSettings.categorySettings).reduce(
          (all, [key, settings]) => ({ ...all, [key]: { ...settings, text, email } }),
          {}
        ),
      };

      return onSubmit(mappedData);
    },
    [alertSettings, onSubmit, isPhoneOpen]
  );

  useEffect(() => {
    form.current.resetForm(initialValues);
  }, [initialValues, form]);

  const successToast = useToast('Your notification settings have been updated');
  const onSuccess = useCallback(() => {
    if (!isPhoneOpen) {
      successToast();
    }
  }, [successToast, isPhoneOpen]);

  return (
    <div>
      <Form
        innerRef={form}
        initialValues={initialValues}
        validationSchema={Schema}
        onSubmit={handleSubmit}
        onSuccess={onSuccess}
      >
        {({ submitForm }) => {
          return (
            <>
              <div className="checkbox-notification mt-1">
                <CheckboxBox
                  name="email"
                  onChange={() => setTimeout(submitForm, 0)}
                  label="E-mail"
                  description={me.local.email}
                />
              </div>

              <div className="checkbox-notification">
                <CheckboxBox
                  disabled={!me.phone}
                  name="text"
                  onChange={() => setTimeout(submitForm, 0)}
                  label={
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <div>Text Message (Recommended)</div>
                        <div className="small text-secondary">{me.phone || 'Set up your phone number'}</div>
                      </div>
                      <PhoneNumberEdit
                        isOpen={isPhoneOpen}
                        toggle={togglePhone}
                        me={me}
                        alertSettings={alertSettings}
                        textAlerts={textAlerts}
                        verifyPhone={verifyPhone}
                      />
                    </div>
                  }
                />
              </div>
            </>
          );
        }}
      </Form>
    </div>
  );
};

Notifications.propTypes = {
  me: PropTypes.shape({
    phone: PropTypes.string,
    local: PropTypes.shape({
      email: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  alertSettings: PropTypes.shape({
    categorySettings: PropTypes.object.isRequired,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  textAlerts: PropTypes.func.isRequired,
  verifyPhone: PropTypes.func.isRequired,
};
