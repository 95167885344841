import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { UndoSwitch } from './UndoSwitch';
import { ScaleReturn } from '../../../../components/ScaleReturn';
import { useIsSimpleSubscription } from '../../../../hooks/use-is-simple-subscribtion.hook';
import { Button } from 'reactstrap';

export const Step2 = ({ me, setDumbPeriod, requestScaleReturn, onSuccess, getNextOrderDateFromNow, toggle }) => {
  const isSimpleSubscription = useIsSimpleSubscription();
  const userCouldHaveScale = useMemo(() => !isSimpleSubscription || me.base_id, [isSimpleSubscription, me]);

  return (
    <>
      <div className="mb-4 text-md-center">
        A fixed frequency subscription means your orders will be sent at fixed intervals. There is a risk of oversupply
        or undersupply.
      </div>
      <UndoSwitch
        getNextOrderDateFromNow={getNextOrderDateFromNow}
        toggle={toggle}
        me={me}
        setDumbPeriod={setDumbPeriod}
      />
      {userCouldHaveScale && (
        <>
          <hr className="my-5" />
          <ScaleReturn toggle={toggle} onSuccess={onSuccess} requestScaleReturn={requestScaleReturn} />
        </>
      )}
      {!userCouldHaveScale && (
        <div className="mw-300 mb-3 mt-4">
          <Button block color="link" onClick={toggle}>
            Close
          </Button>
        </div>
      )}
    </>
  );
};

Step2.headingText = 'Your subscription has been updated!';

Step2.propTypes = {
  me: PropTypes.object.isRequired,
  setDumbPeriod: PropTypes.func.isRequired,
  requestScaleReturn: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  getNextOrderDateFromNow: PropTypes.func.isRequired,
};
