import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { connect } from 'react-redux';
import { Button, Col, CustomInput, Row } from 'reactstrap';
import crossfilter from 'crossfilter2';
import CryptoJS from 'crypto-js';
import { useDataEffect, useConditionalDataEffect } from '@bottomless/common/hooks';
import { addToastAction } from '@bottomless/common/store';
import { isWholeNumber } from '@bottomless/common/utils';
import { Heading } from '../../components/Heading';
import { getScaleStatusAction } from '../../store/scale';
import { getOrdersAction } from '../../store/order';
import { getStatsRecordsAction } from '../../store/stats';
import {
  getCleanedDataAction,
  shareDataAction,
  getUserStatsAction,
  requestScaleAssistanceAction,
} from '../../store/user';
import { Heatmap } from './components/Heatmap';
import { Graph } from './components/Graph';
import { InfoNumbers } from './components/InfoNumbers';
import { RequestScaleAssistanceModal } from './components/RequestScaleAssistanceModal';
import { DetailedGraphs } from './components/DetailedGraphs';
import { PanelSection } from '../../components/PanelSection/PanelSection';
import { ScaleData } from './components/ScaleData';
import './Data.scss';
import { PanelLayout } from '../../layouts/PanelLayout';

const reasonOptions = {
  '': '-----------------',
  inaccurate_weights: 'Scale is reporting inaccurate weights',
  new_container: 'Using a new container',
  connecting_reconnecting: 'Connecting/reconnecting the scale',
  battery_dying: 'The battery is dying too fast',
  charger: 'Request a charger',
  new_scale: 'Request a new scale',
  faq: 'FAQ Guide',
  other: 'Other',
};

const DataPageComponent = ({
  getCleanData,
  cleanData: cleanDataRaw,
  getScaleStatus,
  shareData,
  me,
  addToast,
  orders,
  getOrders,
  stats,
  lastTare,
  getUserStats,
  getStatsRecords,
  requestScaleAssistance,
}) => {
  const [copied, setCopied] = useState(false);
  const [status, setStatus] = useState(null);
  const [isSharing, setSharing] = useState(false);
  const [isAllowing, setAllowing] = useState(false);
  const [allowSharing, setAllowSharing] = useState(me.share_data);
  const [isScaleAssistanceRequestPending, setScaleAssistanceRequestPending] = useState(false);
  const [hasRequestedScaleSupport, setHasRequestedScaleSupport] = useState(
    me && me.support && me.support.scale && me.support.scale.needs_support
  );

  const [userStats, setUserStats] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(null);

  const wasScaleLastConnected = useMemo(
    () => status?.scale_last_connected && isWholeNumber(status?.scale_last_weight),
    [status]
  );

  useConditionalDataEffect(!userStats && me && me.hibernate, getUserStats, setUserStats);

  // eslint-disable-next-line react-hooks/exhaustive-deps, no-new-func
  const key = useMemo(new Function(atob(process.env.REACT_APP_STATS_KEY)), []);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const cleanData = useMemo(() => {
    let data;
    try {
      data = cleanDataRaw.data
        ? JSON.parse(CryptoJS.AES.decrypt(cleanDataRaw.data, key).toString(CryptoJS.enc.Utf8))
        : cleanDataRaw.data;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }

    return {
      ...cleanDataRaw,
      data,
    };
  }, [cleanDataRaw, key]);

  useEffect(() => {
    getCleanData();
    getOrders();
    getStatsRecords();
  }, [getCleanData, getOrders, getStatsRecords]);

  useDataEffect(getScaleStatus, setStatus);

  const onClickRequestScaleAssistance = async data => {
    setScaleAssistanceRequestPending(true);
    try {
      const scaleProblem = data;
      scaleProblem.reason = reasonOptions[data.reason];
      const response = await requestScaleAssistance(data);

      if (response && response.payload && response.payload.status === (404 || 400)) {
        addToast('An error occurred, please try again later', 'danger');
        setScaleAssistanceRequestPending(false);
        return;
      }
      addToast('Successfully requested scale assistance.', 'success');
      setScaleAssistanceRequestPending(false);
      setHasRequestedScaleSupport(true);
      toggleModal();
    } catch (e) {
      addToast("Sorry, we couldn't request scale assistance", 'danger');
      setScaleAssistanceRequestPending(false);
    }
  };

  const onSharingChange = useCallback(() => {
    const share = !allowSharing;
    setAllowSharing(share);

    (async () => {
      try {
        setAllowing(true);
        await shareData({ share });
        setAllowing(false);
        addToast(`Sharing is turned ${share ? 'on' : 'off'}`, 'success');
      } catch (e) {
        setAllowing(false);
        addToast("Sorry, we couldn't share your data", 'danger');
      }
    })();
  }, [setAllowSharing, allowSharing, setAllowing, shareData, addToast]);

  const onCopy = useCallback(() => {
    (async () => {
      try {
        if (!me.share_data) {
          setSharing(true);
          await shareData({ share: true });
          setSharing(false);
          setAllowSharing(true);
        }
        setCopied(true);
      } catch (e) {
        setSharing(false);
        addToast("Sorry, we couldn't share your data", 'danger');
      }
    })();
  }, [shareData, setCopied, setSharing, me, setAllowSharing, addToast]);

  const adjustedWeight = useMemo(() => {
    const ordersMap = {};

    const ordersData = orders.map(order => {
      const timestamp = new Date(new Date(order.date_fulfilled).setHours(12, 0, 0, 0));
      const roast = order.subproduct_id?.product?.roast?.name || '';
      const origin = order.subproduct_id?.product?.origin?.name || '';

      ordersMap[timestamp] = { roast, origin };

      return {
        order: order._id,
        roast,
        origin,
        type: 'order',
        timestamp,
        weight: null,
        diff: null,
      };
    });

    if (!cleanData?.data?.adjusted_weight) {
      return crossfilter(ordersData);
    }

    let currentOrder = ordersData[ordersData.length - 1];

    const weights = Object.entries(cleanData.data.adjusted_weight).map(([date, weight]) => {
      const timestamp = new Date(new Date(Number(date)).setHours(12, 0, 0, 0));
      currentOrder = ordersMap[timestamp] || currentOrder;

      return {
        order: null,
        roast: currentOrder?.roast || '',
        origin: currentOrder?.origin || '',
        type: 'reading',
        timestamp,
        weight,
        diff: Math.round(cleanData.data.diff[date] * 100) / 100,
      };
    });

    return crossfilter([...weights, ...ordersData]);
  }, [cleanData, orders]);

  return (
    <PanelLayout>
      <div className="page page-data">
        <Heading back="/">Data</Heading>
        {(status?.scale_status || (me?.hibernate && userStats)) && (
          <PanelSection>
            {status?.scale_status && (
              <ScaleData
                status={status}
                readings={stats}
                lastTare={lastTare}
                toggleModal={toggleModal}
                isScaleAssistanceRequestPending={isScaleAssistanceRequestPending}
                hasRequestedScaleSupport={hasRequestedScaleSupport}
              />
            )}
            {me?.hibernate && userStats && (
              <Row className="scale-data-row justify-content-center">
                <Col xs="6" md="4" className="mb-2 mb-md-4 d-flex">
                  <InfoNumbers number={userStats.bagsOrdered} text={'Bags of Coffee Ordered'} />
                </Col>
                <Col xs="6" md="4" className="mb-2 mb-md-4 d-flex">
                  <InfoNumbers number={userStats.countriesSampled} text={'Countries Sampled'} />
                </Col>
                <Col xs="6" md="4" className="mb-2 mb-md-4 d-flex">
                  <InfoNumbers number={userStats.weeksWithBottomless} text={'Weeks with Bottomless'} />
                </Col>
              </Row>
            )}
            {status?.scale_status && (
              <div className="d-flex justify-content-between align-items-center mt-2 mt-md-0">
                <div>
                  For FAQs and troubleshooting steps on scales, click{' '}
                  <a
                    href="https://support.bottomless.com/en/categories/236864"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    here
                  </a>
                  .
                </div>
                <Button color="primary" outline disabled={hasRequestedScaleSupport} size="sm" onClick={toggleModal}>
                  {isScaleAssistanceRequestPending ? 'Requesting...' : 'Request Scale Assistance'}
                </Button>
              </div>
            )}
          </PanelSection>
        )}

        <PanelSection
          title="Scale readings"
          subtitle={
            <>
              (Data will refresh every few hours{' '}
              <span aria-label="Hourglass" role="img">
                ⏳
              </span>
              )
            </>
          }
        >
          <Graph stats={stats} emptyStateTitle={wasScaleLastConnected ? 'No recent data' : 'No data yet'} />
        </PanelSection>
        {cleanData?.data?.adjusted_weight && (
          <div className="mb-5 text-center">
            <span className="" aria-label="Alert" role="img">
              🚨 <i> Data is highly experimental </i> 🚨
            </span>
          </div>
        )}
        <div className="mb-5">
          {adjustedWeight.size() > 0 && (
            <DetailedGraphs orders={orders} adjustedWeight={adjustedWeight} cleanData={cleanData} />
          )}
          {isModalOpen && (
            <RequestScaleAssistanceModal
              isOpen={isModalOpen}
              toggle={toggleModal}
              onSubmit={onClickRequestScaleAssistance}
              reasonOptions={reasonOptions}
            />
          )}
          {cleanData?.data?.adjusted_weight && (
            <div className="panel-section">
              <div className="mb-3 d-flex justify-content-between">
                <h4 className="mb-0">Heatmap</h4>
                <div className="text-right">
                  <CopyToClipboard
                    text={`${process.env.REACT_APP_BACKEND_DIRECT_URL}/share/${cleanData.user.referral_id}`}
                    onCopy={onCopy}
                  >
                    <button className={`btn btn-sm btn-${copied ? 'success' : 'dark'}`}>
                      {isSharing && 'Sharing...'}
                      {!isSharing && (copied ? 'Copied!' : 'Share')}
                    </button>
                  </CopyToClipboard>
                  {me.share_data !== undefined && (
                    <div className="d-flex align-items-center">
                      <CustomInput
                        className="switch"
                        id="share-data"
                        type="switch"
                        name="share-data"
                        onChange={onSharingChange}
                        checked={allowSharing}
                      />
                      {isAllowing ? 'Saving...' : 'Enable sharing'}
                    </div>
                  )}
                </div>
              </div>
              <Heatmap data={cleanData.data} />
            </div>
          )}
        </div>
      </div>
    </PanelLayout>
  );
};

DataPageComponent.propTypes = {
  cleanData: PropTypes.object.isRequired,
  getCleanData: PropTypes.func.isRequired,
  getScaleStatus: PropTypes.func.isRequired,
  shareData: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
  me: PropTypes.shape({
    share_data: PropTypes.bool,
    status: PropTypes.string,
    hibernate: PropTypes.bool,
    support: PropTypes.shape({
      order: PropTypes.object,
      scale: PropTypes.object,
    }),
  }).isRequired,
  orders: PropTypes.array.isRequired,
  getOrders: PropTypes.func.isRequired,
  getStatsRecords: PropTypes.func.isRequired,
  stats: PropTypes.array.isRequired,
  lastTare: PropTypes.object,
  requestScaleAssistance: PropTypes.func.isRequired,
  getUserStats: PropTypes.func.isRequired,
};

DataPageComponent.defaultProps = {
  me: {
    support: {
      order: {},
      scale: {},
    },
  },
};

export const DataPage = connect(
  ({ user, order, stats }) => ({
    stats: stats.data,
    lastTare: stats.lastTare,
    orders: order.data ? order.data : [],
    me: user.me,
    cleanData: user.cleanData || {},
  }),
  dispatch => ({
    getCleanData: () => dispatch(getCleanedDataAction()),
    getScaleStatus: () => dispatch(getScaleStatusAction()),
    shareData: data => dispatch(shareDataAction(data)),
    addToast: (message, type) => dispatch(addToastAction(message, type)),
    getOrders: () => dispatch(getOrdersAction()),
    getStatsRecords: () => dispatch(getStatsRecordsAction()),
    getUserStats: () => dispatch(getUserStatsAction()),
    requestScaleAssistance: data => dispatch(requestScaleAssistanceAction(data)),
  })
)(DataPageComponent);
