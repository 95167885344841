import React from 'react';
import PropTypes from 'prop-types';
import { Button, Collapse } from 'reactstrap';
import { useToggle } from '@bottomless/common/hooks';

export const FaqItem = ({ question, answer }) => {
  const [isOpen, toggle] = useToggle();

  return (
    <div className="mb-2">
      <Button block color="text" onClick={toggle} className="text-left px-0 font-weight-bolder">
        {question}
      </Button>
      <Collapse isOpen={isOpen}>
        <div className="pt-2 faq-answer">{answer}</div>
      </Collapse>
    </div>
  );
};

FaqItem.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
};
