import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDataEffect } from '@bottomless/common/hooks';
import { getInTransitionOrdersAction } from '../../../store/order/order.actions';
import { useMemo } from 'react';
import { OrderSources } from '@bottomless/common/constants';

export const useInTransitionIndex = orderId => {
  const dispatch = useDispatch();
  const inTransitionAction = useCallback(() => dispatch(getInTransitionOrdersAction()), [dispatch]);
  const { data: inTransitionData } = useSelector(({ order }) => ({ data: order.inTransition || [] }));

  useDataEffect(inTransitionAction);

  const index = useMemo(() => {
    if (orderId === 'pending') {
      return 0;
    }

    return [...inTransitionData]
      .filter(order => order.source !== OrderSources.USER_ONE_OFF)
      .sort(
        (a, b) =>
          new Date(a.override_fulfillment_date || Date.now()) - new Date(b.override_fulfillment_date || Date.now())
      )
      .findIndex(order => order._id === orderId);
  }, [inTransitionData, orderId]);

  return index;
};
