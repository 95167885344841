import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useDataEffect } from '@bottomless/common/hooks';
import { completeCheckoutAction, getCheckoutAction } from '../../../store/checkout';

export const useCheckout = id => {
  const dispatch = useDispatch();
  const action = useCallback(() => dispatch(getCheckoutAction(id)), [id, dispatch]);

  const { data, error, isFetching: isLoading } = useDataEffect(action);

  const completeCheckout = useCallback(data => dispatch(completeCheckoutAction(id, data)), [id, dispatch]);

  return { data, isLoading, error, completeCheckout };
};
