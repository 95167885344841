import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Image } from '@bottomless/common/components';

const ALIGN = {
  Left: 'left',
  Right: 'right',
};

export const Feature = ({ image, title, description, align = ALIGN.Left }) => (
  <div
    className={classNames('d-flex align-items-center justify-content-between switch-to-sbu-feature mb-4', {
      'switch-to-sbu-feature-left': align === ALIGN.Left,
      'flex-row-reverse': align === ALIGN.Right,
    })}
  >
    <Image
      src={image}
      alt="title"
      width={100}
      height={100}
      className="switch-to-sbu-feature-image"
      wrapperClassName="switch-to-sbu-feature-wrapper"
      useSrcset
    />
    <div className={classNames({ 'mr-4': align === ALIGN.Right, 'ml-4': align === ALIGN.Left })}>
      <div className="font-weight-bolder mb-2">{title}</div>
      <div>{description}</div>
    </div>
  </div>
);

Feature.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  align: PropTypes.string.isRequired,
};
