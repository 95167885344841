import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

export const Step2 = ({ toggle }) => (
  <>
    <div className="mb-4">
      Your subscription has been updated to Subscription by Usage. You can expect a shipment of smart re-ordering scale
      soon.
    </div>
    <div className="mw-300">
      <Button color="primary" outline block onClick={toggle}>
        Close
      </Button>
    </div>
  </>
);

Step2.headingText = 'Your subscription has been updated';

Step2.propTypes = {
  toggle: PropTypes.func.isRequired,
};
