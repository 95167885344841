import React from 'react';
import PropTypes from 'prop-types';
import { RadioBox } from '@bottomless/common/components';
import './PhoneNumber.scss';

export const PhoneNumber = ({ phoneNumbers }) => {
  if (!phoneNumbers.length) {
    return null;
  }

  return (
    <div className="new-subscription-phone">
      <label className="mb-2">
        Phone number <span className="text-secondary small font-weight-normal">(For notifications)</span>
      </label>
      {phoneNumbers.map(account => (
        <RadioBox key={account._id} name="phoneUserId" type="radio" value={account._id} label={account.phone} />
      ))}
      <RadioBox name="phoneUserId" type="radio" value="" label="None" />
    </div>
  );
};

PhoneNumber.propTypes = {
  phoneNumbers: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      phone: PropTypes.string.isRequired,
    })
  ).isRequired,
};
