import * as Sentry from '@sentry/browser';
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Loader } from 'react-feather';
import qs from 'query-string';
import { useConditionalDataEffect } from '@bottomless/common/hooks';
import { useLocalStorage } from '../hooks/use-local-storage.hook';
import { getMeAction, setUserLoggedInAction } from '../store/user/user.actions';
import { LogoutTypes } from '@bottomless/common/store';

const LOCAL_STORAGE_KEY_ACCESS_TOKEN = 'bottomless_access_token';

const setupIdentity = user => {
  if (/@bottomless.com$/.test(user.local.email)) {
    return;
  }

  const { _id, alertSettings, local, onboardingState } = user;

  if (window.hj) {
    window.hj('identify', _id, {
      email: user.local.email,
      username: `${user.first_name} ${user.last_name}`,
      local,
      alertSettings,
      onboardingState,
    });
  }
};

const IsAuthComponent = ({ children, me, getMe, isLoggedIn, setUserLoggedIn, logout }) => {
  const history = useHistory();
  const location = useLocation();

  const [{ bottomless_access_token: defaultToken }, , removeLocalStorage] = useLocalStorage([
    LOCAL_STORAGE_KEY_ACCESS_TOKEN,
  ]);
  const [accessToken, setAccessToken] = useState(defaultToken);
  const { data: user, error } = useConditionalDataEffect(accessToken, getMe, undefined, undefined, null);
  const isWhitelistedRoute = useMemo(
    () =>
      accessToken !== 'shopify_login' &&
      location.pathname.match(/^\/($|profile|shop|account|orders|subscription|switch-to-subscription-by-usage)$/),
    [location, accessToken]
  );

  const accounts = useMemo(() => me?.accounts.filter(account => account.vendor_id === me.vendor_id._id), [me]);

  useEffect(() => {
    if (!isLoggedIn && accounts?.length > 1) {
      setUserLoggedIn();

      const { skipAccountSelection } = qs.parse(location.search.replace(/%26/g, '&'));

      if (!skipAccountSelection && isWhitelistedRoute) {
        history.push('/portal-select');
      }
    }
  }, [isLoggedIn, accounts, history, setUserLoggedIn, isWhitelistedRoute, location]);

  useEffect(() => {
    if (user === undefined || (error?.message === 'Failed to fetch' && !error.status)) {
      return;
    }

    if (error?.status === 429) {
      throw new Error('Too many requests.');
    } else if (!user) {
      setAccessToken(null);
    }
  }, [user, error?.message, error?.status]);

  if (me) {
    Sentry.configureScope(scope => {
      scope.setUser({ id: me._id, email: me.local.email, username: `${me.first_name} ${me.last_name}` });
    });

    setupIdentity(me);
  }

  if (!accessToken) {
    removeLocalStorage(LOCAL_STORAGE_KEY_ACCESS_TOKEN);
    logout();
    history.push({
      pathname: '/send-magic-link',
      search: qs.stringify({ path: location.pathname, params: location.search }),
    });
    return null;
  }

  if (!me) {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <Loader size="20" className="spin mr-2" />
        <span>Loading...</span>
      </div>
    );
  }

  if (!isLoggedIn && accounts?.length > 1 && !isWhitelistedRoute) {
    return null;
  }

  return children;
};

IsAuthComponent.propTypes = {
  children: PropTypes.node.isRequired,
  me: PropTypes.object,
  getMe: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool,
  setUserLoggedIn: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

export const IsAuth = connect(
  ({ user: { me, isLoggedIn } }) => ({ me, isLoggedIn }),
  dispatch => ({
    getMe: () => dispatch(getMeAction()),
    logout: () => dispatch({ type: LogoutTypes.SUCCESS }),
    setUserLoggedIn: () => dispatch(setUserLoggedInAction()),
  })
)(IsAuthComponent);
