import React, { useState, useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useToggle } from '@bottomless/common/hooks';
import { useModal } from '../../../../hooks/use-modal.hook';
import { STEPS } from './steps.constants';
import { StepReturnLabelRequested } from './StepReturnLabelRequested';
import { StepSwitchToFixed } from './StepSwitchToFixed';
import { StepSubscriptionUpdated } from './StepSubscriptionUpdated';
import { StepSubscriptionCancelled } from './StepSubscriptionCancelled';
import { StepPauseOrdering } from './StepPauseOrdering';
import { StepReorderingPaused } from './StepReorderingPaused';
import { StepUndone } from './StepUndone';
import { StepSwitchToByUsage } from './StepSwitchToByUsage';
import { StepSubscriptionUpdatedSbu } from './StepSubscriptionUpdatedSbu';
import { useCanSwitchToSbu } from '../../../../hooks/use-can-switch-to-sbu.hook';
import { SubscriptionType } from '@bottomless/common/src/constants';
import { utcDate } from '@bottomless/common/utils';

const COMPONENTS = {
  [STEPS.SwitchToFixed]: StepSwitchToFixed,
  [STEPS.SwitchToSBU]: StepSwitchToByUsage,
  [STEPS.ReturnLabelRequested]: StepReturnLabelRequested,
  [STEPS.SubscriptionUpdatedFixed]: StepSubscriptionUpdated,
  [STEPS.SubscriptionUpdatedSBU]: StepSubscriptionUpdatedSbu,
  [STEPS.SubscriptionCancelled]: StepSubscriptionCancelled,
  [STEPS.PauseReordering]: StepPauseOrdering,
  [STEPS.ReorderingPaused]: StepReorderingPaused,
  [STEPS.Undone]: StepUndone,
};

export const Hibernate = ({
  me,
  closeAccount,
  requestScaleReturn,
  getNextOrderDateFromNow,
  setDumbPeriod,
  pauseAccount,
}) => {
  const [originalMe] = useState(me);
  const canSwitchToSbu = useCanSwitchToSbu();
  const firstStep = useMemo(
    () =>
      me.subscriptionType !== SubscriptionType.Dynamic
        ? canSwitchToSbu
          ? STEPS.SwitchToSBU
          : STEPS.PauseReordering
        : STEPS.PauseReordering,
    [me, canSwitchToSbu]
  );
  const modalProps = useModal();
  const [isOpen, rawToggle] = useToggle();
  const [step, setStep] = useState(firstStep);
  const [originalPausedUntil, setOriginalPausedUntil] = useState(utcDate(me.pausedUntil));

  useEffect(() => {
    setOriginalPausedUntil(utcDate(me.pausedUntil));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const Component = useMemo(() => COMPONENTS[step], [step]);

  const toggle = useCallback(() => {
    rawToggle();
    setTimeout(() => {
      setStep(firstStep);
    }, 300);
  }, [rawToggle, setStep, firstStep]);

  const setNextStep = useCallback(nextStep => (nextStep ? setStep(nextStep) : toggle()), [setStep, toggle]);

  return (
    <>
      {!me.hibernate && (
        <Button color="primary" outline onClick={toggle}>
          Cancel Subscription
        </Button>
      )}
      <Modal isOpen={isOpen} size="md" toggle={toggle} {...modalProps} className="modal-ordering-strategy">
        <ModalHeader toggle={toggle}>{Component.headingText}</ModalHeader>
        <ModalBody className="text-md-center">
          <Component
            me={me}
            originalMe={originalMe}
            setNextStep={setNextStep}
            closeAccount={closeAccount}
            requestScaleReturn={requestScaleReturn}
            getNextOrderDateFromNow={getNextOrderDateFromNow}
            setDumbPeriod={setDumbPeriod}
            pauseAccount={pauseAccount}
            originalPausedUntil={originalPausedUntil}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

Hibernate.propTypes = {
  me: PropTypes.object.isRequired,
  closeAccount: PropTypes.func.isRequired,
  requestScaleReturn: PropTypes.func.isRequired,
  getNextOrderDateFromNow: PropTypes.func.isRequired,
  setDumbPeriod: PropTypes.func.isRequired,
  pauseAccount: PropTypes.func.isRequired,
};
