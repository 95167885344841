import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCreditsAction } from '../../../store/user';
import { useConditionalDataEffect } from '@bottomless/common/hooks';
import { SubscriptionType } from '@bottomless/common/constants';

export const useCredits = () => {
  const dispatch = useDispatch();

  const me = useSelector(({ user }) => user.me);
  const { data } = useSelector(({ user }) => ({ data: user.credits?.total ? user.credits : null }));

  const creditsAction = useCallback(() => dispatch(getCreditsAction()), [dispatch]);
  const { error, isFetching } = useConditionalDataEffect(
    me?.subscriptionType === SubscriptionType.Prepaid,
    creditsAction
  );

  return { data, error, isFetching };
};
