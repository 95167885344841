import React from 'react';
import PropTypes from 'prop-types';
import { Faq } from './Faq';
import { Card } from 'reactstrap';

const elements = [
  {
    question: 'How do I change my product preference?',
    answer: (
      <>
        You can change your product preference in the <strong>Subscription</strong> Page by clicking on the <strong>"Update"</strong> button
        next to the current product preference.
      </>
    ),
  },
  {
    question: 'How do I change my delivery frequency?',
    answer: (
      <>
        You can change your delivery frequency in the <strong>Subscription</strong> Page by clicking on your preferred
        frequency and confirming the change.
        <br />
        <br />
        Alternatively, you may switch to a Subscribe By Usage subscription by selecting &quot;Deliver based on my
        usage&quot;.
      </>
    ),
  },
  {
    question: 'How do I delay my order?',
    answer: (
      <>
        You can delay your order before it is processed and shipped by clicking on &quot;Change Fulfilment Date&quot; in
        the <strong>Upcoming Order</strong> section.
        <br />
        <br />
        Once the order has been processed, you won’t be able to delay it.
      </>
    ),
  },
  {
    question: 'How do I switch to Subscribe By Usage?',
    answer: (
      <>
        You may switch to a Subscribe By Usage subscription by going to <strong>Frequency</strong> in the{' '}
        <strong>Subscription </strong>
        Page, and selecting &quot;<strong>Deliver based on my usage</strong>&quot;.
      </>
    ),
  },
  {
    question: 'How do I change my personal details like address and billing details?',
    answer: (
      <>
        Change your personal details in the <strong>Settings</strong> Page by clicking the &quot;<strong>Edit</strong>&quot; buttons.
      </>
    ),
  },
];

export const SubscriptionFixedFaq = ({ vendor }) => (
  <>
    <Faq elements={elements} />
    <Card body className="mt-3">
      <div className="font-weight-bolder mb-3">Still need help?</div>
      <div className="small">Contact {vendor?.ordering_email || 'support@bottomless.com'}.</div>
    </Card>
  </>
);

SubscriptionFixedFaq.propTypes = {
  vendor: PropTypes.shape({
    ordering_email: PropTypes.string.isRequired,
  }).isRequired,
};
