import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { SubscriptionType } from '@bottomless/common/constants';
import { DateFormat } from '@bottomless/common/components';
import { utcDate } from '@bottomless/common/utils';
import { READABLE_FORMAT } from '../../../../utils/dates';
import { SkipOrder } from '../../../../components/SkipOrder/SkipOrder';

export const SkipNextOrder = ({ me, vendor, skipOrder, getNextOrderDate, withHr, isOpen, toggle }) => {
  const isFixedSubscription = useMemo(() => me.subscriptionType === SubscriptionType.Fixed, [me]);
  const isPrepaidSubscription = useMemo(() => me.subscriptionType === SubscriptionType.Prepaid, [me]);

  if (!isOpen && !isFixedSubscription && !isPrepaidSubscription) {
    return null;
  }

  return (
    <>
      <div>
        <div className="d-flex justify-content-between mt-1 mb-2">
          <div className="font-weight-bolder order-heading">Next reorder date</div>
          <div>
            <DateFormat date={utcDate(getNextOrderDate(me.dumb_period, true, 1, me))} format={READABLE_FORMAT} />
          </div>
        </div>
        <SkipOrder
          me={me}
          vendor={vendor}
          skipOrder={skipOrder}
          block
          outline
          fulfillmentDate={utcDate(getNextOrderDate(me.dumb_period, true, 1, me))}
          nextFulfillmentDate={utcDate(getNextOrderDate(me.dumb_period, false, 2, me))}
          isOpen={isOpen}
          toggle={toggle}
        />
      </div>
      {withHr && <hr className="m4-1 mb-2" />}
    </>
  );
};

SkipNextOrder.propTypes = {
  me: PropTypes.shape({
    dumb_period: PropTypes.number,
    subscriptionType: PropTypes.string,
    dumb_type: PropTypes.string,
  }).isRequired,
  vendor: PropTypes.object.isRequired,
  skipOrder: PropTypes.func.isRequired,
  getNextOrderDate: PropTypes.func.isRequired,
  withHr: PropTypes.bool,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
};
