import { Box } from '@bottomless/common/components';
import React from 'react';
import { PlainObjectCategoryManifest } from '../../../components/CategoryManifest';
import { PanelSection } from '../../../components/PanelSection';
import { CoffeeOrderDates, GenericOrderDates } from '../manifest';
import { OrderTracking } from '../../../components/OrderTracking';

const COMPONENTS = {
  CoffeeProfileTransitionProgress: CoffeeOrderDates,
  GenericProfileTransitionProgress: GenericOrderDates,
};

export const Tracking = props => (
  <PanelSection title="Order tracking">
    <Box>
      <PlainObjectCategoryManifest
        manifestKey="profileTransitionProgress"
        components={COMPONENTS}
        fallbackComponent={GenericOrderDates}
        {...props}
      />
      <OrderTracking {...props} />
    </Box>
  </PanelSection>
);
