import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import moment from 'moment';
import { Info } from 'react-feather';
import { Form } from '@bottomless/common/components';
import { useToast } from '@bottomless/common/hooks';
import { FrequencyInput } from '../../../../components/FrequencyInput';
import { SelectFrequency } from '../SelectFrequency';
import { useState } from 'react';
import { SubscriptionType } from '@bottomless/common/src/constants';
import { useCanSwitchToSbu } from '../../../../hooks/use-can-switch-to-sbu.hook';

const Schema = Yup.object().shape({
  dumb_period: Yup.number(),
});

export const FrequencyForm = ({ me, setDumbPeriod, getNextOrderDateFromNow, isOpen, toggle: rawToggle, setOpen }) => {
  const form = useRef();
  const [otherOptionsHidden, setOtherOptionsHidden] = useState(false);
  const [step, setStep] = useState(0);

  const canSwitchToSbu = useCanSwitchToSbu();

  const toggle = useCallback(
    (e, hideOtherOptions) => {
      if (form.current?.state.values.dumb_period === e.target.value) {
        return;
      }

      setOtherOptionsHidden(!!hideOtherOptions);

      rawToggle();

      if (isOpen && form.current) {
        form.current.setFieldValue('dumb_period', me.dumb_period);
      }
    },
    [rawToggle, isOpen, form, me]
  );

  const handleBasedOnYourUsageClick = useCallback(
    (e, { setFieldValue }) => {
      setFieldValue('dumb_period', 0);
      toggle(e, true);
    },
    [toggle]
  );

  useEffect(() => {
    if (form.current && !form.current.state.touched.dumb_period) {
      form.current.setFieldValue('dumb_period', me.dumb_period);
    }
  }, [me, form]);

  const onSetDumbPeriod = useCallback(
    data => {
      if (!Number(data.dumb_period)) {
        return setDumbPeriod(
          {
            dumb_period: null,
            sendNotification: true,
          },
          'profile-frequency-form'
        );
      } else {
        return setDumbPeriod(
          {
            ...data,
            triggerOrder: true,
            override_fulfillment_date: moment(getNextOrderDateFromNow(data.dumb_period)).format('YYYY-MM-DD'),
          },
          'profile-frequency-form'
        );
      }
    },
    [setDumbPeriod, getNextOrderDateFromNow]
  );

  const periodChangeToast = useToast('Your frequency updated successfully');

  const onSuccess = useCallback(
    data => {
      if (data.subscriptionType === SubscriptionType.Dynamic) {
        setStep(1);
      } else {
        periodChangeToast();
        setOpen(false);
      }
    },
    [periodChangeToast, setOpen]
  );

  return (
    <Form
      initialValues={{ dumb_period: me.dumb_period }}
      validationSchema={Schema}
      onSubmit={onSetDumbPeriod}
      onSuccess={onSuccess}
      innerRef={form}
      className="mt-3 form-fixed-period"
    >
      {({ isSubmitting, setFieldValue, setFieldTouched, values, submitForm }) => (
        <>
          <FrequencyInput
            name="dumb_period"
            value={values.dumb_period}
            setFieldValue={setFieldValue}
            onChange={toggle}
            withOther={false}
            setFieldTouched={setFieldTouched}
            onOtherClick={toggle}
          />
          {canSwitchToSbu && (
            <div
              className="fixed-subscription-button btn btn-light btn-block"
              onClick={e => handleBasedOnYourUsageClick(e, { setFieldValue })}
            >
              <div className="simple-subscription-section d-flex">
                <div>Deliver based on your usage</div>
                <div className="d-flex align-items-center">
                  <Info width="18" height="18" strokeWidth="1.75" />
                </div>
              </div>
            </div>
          )}
          <SelectFrequency
            step={step}
            me={me}
            isSubmitting={isSubmitting}
            onSubmitClick={submitForm}
            isOpen={isOpen}
            toggle={toggle}
            dumbPeriod={isNaN(values.dumb_period) ? 0 : Number(values.dumb_period)}
            otherOptionsHidden={otherOptionsHidden}
          />
        </>
      )}
    </Form>
  );
};

FrequencyForm.propTypes = {
  me: PropTypes.shape({
    dumb_period: PropTypes.number,
    lineItems: PropTypes.array,
    subscriptionType: PropTypes.string,
  }).isRequired,
  setDumbPeriod: PropTypes.func.isRequired,
  getNextOrderDateFromNow: PropTypes.func.isRequired,
  getNextOrderDate: PropTypes.func.isRequired,
  skipOrder: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired,
};
