import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useModal } from '../../hooks/use-modal.hook';
import { STEPS } from './steps';
import { StepSkip } from './StepSkip';
import { StepSubscriptionUpdatedSbu } from './StepSubscriptionUpdatedSbu';
import { DumbType, SubscriptionType } from '@bottomless/common/constants';
import { StepReschedule } from './StepReschedule';

const COMPONENTS = {
  [STEPS.Skip]: StepSkip,
  [STEPS.Reschedule]: StepReschedule,
  [STEPS.SubscriptionUpdatedSBU]: StepSubscriptionUpdatedSbu,
};

export const SkipOrder = ({
  me,
  vendor,
  fulfillmentDate,
  nextFulfillmentDate,
  skipOrder,
  block,
  outline,
  className,
  isOpen,
  toggle: rawToggle,
  children = 'Skip',
}) => {
  const isDelayable = useMemo(
    () =>
      me?.subscriptionType === SubscriptionType.Dynamic ||
      (me?.subscriptionType === SubscriptionType.Fixed &&
        ![DumbType.FirstThursday, DumbType.FirstWednesday, DumbType.SameDayMonthly].includes(me.dumb_type)),
    [me]
  );

  const buttonContent = useMemo(() => (isDelayable ? 'Reschedule order' : children), [children, isDelayable]);

  const modalProps = useModal();

  const firstStep = useMemo(() => (isDelayable ? STEPS.Reschedule : STEPS.Skip), [isDelayable]);
  const [step, setStep] = useState(firstStep);

  const toggle = useCallback(() => {
    rawToggle();
    setTimeout(() => setStep(firstStep), 300);
  }, [rawToggle, setStep, firstStep]);

  const setNextStep = useCallback(nextStep => (nextStep ? setStep(nextStep) : toggle()), [setStep, toggle]);

  const Component = useMemo(() => COMPONENTS[step], [step]);

  return (
    <>
      <Button color="primary" block={block} outline={outline} onClick={toggle} className={className}>
        {buttonContent}
      </Button>
      <Modal isOpen={isOpen} size="md" toggle={toggle} {...modalProps}>
        <ModalHeader toggle={toggle}>{Component.headingText}</ModalHeader>
        <ModalBody className="text-center">
          <Component
            me={me}
            vendor={vendor}
            setNextStep={setNextStep}
            fulfillmentDate={fulfillmentDate}
            nextFulfillmentDate={nextFulfillmentDate}
            skipOrder={skipOrder}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

SkipOrder.propTypes = {
  skipOrder: PropTypes.func.isRequired,
  block: PropTypes.bool,
  outline: PropTypes.bool,
  className: PropTypes.string,
  fulfillmentDate: PropTypes.object,
  nextFulfillmentDate: PropTypes.object,
  me: PropTypes.object,
  vendor: PropTypes.object,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
  children: PropTypes.node,
};
