import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDataEffect } from '@bottomless/common/hooks';
import { getInTransitionOrdersAction } from '../../../store/order/order.actions';
import { getCustomListAction } from '../../../store/product';
import { useMemo } from 'react';
import { OrderSources } from '@bottomless/common/constants';

export const useInTransition = () => {
  const dispatch = useDispatch();
  const inTransitionAction = useCallback(() => dispatch(getInTransitionOrdersAction()), [dispatch]);
  const { data: inTransitionData } = useSelector(({ order }) => ({ data: order.inTransition }));

  const { error, isFetching: isLoading } = useDataEffect(inTransitionAction);

  const { data: queueData } = useSelector(({ product: { wishlist } }) => ({ data: wishlist || [] }));
  const queuesAction = useCallback(() => dispatch(getCustomListAction()), [dispatch]);
  useDataEffect(queuesAction);

  const data = useMemo(() => {
    const wishlistCopy = queueData?.length ? [...queueData] : [];

    return [...inTransitionData]
      .sort(
        (a, b) =>
          new Date(a.override_fulfillment_date || Date.now()) - new Date(b.override_fulfillment_date || Date.now())
      )
      .map(order => {
        if (order.source === OrderSources.USER_ONE_OFF || order.subproduct_id) {
          return order;
        }

        const subproduct = wishlistCopy.shift();
        return {
          ...order,
          grind: subproduct?.grind,
          subproduct_id: subproduct,
          subproduct_name: subproduct?.product.name,
          subvendor_id: subproduct?.product.vendor_id,
        };
      })
      .sort((a, b) => new Date(b.date_initiated) - new Date(a.date_initiated));
  }, [inTransitionData, queueData]);

  return { data, isLoading, error };
};
