import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { utcDate } from '@bottomless/common/utils';
import { OrderStatuses, SubscriptionType, UserStatuses } from '@bottomless/common/constants';
import { Box } from '@bottomless/common/components';
import { Orders } from './Orders';
import { TriggerNow } from './TriggerNow';
import { Col, Row } from 'reactstrap';
import { PanelSection } from '../../../components/PanelSection';

export const OrderEmptyState = ({ orders, user, vendor, triggerOrder, getNextOrderDate, credits, queue }) => {
  const isInactiveUser = useMemo(() => user.pausedUntil || user.hibernate || user.status === UserStatuses.Closed, [
    user,
  ]);

  const firstQueueElement = useMemo(() => (queue?.length > 0 ? queue[0] : null), [queue]);

  if (orders.length > 0) {
    return null;
  }

  if (user.subscriptionType !== SubscriptionType.Dynamic) {
    if (isInactiveUser) {
      return null;
    }

    if (user.subscriptionType === SubscriptionType.Prepaid && !credits?.granted) {
      return null;
    }

    return (
      <PanelSection title="Upcoming Orders">
        <Orders
          orders={[
            {
              _id: 'pending',
              status: OrderStatuses.Initiated,
              override_fulfillment_date: utcDate(getNextOrderDate(user.dumb_period, false, 1, user)),
              ...(firstQueueElement
                ? {
                    subproduct_id: { product: firstQueueElement.product, variant: firstQueueElement.variant },
                    grind: firstQueueElement.grind,
                  }
                : {}),
            },
          ]}
          user={user}
        />
      </PanelSection>
    );
  }

  return (
    <PanelSection title="Upcoming Orders">
      <Row className="section-trigger-now">
        <Col xs="12" lg="6">
          <div className="mb-3">
            {!isInactiveUser && 'No orders in progress. Next order will be triggered by the scale based on your usage.'}
          </div>
          <Box>
            <div className="mb-3 d-flex align-items-baseline">
              {!isInactiveUser && (
                <>
                  <div className="mr-2">
                    <span className="info-icon small">i</span>
                  </div>
                  Need it earlier? Trigger manually to order for the earliest available date.
                </>
              )}
              {isInactiveUser &&
                'Automatic re-ordering is turned off, but you can trigger an order based on your preferences when needed.'}
            </div>
            <TriggerNow vendor={vendor} triggerOrder={triggerOrder} />
          </Box>
        </Col>
      </Row>
    </PanelSection>
  );
};

OrderEmptyState.propTypes = {
  orders: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  vendor: PropTypes.object.isRequired,
  triggerOrder: PropTypes.func.isRequired,
  getNextOrderDate: PropTypes.func.isRequired,
  credits: PropTypes.shape({
    granted: PropTypes.number,
  }),
  queue: PropTypes.array.isRequired,
};
