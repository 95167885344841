import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { DateFormat } from '@bottomless/common/components';
import { READABLE_FORMAT } from '../../../../utils/dates';
import { Button } from 'reactstrap';

export const StepReorderingPaused = ({ me, setNextStep }) => {
  const onClick = useCallback(() => setNextStep(), [setNextStep]);

  return (
    <>
      <div className="mb-4">
        You won&apos;t get any automatic re-orders until this date. You can always come back and adjust it to your
        needs.
      </div>
      <div className="mw-300">
        <div className="d-flex justify-content-between mt-3 mb-4">
          <div className="font-weight-bolder order-heading">Paused Until:</div>
          <div>
            <DateFormat date={me.pausedUntil} format={READABLE_FORMAT} fromZeroed />
          </div>
        </div>
        <Button color="primary" outline block onClick={onClick}>
          Close
        </Button>
      </div>
    </>
  );
};

StepReorderingPaused.headingText = 'Automatic re-ordering is paused';

StepReorderingPaused.propTypes = {
  me: PropTypes.shape({
    pausedUntil: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  }).isRequired,
  setNextStep: PropTypes.func.isRequired,
  requestScaleReturn: PropTypes.func.isRequired,
  getNextOrderDateFromNow: PropTypes.func.isRequired,
};
