import React, { useEffect } from 'react';
import { PanelLayout } from '../../layouts/PanelLayout';

export const LogoutPage = () => {
  useEffect(() => {
    fetch('/account/logout').finally(() => {
      localStorage.removeItem('bottomless_access_token');
      window.location.href = `${process.env.REACT_APP_BACKEND_DIRECT_URL}/shopify/logout?shop=${window.location.origin}`;
    });
  }, []);

  return (
    <PanelLayout>
      <h2 className="text-center">Logging out...</h2>
    </PanelLayout>
  );
};

LogoutPage.propTypes = {};
