import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, Image, SubmitButton, VariantPrice } from '@bottomless/common/components';
import { fromOz } from '@bottomless/common/src/utils';
import { Col, Row } from 'reactstrap';
import { X } from 'react-feather';
import { useEffect } from 'react';

import './CoffeeChosenProduct.scss';

const BUTTONS = {
  Select: 'select',
  Remove: 'remove',
};

export const CoffeeChosenProduct = ({
  user,
  product,
  variant: variantId,
  grind: grindId,
  setFieldValue,
  attributes,
  isSubmitting,
  onClear,
  quantity,
  onDelete,
}) => {
  const [lastButtonClicked, setLastButtonClicked] = useState();

  const handleDelete = useCallback(
    (...data) => {
      setLastButtonClicked(BUTTONS.Remove);
      return onDelete(...data);
    },
    [onDelete]
  );

  const variantOptions = useMemo(
    () =>
      product.variants.reduce(
        (all, variant) => ({ ...all, [variant._id]: fromOz({ oz: variant.size, unit: variant.unit }).formatted }),
        {}
      ),
    [product]
  );

  const selectedVariant = useMemo(() => product.variants.find(v => v._id === variantId) || product.variants[0], [
    variantId,
    product,
  ]);

  const grindOptions = useMemo(() => {
    const mappedVariants = Object.keys(selectedVariant?.original_id_mapping || {});

    return (product.available_ground
      ? (attributes.grinds || []).filter(grind => grind.default || mappedVariants.includes(grind._id))
      : (attributes.grinds || []).filter(grind => grind.default)
    ).reduce((all, grind, i) => ({ ...all, [grind._id]: !i ? grind.name : `Ground: ${grind.name}` }), {});
  }, [product.available_ground, attributes, selectedVariant]);

  useEffect(() => {
    const grindOptionsIds = Object.keys(grindOptions);

    if (!grindOptionsIds.includes(grindId)) {
      setFieldValue('grind', grindOptionsIds[0]);
    }
  }, [grindId, variantId, grindOptions, setFieldValue]);

  return (
    <>
      <div key={product._id} className="d-flex align-items-center justify-content-between mb-3">
        <div className="d-flex align-items-center">
          <Image src={product.small_image_src} alt={product.name} width="75" height="75" className="mr-2" />
          <div>
            <div>{product.name}</div>
            <div className="text-secondary">
              <VariantPrice user={user} variant={selectedVariant} pricingRule={user.pricing_rule} hideDiscount />
            </div>
          </div>
        </div>
        <div className="text-sm text-secondary cursor-pointer" onClick={onClear}>
          <X width="16" height="16" strokeWidth="3.5" />
        </div>
      </div>
      <Row>
        <Col xs="12" md="6">
          <Field label="Size" type="select" name="variant" options={variantOptions} />
        </Col>
        <Col xs="12" md="6">
          <Field label="Grind" type="select" name="grind" options={grindOptions} />
        </Col>
        {quantity !== null && quantity !== undefined && (
          <Col xs="12" md="6">
            <Field label="Quantity" type="number" name="quantity" min={1} />
          </Col>
        )}
      </Row>
      <Row className="mt-1 justify-space-evenly">
        <SubmitButton
          isSubmitting={lastButtonClicked === BUTTONS.Select && isSubmitting}
          size="default"
          onClick={() => setLastButtonClicked(BUTTONS.Select)}
          disabled={isSubmitting}
        >
          Select
        </SubmitButton>
        {onDelete && (
          <SubmitButton
            isSubmitting={lastButtonClicked === BUTTONS.Remove && isSubmitting}
            outline
            onClick={handleDelete}
            size="default"
            disabled={isSubmitting}
          >
            Remove
          </SubmitButton>
        )}
      </Row>
    </>
  );
};

CoffeeChosenProduct.propTypes = {
  product: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    small_image_src: PropTypes.string.isRequired,
    variants: PropTypes.arrayOf(PropTypes.shape({ size: PropTypes.number })).isRequired,
    available_ground: PropTypes.bool,
  }).isRequired,
  variant: PropTypes.string.isRequired,
  grind: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  attributes: PropTypes.shape({
    grinds: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        default: PropTypes.bool,
      })
    ).isRequired,
  }).isRequired,
  isSubmitting: PropTypes.bool,
  onClear: PropTypes.func.isRequired,
  quantity: PropTypes.number,
  onDelete: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};
