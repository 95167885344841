import { useMemo } from 'react';
import { useSubscriptionFrequency } from './use-subscription-frequency.hook';
import { useSelector } from 'react-redux';
import { SubscriptionType } from '@bottomless/common/constants';

export const useCanSwitchToSbu = () => {
  const { me } = useSelector(({ user }) => ({ me: user.me }));

  const { availableOtherOptions } = useSubscriptionFrequency();

  const isFixedSubscriber = useMemo(() => me.subscriptionType === SubscriptionType.Fixed, [me]);

  const showFrequencyAsInfo = useMemo(() => availableOtherOptions.length <= 1 && !me?.lineItems.length, [
    availableOtherOptions,
    me,
  ]);

  const isInternationalCustomer = useMemo(
    () =>
      !me?.verifiedAddress?.street1 || (me?.verifiedAddress?.countryCode && me.verifiedAddress.countryCode !== 'US'),
    [me]
  );
  const isSubscribeByUsageDisabled = useMemo(
    () => isInternationalCustomer || me?.lineItems?.length || showFrequencyAsInfo,
    [isInternationalCustomer, me, showFrequencyAsInfo]
  );

  return isFixedSubscriber && !isSubscribeByUsageDisabled;
};
