import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { Form, Field, SubmitButton } from '@bottomless/common/components';
import { addToastAction } from '@bottomless/common/store';
import { getMeAction, textAlertsAction, verifyPhoneAction } from '../../store/user';
import { PanelLayoutGuest } from '../../layouts/PanelLayoutGuest';
import { TextConfirmationModal } from './components';

const TextAlertsSchema = Yup.object().shape({
  alertSettings: Yup.object()
    .shape({ gifs: Yup.bool() })
    .required('This field is required'),
  phone: Yup.string().matches(/^\+1\s.?[(]?[0-9]{3}[)]\s.?[0-9]{3}[-][0-9]{4}|^\+1[0-9]{10}/i, 'Invalid phone number'),
});

export const SetupTextPageComponent = ({ getMe, me, textAlerts, addToast, verifyPhone }) => {
  const history = useHistory();

  const [isModal, showModal] = useState(false);
  const onSuccess = useCallback(() => history.push('/profile'), [history]);

  const toggleModal = () => showModal(!isModal);

  useEffect(() => {
    getMe();
  }, [getMe]);

  const handleSuccess = ({ alertSettings, phone, confirmationSent }, { resetForm }) => {
    resetForm({ alertSettings, phone });
    addToast('Text alerts have been successfully saved');

    if (confirmationSent) {
      showModal(true);
    } else {
      onSuccess();
    }
  };

  const handleVerifySuccess = () => {
    showModal(false);
    addToast('Phone number have been successfully verified');
    onSuccess();
  };

  return (
    <PanelLayoutGuest>
      <h1 className="mb-4 text-center">Setup Text Notifications</h1>
      <div className="text-center mb-4">Receive text notifications about orders and products.</div>
      <div className="page-setup-text">
        <Row>
          <Col xs={12} md={{ offset: 3, size: 6 }}>
            {me && (
              <>
                <Col xs="12">
                  <Form
                    initialValues={{ alertSettings: me.alertSettings || { gifs: true }, phone: me.phone || '' }}
                    validationSchema={TextAlertsSchema}
                    onSubmit={textAlerts}
                    onSuccess={handleSuccess}
                    className="mw-300"
                  >
                    {({ isSubmitting, values }) => (
                      <>
                        <Field name="phone" type="phone" label="Phone number" />
                        <div className="mt-3 text-center">
                          <SubmitButton size={null} isSubmitting={isSubmitting} disabled={!values.phone}>
                            Save
                          </SubmitButton>
                          <div className="mt-3">
                            <Link to="/profile" className="small text-center">
                              I&apos;ll do this later
                            </Link>
                          </div>
                        </div>
                      </>
                    )}
                  </Form>
                </Col>
                <TextConfirmationModal
                  isOpen={isModal}
                  toggle={toggleModal}
                  onSubmit={verifyPhone}
                  onSuccess={handleVerifySuccess}
                />
              </>
            )}
          </Col>
        </Row>
      </div>
    </PanelLayoutGuest>
  );
};

SetupTextPageComponent.propTypes = {
  me: PropTypes.object,
  getMe: PropTypes.func.isRequired,
  textAlerts: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
  verifyPhone: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export const SetupTextPage = connect(
  ({ user: { me } }) => ({ me }),
  dispatch => ({
    textAlerts: data => dispatch(textAlertsAction(data)),
    verifyPhone: data => dispatch(verifyPhoneAction(data)),
    addToast: message => dispatch(addToastAction(message)),
    getMe: () => dispatch(getMeAction()),
  })
)(SetupTextPageComponent);
