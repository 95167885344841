import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useToggle } from '@bottomless/common/hooks';
import { useModal } from '../../hooks/use-modal.hook';
import { AddressForm } from './components';

const EditAddressModalComponent = ({ user, verifyAddress }) => {
  const [isOpen, toggleOpen] = useToggle();
  const modalProps = useModal();

  return (
    <>
      <Button color="primary" outline size="sm" onClick={toggleOpen}>
        Edit
      </Button>
      {isOpen && (
        <Modal isOpen={isOpen} toggle={toggleOpen} size="lg" {...modalProps}>
          <ModalHeader toggle={toggleOpen}>Edit Address</ModalHeader>
          <ModalBody>
            <AddressForm me={user} onSubmit={verifyAddress} onSuccess={toggleOpen} />
            <p className="mt-4 small text-secondary text-center">
              Warning: This will update the address for this and all subsequent orders!
            </p>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

EditAddressModalComponent.propTypes = {
  user: PropTypes.object.isRequired,
  verifyAddress: PropTypes.func.isRequired,
};

export const EditAddressModal = memo(EditAddressModalComponent);
