import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDataEffect } from '@bottomless/common/hooks';
import { getOrdersAction } from '../../../store/order/order.actions';
import { useMemo } from 'react';
import { OrderSources } from '@bottomless/common/constants';

export const useOrders = () => {
  const dispatch = useDispatch();
  const action = useCallback(() => dispatch(getOrdersAction()), [dispatch]);
  const { data } = useSelector(({ order }) => ({ data: order.data }));

  const { error, isFetching: isLoading } = useDataEffect(action);

  const lastSubscriptionOrder = useMemo(() => data?.find(order => order.source !== OrderSources.USER_ONE_OFF), [data]);

  return { data, isLoading, error, lastSubscriptionOrder };
};
