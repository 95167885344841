import { OrderStatuses } from '@bottomless/common/src/constants';
import { useMemo } from 'react';

const AVAILABLE_STATUSES = {
  Scheduled: 'scheduled',
  Fulfilling: 'fulfilling',
  InTransit: 'in_transit',
  OutOfDelivery: 'out_for_delivery',
  Delivered: 'delivered',
};

export const useStatus = ({ order }) => {
  return useMemo(() => {
    if (!order) {
      return undefined;
    }

    if (order.date_arrived) {
      return AVAILABLE_STATUSES.Delivered;
    }

    if (Object.values(AVAILABLE_STATUSES).includes(order.shipping_status)) {
      return order.shipping_status;
    }

    if (
      order.status === OrderStatuses.SentToRoaster ||
      (order.status === OrderStatuses.Fulfilled && !order.tracking_number)
    ) {
      return AVAILABLE_STATUSES.Fulfilling;
    }

    if (order.status === OrderStatuses.Fulfilled && order.tracking_number) {
      return AVAILABLE_STATUSES.InTransit;
    }

    return AVAILABLE_STATUSES.Scheduled;
  }, [order]);
};
