import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { datesService } from '@bottomless/common/utils';
import moment from 'moment';
import { Graph } from './Graph';
import { PanelSection } from '../../../components/PanelSection';

export const DataGraph = ({ cleanData, order, stats }) => {
  const adjustedWeight = useMemo(() => {
    if (!cleanData?.data?.adjusted_weight) {
      return [];
    }

    let weights = Object.entries(cleanData.data.adjusted_weight).map(([date, weight]) => {
      const timestamp = new Date(new Date(Number(date)).setHours(12, 0, 0, 0));

      return {
        timestamp,
        adj_weight: weight ? weight : 0,
      };
    });
    const fourteen_days_ago = new Date();

    fourteen_days_ago.setDate(fourteen_days_ago.getDate() - 14);

    weights = weights.filter(weight => weight.timestamp >= fourteen_days_ago);

    return weights;
  }, [cleanData]);

  const predictedDay = useMemo(() => {
    const predicates = Array.isArray(order.predictions) ? order.predictions[0] : order.predictions;

    if (!predicates?.tomorrow_predictions) {
      return '3-6';
    }

    let sum = 0;
    let predictedDay = 0;
    predicates.tomorrow_predictions.some((v, i) => {
      sum += +v;
      predictedDay = i;

      if (sum >= 0.9) {
        return true;
      }
      return false;
    });

    return predictedDay;
  }, [order]);

  const estimatedDeliveryRange = useMemo(() => {
    const fromDate = order.date_fulfilled ? new Date(order.date_fulfilled) : new Date();
    fromDate.setHours(0, 0, 0, 0);

    return [...Array(!isNaN(predictedDay) ? predictedDay : 6).keys()].map(
      i => new Date(fromDate.getTime() + i * 86400000)
    );
  }, [order, predictedDay]);

  const estimatedDelivery = useMemo(() => estimatedDeliveryRange[estimatedDeliveryRange.length - 1], [
    estimatedDeliveryRange,
  ]);

  const showEstimatedDelivery = useMemo(() => {
    if (moment(estimatedDelivery).startOf('day') < moment().startOf('day')) {
      return false;
    }
    if (!order?.tracking_updates?.length) {
      return true;
    }
    const trackingDeliveryDate = order.tracking_updates[order.tracking_updates.length - 1].est_delivery_date;
    if (!trackingDeliveryDate) {
      return true;
    }

    if (
      moment(estimatedDelivery)
        .startOf('day')
        .isSame(moment(new Date(trackingDeliveryDate)).startOf('day'))
    ) {
      return true;
    }
    return false;
  }, [order, estimatedDelivery]);

  const scheduledDays = useMemo(
    () =>
      order.override_fulfillment_date &&
      (new Date(order.override_fulfillment_date) -
        new Date(datesService.formatDateInSeattle(new Date(), 'YYYY-MM-DD'))) /
        (1000 * 60 * 60 * 24),
    [order]
  );

  const yMax = stats?.reduce((max, record) => (record.adj_weight > max ? record.adj_weight : max), 0);
  const graphDataset = useMemo(() => {
    return {
      options: {
        annotation: {
          annotations: [
            {
              drawTime: 'afterDatasetsDraw',
              type: 'box',
              xScaleID: 'x-axis-0',
              yScaleID: 'y-axis-0',
              xMin: estimatedDeliveryRange[0],
              xMax: estimatedDelivery,
              yMin: 0,
              yMax: Math.max(yMax, 12),
              backgroundColor: 'rgba(144, 238, 144, 0.4)',
              borderColor: '#666666',
              borderWidth: 0,
            },
            {
              drawTime: 'afterDatasetsDraw',
              type: 'line',
              mode: 'vertical',
              scaleID: 'x-axis-0',
              value: estimatedDeliveryRange[Math.floor(estimatedDeliveryRange.length / 2)],
              borderColor: 'rgba(0,0,0,0)',
              borderWidth: 0,
            },
          ],
        },
        scales: {
          xAxes: [
            {
              type: 'time',
              time: { unit: 'day', max: new Date(estimatedDelivery.getTime() + 86400000) },
              gridLines: { display: false },
            },
          ],
          yAxes: [{ ticks: { callback: item => `${item} oz`, suggestedMax: 12, suggestedMin: 0 } }],
        },
        tooltips: {
          callbacks: {
            title: data => {
              if (data[0].datasetIndex === 1) {
                return `Predicted order arrival ${estimatedDeliveryRange[
                  estimatedDeliveryRange.length - 1
                ].toLocaleDateString()}`;
              }

              return null;
            },
            label: data => {
              if (data.datasetIndex === 0) {
                return data.value;
              }

              return null;
            },
          },
        },
      },
    };
  }, [estimatedDelivery, estimatedDeliveryRange]);

  if (order.date_arrived || scheduledDays > 1) {
    return null;
  }

  const dateFormatter = new Intl.DateTimeFormat('en-US', { month: 'short', day: '2-digit' });

  if (adjustedWeight.length) {
    return (
      <PanelSection>
        {estimatedDelivery && showEstimatedDelivery && (
          <div className="text-right small mb-1">
            <span className="text-secondary">Estimated delivery date:</span>{' '}
            <span>{dateFormatter.format(estimatedDelivery)}</span>
          </div>
        )}
        <Graph stats={adjustedWeight} {...graphDataset} />
      </PanelSection>
    );
  }

  if (adjustedWeight.length === 0 && stats?.length > 0) {
    return (
      <PanelSection>
        {estimatedDelivery && (
          <div className="text-right small mb-1">
            <span className="text-secondary">Estimated delivery date:</span>{' '}
            <span>{dateFormatter.format(estimatedDelivery)}</span>
          </div>
        )}
        <Graph stats={stats} {...graphDataset} />
      </PanelSection>
    );
  }

  return null;
};

DataGraph.propTypes = {
  cleanData: PropTypes.object,
  order: PropTypes.object,
  stats: PropTypes.array,
};
