import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'reactstrap';
import { Faq } from './Faq';

const elements = [
  {
    question: 'How do I change my product preference?',
    answer: (
      <>
        You can change your product preference in the <strong>Subscription</strong> Page by clicking on the <strong>"Update"</strong> button
        next to the current product preference.
      </>
    ),
  },
  {
    question: 'When will my next order arrive?',
    answer: (
      <>
        Re-orders are triggered based on your usage.
        <br />
        <br />
        When an order has been triggered, you will be able to see your order status and estimated delivery date under Upcoming
        Orders.
      </>
    ),
  },
  {
    question: 'How do I delay my order?',
    answer: (
      <>
        You can delay your order before it is processed and shipped by clicking on &quot;Delay this Order&quot; in the 
        <strong> Upcoming Order</strong> section.
        <br />
        <br />
        Once the order has been processed, you won&apos;t be able to delay it.
      </>
    ),
  },
  {
    question: 'How do I cancel my account?',
    answer: "To cancel your account, go to your Settings page and click on \"Cancel Subscription\".",
  },
  {
    question: 'How do I switch to Fixed Frequency Subscription?',
    answer: (
      <>
        You may switch to a Fixed Frequency Subscription by going to <strong>Subscription</strong> Page, selecting
        &quot;<strong>Switch to Fixed Frequency Subscription</strong>&quot;, and selecting your preferred delivery
        frequency.
      </>
    ),
  },
  {
    question: 'How do I change my personal details like address and billing details?',
    answer: (
      <>
        Change your personal details in the <strong>Settings</strong> Page by clicking the &quot;<strong>Edit</strong>&quot; buttons.
      </>
    ),
  },
];

export const SubscriptionByUsageFaq = ({ vendor }) => (
  <>
    <Faq elements={elements} />
    <Card body className="mt-3">
      <div className="font-weight-bolder mb-3">Still need help?</div>
      <div className="small">
        <div className="font-weight-bolder mb-3">For product-related issues:</div>
        <div className="mb-3">Contact {vendor?.ordering_email || 'support@bottomless.com'}.</div>
        <div className="font-weight-bolder mb-3">For subscription and scale-related issues:</div>
        <div>Contact support@bottomless.com.</div>
      </div>
    </Card>
  </>
);

SubscriptionByUsageFaq.propTypes = {
  vendor: PropTypes.shape({
    ordering_email: PropTypes.string.isRequired,
  }).isRequired,
};
