import React from 'react';
import PropTypes from 'prop-types';

export const EmbedAccountLink = ({ children }) => (
  <a className="nav-link d-flex align-items-center" href="/account">
    {children}
  </a>
);

EmbedAccountLink.propTypes = {
  children: PropTypes.node,
};
