import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Form, Field, SubmitButton } from '@bottomless/common/components';
import { setPasswordAction } from '../../store/auth/auth.actions';
import { Col, Row } from 'reactstrap';
import { PanelLayoutGuest } from '../../layouts/PanelLayoutGuest';

const Schema = Yup.object().shape({
  password: Yup.string()
    .required('This field is required')
    .min(8)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*-])(?=.{8,})/,
      'Password must contain a lowercase character, an uppercase character, a number, a special character and at least 8 characters.'
    ),
});

export const SetPasswordPageComponent = ({ setPassword }) => {
  const history = useHistory();

  const onSuccess = useCallback(() => {
    history.push('/profile?onboarding=true');
  }, [history]);

  return (
    <PanelLayoutGuest>
      <h1 className="mb-4 text-center">Create your password</h1>
      <Row>
        <Col xs={12} md={{ offset: 3, size: 6 }}>
          <Form
            initialValues={{ password: '' }}
            validationSchema={Schema}
            onSubmit={setPassword}
            onSuccess={onSuccess}
            className="mw-300"
          >
            {({ isSubmitting }) => (
              <>
                <Field name="password" type="password" label="Password" />
                <div className="text-center">
                  <SubmitButton size={null} isSubmitting={isSubmitting}>
                    Save
                  </SubmitButton>
                  <div className="mt-3">
                    <Link to="/profile?onboarding=true">Skip</Link>
                  </div>
                </div>
              </>
            )}
          </Form>
        </Col>
      </Row>
    </PanelLayoutGuest>
  );
};

SetPasswordPageComponent.propTypes = {
  setPassword: PropTypes.func.isRequired,
};

export const SetPasswordPage = connect(null, dispatch => ({
  setPassword: data => dispatch(setPasswordAction(data)),
}))(SetPasswordPageComponent);
