import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import * as Yup from 'yup';
import { Form, SubmitButton, AddressInput, GoogleMapsLoaded, GoogleMapsLoader } from '@bottomless/common/components';

const AddressSchema = Yup.object().shape({
  verifiedAddress: Yup.object()
    .shape({
      street1: Yup.string().required('This field is required'),
      city: Yup.string().required('This field is required'),
      zip: Yup.string().required('This field is required'),
      state: Yup.string().required('This field is required'),
      street2: Yup.string(),
    })
    .required(),
});

export const AddressForm = ({ me, onSubmit, onSuccess }) => {
  const { verifiedAddress } = me || {};
  const form = useRef();

  const handleSuccess = ({ verifiedAddress }, { resetForm }) => {
    resetForm({ verifiedAddress });
    onSuccess(verifiedAddress);
  };

  useEffect(() => {
    if (form.current) {
      form.current.resetForm({ verifiedAddress });
    }
  }, [me, verifiedAddress]);

  return (
    <GoogleMapsLoader>
      <Form
        initialValues={{ verifiedAddress: { state: 'AL', ...(verifiedAddress || {}) } }}
        validationSchema={AddressSchema}
        onSubmit={onSubmit}
        onSuccess={handleSuccess}
        innerRef={form}
      >
        {({ isSubmitting, dirty, values, setFieldValue }) => {
          return (
            <>
              <GoogleMapsLoaded>
                <AddressInput {...{ setFieldValue, values }} />
              </GoogleMapsLoaded>
              <div className="d-flex justify-content-center mt-4 w-25 mx-auto">
                <SubmitButton
                  disabled={!dirty}
                  size="default"
                  block
                  color="primary"
                  outline
                  isSubmitting={isSubmitting}
                >
                  Save
                </SubmitButton>
              </div>
            </>
          );
        }}
      </Form>
    </GoogleMapsLoader>
  );
};

AddressForm.propTypes = {
  me: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};
