import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import * as Yup from 'yup';
import { Form, Field, SubmitButton } from '@bottomless/common/components';
import { useModal } from '../../../hooks/use-modal.hook';
import './TextConfirmationModal.scss';

const Schema = Yup.object().shape({
  code: Yup.number().required('This field is required'),
});

export const TextConfirmationModal = ({ isOpen, toggle, onSubmit, onSuccess }) => {
  const modalProps = useModal();

  const handleSuccess = (_, { resetForm }) => {
    resetForm({ code: '' });
    onSuccess();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} {...modalProps} className="modal-confirm-phone">
      <ModalHeader toggle={toggle}>Confirmation</ModalHeader>
      <ModalBody>
        <div className="text-secondary text-center mb-3">We sent you a confirmation code. Please type it below.</div>
        <Form initialValues={{ code: '' }} validationSchema={Schema} onSubmit={onSubmit} onSuccess={handleSuccess}>
          {({ isSubmitting }) => (
            <>
              <Field type="number" name="code" label="Code" />
              <SubmitButton color="primary" outline size="sm" isSubmitting={isSubmitting}>
                Verify
              </SubmitButton>
            </>
          )}
        </Form>
      </ModalBody>
    </Modal>
  );
};

TextConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};
