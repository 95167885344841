import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

export const StepUndone = ({ setNextStep }) => {
  const onClick = useCallback(() => setNextStep(), [setNextStep]);

  return (
    <>
      <div className="mb-4">All changes are reverted.</div>
      <div className="mw-300">
        <Button color="primary" outline block onClick={onClick}>
          Close
        </Button>
      </div>
    </>
  );
};

StepUndone.headingText = 'Action undone';

StepUndone.propTypes = {
  setNextStep: PropTypes.func.isRequired,
};
