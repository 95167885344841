import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import has from 'lodash-es/has';
import moment from 'moment';
import { DateFormat, Field, Form, SubmitButton } from '@bottomless/common/components';
import { useToggle } from '@bottomless/common/hooks';
import { datesService, utcDate } from '@bottomless/common/utils';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import * as Yup from 'yup';
import { READABLE_FORMAT, getNextFulfillmentDate } from '../../../utils/dates';
import { useModal } from '../../../hooks/use-modal.hook';

const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const OverrideDateSchema = Yup.object().shape({
  override_fulfillment_date: Yup.date().required('This field is required'),
});

export const TriggerNow = ({ triggerOrder, vendor }) => {
  const modalProps = useModal();
  const [isOpen, toggle] = useToggle();
  const [finished, toggleFinished] = useToggle();
  const [chosenDate, setChosenDate] = useState();

  const triggerOrderHandler = useCallback(
    async data => {
      await triggerOrder({
        ...data,
        override_fulfillment_date: has(data, 'override_fulfillment_date')
          ? moment(data.override_fulfillment_date).format('YYYY-MM-DD')
          : null,
      });

      toggleFinished();
    },
    [triggerOrder, toggleFinished]
  );

  const startDate = useMemo(() => {
    const today = new Date();
    const isBefore4AMLocalTime = today.getHours() > 0 && today.getHours() < 4;
    let tomorrow = new Date();
    tomorrow = tomorrow.setDate(tomorrow.getDate() + 1);

    if (isBefore4AMLocalTime) {
      return today;
    }

    return tomorrow;
  }, []);

  const excludedDatesForFulfillment = useMemo(() => {
    const { exclude_fulfilment_dates } = vendor || {};
    return exclude_fulfilment_dates ? exclude_fulfilment_dates.map(date => utcDate(date)) : [];
  }, [vendor]);

  const nextAvailableRoastDate = useMemo(
    () =>
      getNextFulfillmentDate({
        startDate,
        excludedDates: excludedDatesForFulfillment,
      }),
    [startDate, excludedDatesForFulfillment]
  );

  const nextAvailableRoastDateLabel = useMemo(() => moment(nextAvailableRoastDate).format('MM/DD/YYYY'), [
    nextAvailableRoastDate,
  ]);

  const nextAvailableRoastDay = useMemo(() => daysOfWeek[new Date(nextAvailableRoastDate).getDay()], [
    nextAvailableRoastDate,
  ]);

  const minDate = useMemo(() => {
    let min = datesService.getTodayInSeattle();

    if (!vendor?.exclude_fulfilment_dates) {
      return min;
    }

    const excludedDates = vendor.exclude_fulfilment_dates.map(date => utcDate(date).getTime());

    while (excludedDates.includes(min.setHours(0, 0, 0, 0))) {
      min = new Date(min.getTime() + 25 * 3600 * 1000);
    }

    return min;
  }, [vendor]);

  const onTriggerOrder = useCallback(
    ({ override_fulfillment_date, ...formData }) => {
      const data =
        override_fulfillment_date === nextAvailableRoastDateLabel
          ? formData
          : { ...formData, override_fulfillment_date };

      setChosenDate(override_fulfillment_date);

      return triggerOrderHandler(data);
    },
    [nextAvailableRoastDateLabel, triggerOrderHandler]
  );

  return (
    <>
      <Button color="primary" outline block className="button-trigger-now" onClick={toggle}>
        Trigger Manually
      </Button>
      <Modal isOpen={isOpen} toggle={toggle} {...modalProps}>
        <ModalHeader className="text-capitalize" toggle={toggle}>
          {finished ? 'Manual order triggered' : 'Trigger a one-time order manually?'}
        </ModalHeader>
        <ModalBody>
          {!finished && (
            <>
              <div className="mb-4 text-md-center">
                The next available date for your order is {nextAvailableRoastDay},{' '}
                <DateFormat date={nextAvailableRoastDate} format={READABLE_FORMAT} />.
              </div>
              <Form
                initialValues={{ override_fulfillment_date: nextAvailableRoastDateLabel }}
                validationSchema={OverrideDateSchema}
                onSubmit={onTriggerOrder}
                onSuccess={toggle}
                className="mw-300"
              >
                {({ isSubmitting }) => (
                  <>
                    <Field
                      type="date"
                      label="Select Preferred Fulfilment Date"
                      name="override_fulfillment_date"
                      min={minDate}
                      max={new Date(Date.now() + 56 * 86400000)}
                      excludeWeekends={true}
                      excludeDates={
                        vendor?.exclude_fulfilment_dates
                          ? vendor.exclude_fulfilment_dates.map(date => utcDate(date))
                          : []
                      }
                    />
                    <SubmitButton isSubmitting={isSubmitting} size={null} block>
                      Trigger Order
                    </SubmitButton>
                  </>
                )}
              </Form>
            </>
          )}
          {finished && (
            <div>
              Your order will be scheduled for fulfilment on <DateFormat date={chosenDate} correctTimezone />.
            </div>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

TriggerNow.propTypes = {
  triggerOrder: PropTypes.func.isRequired,
  vendor: PropTypes.shape({
    exclude_fulfilment_dates: PropTypes.array,
  }),
};
