import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import qs from 'query-string';
import { Check, Info } from 'react-feather';
import classNames from 'classnames';
import { Form, Field, SubmitButton, Image } from '@bottomless/common/components';
import { sendMagicLinkAction } from '../../store/auth/auth.actions';
import { Col, Row } from 'reactstrap';
import { PanelLayoutGuest } from '../../layouts/PanelLayoutGuest';
import './SendMagicLink.scss';

const Schema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid e-mail address')
    .required('This field is required'),
});

const WildEarthWrapper = ({ children }) => (
  <>
    <Row className="login-wild-earth">
      <Col xs="12" md="6" className="d-flex align-items-center justify-content-center login-wild-earth-left mb-4">
        <div className="mx-0 mx-md-3 login-wild-earth-wrapper">
          <h1 className="mb-3 text-center login-header-long">Subscription By Usage Log-in</h1>
          {children}
        </div>
      </Col>
      <Col xs="12" md="6" className="mb-4 d-none d-md-flex align-items-center">
        <Image className="rounded" src="https://bottomless.imgix.net/shopify-widget/wildearth-login.png" />
      </Col>
    </Row>
  </>
);

WildEarthWrapper.propTypes = {
  children: PropTypes.node,
};

const RegularWrapper = ({ children }) => (
  <>
    <h1 className="mb-3 text-center">Subscriber Login</h1>
    <Row>
      <Col xs={12} md={{ offset: 2, size: 8 }} lg={{ offset: 4, size: 4 }}>
        {children}
      </Col>
    </Row>
  </>
);

RegularWrapper.propTypes = {
  children: PropTypes.node,
};

const SendMagicLinkPageComponent = ({ sendMagicLink, location }) => {
  const query = qs.parse(location.search);
  const [email, setEmail] = useState('');
  const [isLinkSent, setLinkSent] = useState(false);
  const [isLinkAlertPinned, pinLinkAlert] = useState(false);

  const isWildEarth = useMemo(() => window.location.origin.includes('wildearth'), []);

  const Wrapper = useMemo(() => (isWildEarth ? WildEarthWrapper : RegularWrapper), [isWildEarth]);

  const onSuccess = data => {
    setLinkSent(true);
    setEmail(data.email);
  };

  const onSubmit = async values => {
    return sendMagicLink({ ...values, path: query.path, params: query.params });
  };

  return (
    <PanelLayoutGuest>
      <Wrapper>
        <>
          {isLinkSent ? (
            <div className="magic-link-sent d-flex flex-column align-items-center">
              <div className="mb-3 mt-3 p-2 check-wrapper">
                <Check strokeWidth="1.5" color="white" size={30} />
              </div>
              <div className="text-center">
                We&apos;ve sent a one-time login link to <span className="font-weight-bold">{email}</span>. Please click
                on the login link in the email to access your subscription.
              </div>
            </div>
          ) : (
            <>
              <div className="text-center mb-5">
                Enter the email address associated with your subscription to get a one-time login link.
              </div>
              <Form initialValues={{ email: '' }} validationSchema={Schema} onSubmit={onSubmit} onSuccess={onSuccess}>
                {({ isSubmitting }) => (
                  <>
                    <Field name="email" type="email" label="Email" />
                    <div className="text-center">
                      <SubmitButton isSubmitting={isSubmitting} loadingText="Sending" block className="p-2">
                        Send login link
                      </SubmitButton>
                    </div>
                  </>
                )}
              </Form>
            </>
          )}
          <div className="link-alert-container mt-5 d-flex flex-column align-items-center">
            <div
              className={classNames('d-flex justify-content-center', {
                'cursor-pointer': !isLinkAlertPinned,
              })}
              onClick={() => pinLinkAlert(true)}
            >
              <div>Didn&apos;t receive a login link?</div>
              <div className="d-flex align-content-center flex-wrap ml-1">
                <Info size="18" />
              </div>
            </div>
            <small
              className={classNames('mt-1 p-3 link-alert-box text-center', {
                pinned: isLinkAlertPinned,
              })}
            >
              <div>You may not have an active subscription account.</div>
              <div>
                Access your customer account{' '}
                <a className="text-underline" href="/account">
                  here
                </a>
                .
              </div>
            </small>
          </div>
        </>
      </Wrapper>
    </PanelLayoutGuest>
  );
};

SendMagicLinkPageComponent.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  sendMagicLink: PropTypes.func.isRequired,
};

export const SendMagicLinkPage = connect(null, dispatch => ({
  sendMagicLink: data => dispatch(sendMagicLinkAction(data)),
}))(SendMagicLinkPageComponent);
