import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useConditionalDataEffect } from '@bottomless/common/hooks';
import { DataHandler } from '@bottomless/common/components';
import { useDispatch, useSelector } from 'react-redux';
import { ManifestContextProvider } from '../../context/manifest.context';
import { getMeAction } from '../../store/user';

const UserCategoryManifestComponent = ({
  manifestKey = '',
  defaultKey = '',
  components = {},
  noLoading,
  onlySubscribe,
  fallbackComponent,
  ...props
}) => {
  const dispatch = useDispatch();
  const getMe = useCallback(() => dispatch(getMeAction()), [dispatch]);

  const { me, isLoading, isAuth } = useSelector(({ user, auth }) => ({
    me: user.me,
    isLoading: user.isLoading,
    isAuth: auth?.isAuth,
  }));

  const manifest = useMemo(() => me?.product?.product?.category?.manifest, [me]);
  const Component = useMemo(
    () => components[(manifest || { components: {} }).components[manifestKey]] || fallbackComponent,
    [manifest, manifestKey, components, fallbackComponent]
  );

  const { error } = useConditionalDataEffect(
    !onlySubscribe && !manifest && !isLoading && !isAuth,
    getMe,
    undefined,
    undefined,
    null
  );

  if (defaultKey && (error || (isAuth && !manifest))) {
    const DefaultComponent = components[defaultKey];
    return <DefaultComponent {...props} />;
  }

  if (!manifest && noLoading) {
    return null;
  }

  if (!manifest) {
    return <DataHandler data={me} isLoading={isLoading} error={error} />;
  }

  if (!Component) {
    return null;
  }

  return (
    <ManifestContextProvider value={{ manifest }}>
      <Component {...props} />
    </ManifestContextProvider>
  );
};

UserCategoryManifestComponent.propTypes = {
  manifestKey: PropTypes.string.isRequired,
  defaultKey: PropTypes.string,
  components: PropTypes.object.isRequired,
  noLoading: PropTypes.bool,
  onlySubscribe: PropTypes.bool,
  fallbackComponent: PropTypes.element.isRequired,
};

export const UserCategoryManifest = React.memo(UserCategoryManifestComponent);
