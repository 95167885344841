import React from 'react';
import PropTypes from 'prop-types';
import { useToggle } from '@bottomless/common/hooks';
import { useModal } from '../../../hooks/use-modal.hook';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { SubscriptionByUsageFaq, SubscriptionFixedFaq } from '../../../components/Faq';

export const FaqModal = ({ me }) => {
  const modalProps = useModal();
  const [isOpen, toggle] = useToggle();

  return (
    <>
      <div className="text-center">
        <Button color="primary" outline className="text-left" onClick={toggle}>
          Get Help
        </Button>
      </div>
      <Modal isOpen={isOpen} size="lg" toggle={toggle} {...modalProps}>
        <ModalHeader toggle={toggle}>Get Help</ModalHeader>
        <ModalBody>
          {!!me.dumb_period && <SubscriptionFixedFaq vendor={me.vendor_id} />}
          {!me.dumb_period && <SubscriptionByUsageFaq vendor={me.vendor_id} />}
        </ModalBody>
      </Modal>
    </>
  );
};

FaqModal.propTypes = {
  me: PropTypes.shape({
    dumb_period: PropTypes.number,
    vendor_id: PropTypes.object.isRequired,
  }),
};
