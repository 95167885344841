import { useSelector } from 'react-redux';

export const DAYS_IN_WEEK = 7;
const DEFAULT_OPTIONS = [1, 2, 3];
const OTHER_OPTIONS = [1, 2, 3, 4, 6, 8];

export const useProductFrequency = (product, isSimpleSubscriber = false) => {
  if (!isSimpleSubscriber || !product?.availableDumbPeriods?.length) {
    return { options: DEFAULT_OPTIONS, availableOtherOptions: OTHER_OPTIONS };
  }

  const allProductOptions = product.availableDumbPeriods.map(row => row / DAYS_IN_WEEK);
  const hideOthers = allProductOptions.length <= 4;
  const options = hideOthers ? allProductOptions : allProductOptions.slice(0, 2);

  return { options, availableOtherOptions: allProductOptions, hideOthers };
};

export const useSubscriptionFrequency = () => {
  const { me } = useSelector(({ user }) => ({ me: user.me }));

  return useProductFrequency(me?.product?.product, !!me.shopifySubscriptionContractId);
};
