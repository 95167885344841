import React, { useMemo } from 'react';
import { isNumber } from 'lodash-es';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import { Box, Price, getDiscountedPrice } from '@bottomless/common/components';
import { OrderSources, OrderStatuses, SubscriptionType } from '@bottomless/common/constants';
import { PanelSection } from '../../../components/PanelSection';

const CALCULATED_WHEN_PROCESSED = 'Calculated when processed';

export const AmountsPaid = ({ order, user, lineItems, product, pricingRule, discountCode, isQueueProduct }) => {
  const orderIsPaid = useMemo(() => isNumber(order.amount_paid), [order]);
  const isPrepaidSubscriber = useMemo(() => user.subscriptionType === SubscriptionType.Prepaid, [user]);
  const isOneOff = useMemo(() => order.source === OrderSources.USER_ONE_OFF, [order]);
  const useUserProduct = useMemo(() => !isOneOff && !isQueueProduct, [isOneOff, isQueueProduct]);

  const productVariant = useMemo(() => product.product.variants.find(v => v._id === product.variant), [product]);
  const userVariant = useMemo(() => user.product.product.variants.find(v => v._id === user.product.variant), [user]);
  const address = useMemo(() => (order.verifiedAddress?.street1 ? order.verifiedAddress : user.verifiedAddress), [
    order,
    user,
  ]);

  const productCost = useMemo(() => {
    if (orderIsPaid) {
      return order.amount_paid;
    }

    const dynamicPrice = useUserProduct && user.product?.dynamicPricing?.price;
    const variant = useUserProduct && user.product.product.custom_rotation ? userVariant : productVariant;
    const variantPrice = (order.quantity || 1) * (dynamicPrice || variant.website_pricing || variant.price) * 100;

    const lineItemsPrice = lineItems.reduce((sum, lineItem) => {
      const variant = lineItem.product.variants.find(v => v._id === lineItem.variant);
      const variantPrice = (lineItem.quantity || 1) * (variant.website_pricing || variant.price) * 100;

      return sum + variantPrice;
    }, 0);

    return variantPrice + lineItemsPrice;
  }, [orderIsPaid, order, productVariant, lineItems, user, userVariant, useUserProduct]);

  const shipmentPrice = useMemo(() => {
    if (orderIsPaid) {
      return order.shipment_paid;
    }

    if (user.deliveryPrice !== null && user.deliveryPrice !== undefined && user.deliveryPrice >= 0) {
      return user.deliveryPrice * 100;
    }

    if (productVariant.skipShipment || address?.pickup) {
      return 0;
    }

    if (order.first_order && !isNaN(pricingRule.first_shipping_price)) {
      return pricingRule.first_shipping_price;
    }

    const variantDiscountedPrice = getDiscountedPrice(pricingRule, productCost, order.first_order, productVariant.size);

    if (!isNaN(pricingRule.free_shipping_threshold) && variantDiscountedPrice >= pricingRule.free_shipping_threshold) {
      return 0;
    }

    if (pricingRule.free_shipping) {
      return 0;
    }

    if (pricingRule.shipping_price) {
      return pricingRule.shipping_price;
    }

    return CALCULATED_WHEN_PROCESSED;
  }, [orderIsPaid, order, productVariant, pricingRule, productCost, address, user]);

  const total = useMemo(() => {
    if (orderIsPaid) {
      return order.amount_paid + (order.shipment_paid || 0) + (order.tax_paid || 0);
    }

    return (
      getDiscountedPrice(pricingRule, productCost, order.first_order, productVariant.size) +
      (isNumber(shipmentPrice) ? shipmentPrice : 0)
    );
  }, [orderIsPaid, order, productCost, shipmentPrice, pricingRule, productVariant]);

  const shippingPriceLabel = useMemo(() => {
    if (isNumber(shipmentPrice)) {
      return <Price value={shipmentPrice} cents />;
    }

    return shipmentPrice;
  }, [shipmentPrice]);

  const taxPriceLabel = useMemo(() => {
    if (isNumber(order.tax_paid) || isPrepaidSubscriber) {
      return <Price value={order.tax_paid} cents />;
    }

    return CALCULATED_WHEN_PROCESSED;
  }, [order, isPrepaidSubscriber]);

  const shippingAndTaxCalculatedWhenProcessed = useMemo(
    () => shippingPriceLabel === CALCULATED_WHEN_PROCESSED && taxPriceLabel === CALCULATED_WHEN_PROCESSED,
    [shippingPriceLabel, taxPriceLabel]
  );

  if (order.status === OrderStatuses.Cancelled || isPrepaidSubscriber) {
    return null;
  }

  return (
    <PanelSection title="Payment Details">
      <Box className="small">
        <div className="flex-grow-1">
          <div className="d-flex">
            <div className="mr-1 text-secondary">Product Cost:</div>
            {!orderIsPaid && (
              <Price value={productCost} cents pricingRule={pricingRule} isFirstBag={order.first_order} />
            )}
            {orderIsPaid && (
              <>
                {order.gross_price > order.amount_paid && (
                  <span className="text-secondary text-strikethrough mr-1">
                    <Price value={order.gross_price} cents isFirstBag={order.first_order} />
                  </span>
                )}
                <Price value={order.amount_paid} cents isFirstBag={order.first_order} />
              </>
            )}
          </div>
          {shippingAndTaxCalculatedWhenProcessed && (
            <>
              <div className="d-flex">
                <div className="mr-1 text-secondary">Shipment and Tax:</div>
                {CALCULATED_WHEN_PROCESSED}
              </div>
            </>
          )}
          {!shippingAndTaxCalculatedWhenProcessed && (
            <>
              <div className="d-flex">
                <div className="mr-1 text-secondary">Shipment:</div>
                {shippingPriceLabel}
              </div>
              <div className="d-flex">
                <div className="mr-1 text-secondary">Tax:</div>
                {taxPriceLabel}
              </div>
            </>
          )}
          {discountCode && (
            <div className="d-flex align-items-center discount-code">
              <div className="mr-1 text-secondary">Discount Code:</div>
              <Badge color="primary">{discountCode.title}</Badge>
            </div>
          )}
          <hr />
          <div className="d-flex">
            <div className="mr-1">
              <strong>{orderIsPaid ? 'Total' : 'Subtotal'}:</strong>
            </div>
            <strong>
              <Price value={total} cents />
            </strong>
          </div>
        </div>
      </Box>
    </PanelSection>
  );
};

AmountsPaid.propTypes = {
  order: PropTypes.shape({
    amount_paid: PropTypes.number,
    shipment_paid: PropTypes.number,
    tax_paid: PropTypes.number,
    gross_price: PropTypes.number,
    quantity: PropTypes.number,
    first_order: PropTypes.bool,
    verifiedAddress: PropTypes.object,
    status: PropTypes.string,
    source: PropTypes.string,
  }).isRequired,
  className: PropTypes.string,
  lineItems: PropTypes.arrayOf(
    PropTypes.shape({
      product: PropTypes.shape({
        variants: PropTypes.array,
      }).isRequired,
      variant: PropTypes.string,
    })
  ).isRequired,
  product: PropTypes.shape({
    product: PropTypes.shape({
      variants: PropTypes.array,
    }).isRequired,
    variant: PropTypes.string,
  }).isRequired,
  pricingRule: PropTypes.object.isRequired,
  user: PropTypes.shape({
    deliveryPrice: PropTypes.number,
    verifiedAddress: PropTypes.object,
    subscriptionType: PropTypes.string,
    product: PropTypes.shape({
      product: PropTypes.shape({
        custom_rotation: PropTypes.string,
        variants: PropTypes.arrayOf(PropTypes.shape({ _id: PropTypes.string.isRequired })).isRequired,
      }).isRequired,
      variant: PropTypes.string.isRequired,
      dynamicPricing: PropTypes.shape({
        price: PropTypes.string,
      }),
    }).isRequired,
  }).isRequired,
  discountCode: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }),
  isQueueProduct: PropTypes.bool,
};
