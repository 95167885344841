import { DataHandler, DataHandlerContent } from '@bottomless/common/components';
import React from 'react';
import { PanelLayout } from '../../layouts/PanelLayout';
import { combineStates } from '../../utils/combine-states';
import { PanelSection } from '../../components/PanelSection';
import { Heading } from '../../components/Heading';
import { useLastOrderDate } from '../../hooks/use-last-order-date';
import { useCredits, useInTransition, useMe, useOrders, useQueue, useUpcomingOrder } from './hooks';
import { Orders, UpcomingOrders } from './components';
import { DataHandlerEmptyState } from '@bottomless/common/src/components/DataHandler/DataHandlerEmptyState';
import { OrderEmptyState } from './components/OrderEmptyState';
import './OrdersPage.scss';

export const OrdersPage = () => {
  const orders = useOrders();
  const inTransition = useInTransition();
  const upcomingOrders = useUpcomingOrder();
  const credits = useCredits();
  const queue = useQueue();
  const me = useMe();

  const { getNextOrderDate } = useLastOrderDate({
    lastOrder: { data: orders.lastSubscriptionOrder },
    upcomingOrders,
    user: me?.data,
  });

  return (
    <PanelLayout>
      <div className="page page-orders">
        <Heading>Orders</Heading>
        <DataHandler {...combineStates(me, inTransition, upcomingOrders)}>
          <></>
        </DataHandler>
        <DataHandlerContent
          {...combineStates(me, orders, inTransition, upcomingOrders)}
          data={me && [...(orders.data || []), ...(inTransition.data || [])]}
        >
          <UpcomingOrders
            user={me.data}
            orders={inTransition.data}
            vendor={upcomingOrders.data?.vendor}
            triggerOrder={upcomingOrders.triggerOrder}
            getNextOrderDate={getNextOrderDate}
            credits={credits.data}
            queue={queue.data || []}
          />
        </DataHandlerContent>
        <DataHandlerEmptyState
          {...combineStates(me, orders, inTransition, upcomingOrders)}
          data={me && [...(orders.data || []), ...(inTransition.data || [])]}
        >
          <OrderEmptyState
            user={me.data}
            orders={inTransition.data}
            vendor={upcomingOrders.data?.vendor}
            triggerOrder={upcomingOrders.triggerOrder}
            getNextOrderDate={getNextOrderDate}
            credits={credits.data}
            queue={queue.data || []}
          />
        </DataHandlerEmptyState>
        <DataHandlerContent {...orders}>
          <PanelSection title="Past Orders">
            <Orders orders={orders.data} />
          </PanelSection>
        </DataHandlerContent>
        <DataHandler
          {...combineStates(me, orders, inTransition, upcomingOrders)}
          data={me && [...(orders.data || []), ...(inTransition.data || [])]}
        >
          <PanelSection title="Past Orders">You haven&apos;t had any orders yet.</PanelSection>
        </DataHandler>
      </div>
    </PanelLayout>
  );
};
