import React, { useEffect } from 'react';
import { Button } from 'reactstrap';
import { useQueryString } from '@bottomless/common/hooks';
import { PanelLayoutGuest } from '../../layouts/PanelLayoutGuest';
import { useFacebookPixel } from '../../hooks/use-facebook-pixel';
import { useCookies } from 'react-cookie';

export const CheckoutCompletedPage = () => {
  const [, , removeCookie] = useCookies();
  const facebookPixel = useFacebookPixel();

  const { params: queryParams, update } = useQueryString();

  useEffect(() => {
    removeCookie('newCheckout', { path: '/' });
  }, [removeCookie]);

  useEffect(() => {
    if (queryParams.success) {
      facebookPixel.purchase({
        ...queryParams,
        value: queryParams.value ? queryParams.value / 100 : queryParams.value,
      });
      update({ success: undefined, productId: undefined, value: undefined, name: undefined });
    }
  }, [queryParams, update, facebookPixel]);

  return (
    <PanelLayoutGuest>
      <h1 className="mb-3 text-center">Purchase Successful!</h1>
      <div className="text-center mb-5">
        We have sent you an email with a confirmation button.
        <br /> Please click on the button and verify your email to complete the sign-up process.
      </div>
      <div className="text-center">
        <a href="/" title="Go back to the store">
          <Button color="primary">Go back to the store</Button>
        </a>
      </div>
    </PanelLayoutGuest>
  );
};

CheckoutCompletedPage.propTypes = {};
