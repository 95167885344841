import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getInTransitionCountAction } from '../../store/order';
import { useConditionalDataEffect, useDataEffect } from '@bottomless/common/hooks';
import { useMemo } from 'react';
import { useMe } from './use-me.hook';
import { SubscriptionType, UserStatuses } from '@bottomless/common/constants';
import { getCreditsAction } from '../../store/user';

export const useOrderNotification = () => {
  const dispatch = useDispatch();
  const { me } = useMe();

  const isInactiveUser = useMemo(() => me.pausedUntil || me.hibernate || me.status === UserStatuses.Closed, [me]);

  const { data: credits } = useSelector(({ user }) => ({ data: user.credits?.total ? user.credits : null }));

  const creditsAction = useCallback(() => dispatch(getCreditsAction()), [dispatch]);
  useConditionalDataEffect(me?.subscriptionType === SubscriptionType.Prepaid, creditsAction);

  const action = useCallback(() => dispatch(getInTransitionCountAction()), [dispatch]);
  const { data: rawData } = useSelector(({ order }) => ({ data: order.count || {} }));

  const data = useMemo(() => {
    const count = rawData?.sum || 0;

    if (isInactiveUser) {
      return count;
    }

    if (!count && me.subscriptionType === SubscriptionType.Prepaid) {
      return credits?.granted ? 1 : 0;
    }

    if (!count && me.subscriptionType === SubscriptionType.Fixed) {
      return 1;
    }

    return count;
  }, [rawData, credits, me, isInactiveUser]);

  const { error, isFetching: isLoading } = useDataEffect(action);

  return { data, error, isLoading };
};
