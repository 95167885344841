import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Nav, NavItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { Home, Settings, ShoppingBag } from 'react-feather';
import { DataHandlerContent, UserAvatar } from '@bottomless/common/components';
import { useMe } from './hooks/use-me.hook';
import './PanelLayout.scss';
import { useOrderNotification } from './hooks/use-order-notification.hook';
import { useConfigValue } from '../hooks/use-config-value.hook';
import { AccountLink, EmbedAccountLink } from './components';

const ROUTES = {
  '/': { name: 'Subscription', exact: true, Icon: props => <Home {...props} /> },
  '/orders': { name: 'Orders', Icon: props => <ShoppingBag {...props} />, notification: true },
  '/account': { name: 'Settings', Icon: props => <Settings {...props} /> },
};

export const PanelLayout = ({ children }) => {
  const { me } = useMe();
  const orderNotification = useOrderNotification();
  const isLoggedShopifyCustomer = useMemo(() => !!window.BottomlessAccount?.customer?.id, []);
  const useEmbedAccountPicker = useConfigValue('useEmbedAccountPicker');

  const ProperAccountLink = useMemo(
    () => (useEmbedAccountPicker && isLoggedShopifyCustomer ? EmbedAccountLink : AccountLink),
    [useEmbedAccountPicker, isLoggedShopifyCustomer]
  );

  return (
    <div className="layout-panel d-flex">
      <div className="layout-navigation">
        <Nav className="flex-lg-column main-navigation">
          {Object.entries(ROUTES).map(([to, route], i, allRoutes) => (
            <NavItem key={to} className={classNames({ 'mb-0 mb-lg-3': !!allRoutes[i + 1] })}>
              <NavLink className="nav-link d-flex align-items-center position-relative" to={to} exact={route.exact}>
                {route.notification && (
                  <DataHandlerContent {...orderNotification}>
                    <span className="main-navigation-notification position-absolute d-flex align-items-center justify-content-center">
                      {orderNotification.data}
                    </span>
                  </DataHandlerContent>
                )}
                <route.Icon width="24" height="24" className="mr-lg-2 mb-2 mb-lg-0" /> {route.name}
              </NavLink>
            </NavItem>
          ))}
          <NavItem className="d-block d-lg-none">
            <ProperAccountLink useEmbedAccountPicker={useEmbedAccountPicker}>
              <div className="mb-2 main-navigation-account d-flex align-items-center justify-content-center">
                <UserAvatar user={me} width="22" height="22" />
              </div>{' '}
              Home
            </ProperAccountLink>
          </NavItem>
        </Nav>
        <div className="account-dropdown-wrapper d-none d-lg-block">
          <hr className="border-top my-4" />
          <ProperAccountLink useEmbedAccountPicker={useEmbedAccountPicker}>
            <div className="account-dropdown-image d-flex align-items-center justify-content-center border mr-2 bg-white">
              <UserAvatar user={me} width="22" height="22" />
            </div>{' '}
            Home
          </ProperAccountLink>
        </div>
      </div>
      <div className="layout-content">{children}</div>
      <div className="nav-empty" />
    </div>
  );
};

PanelLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
