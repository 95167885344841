import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Image, Price, ProductName, VariantPrice } from '@bottomless/common/components';
import { isNumber } from 'lodash-es';
import { OrderSources, SubscriptionType } from '@bottomless/common/constants';

export const Product = ({
  order,
  user,
  pricingRule,
  product,
  isSimpleSubscription,
  isMainProduct,
  overrideGrind,
  isQueueProduct,
}) => {
  const orderIsPaid = useMemo(() => isNumber(order.amount_paid), [order]);

  const grind = useMemo(() => overrideGrind || order.grind || user?.grind, [overrideGrind, order, user]);
  const variant = useMemo(() => product.product.variants.find(v => v._id === product.variant), [product]);
  const userVariant = useMemo(() => user.product.product.variants.find(v => v._id === user.product.variant), [user]);
  const isOneOff = useMemo(() => order.source === OrderSources.USER_ONE_OFF, [order]);
  const isShopifyRotation = useMemo(() => !!user.product.product.custom_rotation, [user]);
  const useUserProduct = useMemo(() => !isOneOff && !isQueueProduct, [isOneOff, isQueueProduct]);

  const amountPaid = useMemo(() => {
    if (!isMainProduct) {
      return order.amount_paid;
    }

    const amountPaidOfLineItems = (order.lineItems || []).reduce((sum, lineItem) => sum + lineItem.amount_paid, 0);

    return order.amount_paid - amountPaidOfLineItems;
  }, [isMainProduct, order]);

  const grossPrice = useMemo(() => {
    if (!isMainProduct) {
      return order.gross_price;
    }

    const grossPriceOfLineItems = (order.lineItems || []).reduce((sum, lineItem) => sum + lineItem.gross_price, 0);

    return order.gross_price - grossPriceOfLineItems;
  }, [isMainProduct, order]);

  const isPrepaidSubscriber = useMemo(() => user.subscriptionType === SubscriptionType.Prepaid, [user]);

  const dynamicPrice = useMemo(() => user.product?.dynamicPricing?.price, [user]);

  return (
    <div className="d-flex align-items-center justify-content-between">
      <div className="d-flex align-items-center">
        <Image
          className="product-image img-fluid mr-3"
          src={product.product.small_image_src}
          alt={product.product.name}
          height="65"
          width="65"
          fit="clip"
        />
        <div>
          <ProductName
            product={product}
            personalized={user?.personalized}
            productVariant={product}
            grind={grind?.name}
          />
          {!isPrepaidSubscriber && (
            <div className="small text-secondary">
              {!orderIsPaid &&
                (useUserProduct && dynamicPrice ? (
                  <Price value={dynamicPrice} pricingRule={pricingRule} />
                ) : (
                  <VariantPrice
                    user={user}
                    variant={useUserProduct && isMainProduct && isShopifyRotation ? userVariant : variant}
                    pricingRule={pricingRule}
                  />
                ))}
              {orderIsPaid && (
                <>
                  {grossPrice > amountPaid && (
                    <span className="text-secondary text-strikethrough mr-1">
                      <Price value={grossPrice / (order.quantity || 1)} cents />
                    </span>
                  )}
                  <Price value={amountPaid / (order.quantity || 1)} cents />
                </>
              )}
            </div>
          )}
        </div>
      </div>
      {(isSimpleSubscription || order.quantity > 1) && <div>x{order.quantity || 1}</div>}
    </div>
  );
};

Product.propTypes = {
  order: PropTypes.shape({
    grind: PropTypes.shape({ name: PropTypes.string.isRequired }),
    quantity: PropTypes.number,
    amount_paid: PropTypes.number,
    gross_price: PropTypes.number,
    lineItems: PropTypes.array,
    source: PropTypes.string,
  }).isRequired,
  user: PropTypes.shape({
    grind: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    personalized: PropTypes.object,
    subscriptionType: PropTypes.object,
    dynamicPricing: PropTypes.shape({
      price: PropTypes.string,
    }),
    product: PropTypes.shape({
      dynamicPricing: PropTypes.shape({
        price: PropTypes.string,
      }),
      product: PropTypes.shape({
        custom_rotation: PropTypes.string,
        variants: PropTypes.arrayOf(PropTypes.shape({ _id: PropTypes.string.isRequired })).isRequired,
      }).isRequired,
      variant: PropTypes.string.isRequired,
    }),
  }),
  product: PropTypes.shape({
    product: PropTypes.shape({
      name: PropTypes.string.isRequired,
      vendor_name: PropTypes.string.isRequired,
      small_image_src: PropTypes.string.isRequired,
      variants: PropTypes.arrayOf(
        PropTypes.shape({
          size: PropTypes.number,
        })
      ).isRequired,
    }).isRequired,
    variant: PropTypes.string.isRequired,
  }).isRequired,
  isSimpleSubscription: PropTypes.bool,
  pricingRule: PropTypes.object,
  isMainProduct: PropTypes.bool,
  overrideGrind: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  isQueueProduct: PropTypes.bool,
};
