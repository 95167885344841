import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { useCallback } from 'react';

export const StepSubscriptionUpdatedSbu = ({ setNextStep }) => {
  const onClick = useCallback(() => setNextStep(), [setNextStep]);

  return (
    <>
      <div className="mb-4">
        Your subscription has been updated to Subscription by Usage. You can expect a shipment of smart re-ordering
        scale soon.
      </div>
      <div className="mw-300">
        <Button color="primary" outline block onClick={onClick}>
          Close
        </Button>
      </div>
    </>
  );
};

StepSubscriptionUpdatedSbu.headingText = 'Your subscription has been updated';

StepSubscriptionUpdatedSbu.propTypes = {
  setNextStep: PropTypes.func.isRequired,
};
