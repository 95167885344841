import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { DateFormat, Form, SubmitButton } from '@bottomless/common/components';
import { FrequencyInput } from '../../../../components/FrequencyInput';
import { READABLE_FORMAT } from '../../../../utils/dates';
import { Button } from 'reactstrap';
import moment from 'moment';

const Schema = Yup.object().shape({
  dumb_period: Yup.number().required('This field is required'),
});

export const Step1 = ({ setDumbPeriod, onSuccess, getNextOrderDateFromNow, toggle }) => {
  const onSetDumbPeriod = useCallback(
    data =>
      setDumbPeriod({
        ...data,
        triggerOrder: true,
        override_fulfillment_date: moment(getNextOrderDateFromNow(data.dumb_period)).format('YYYY-MM-DD'),
      }),
    [setDumbPeriod, getNextOrderDateFromNow]
  );

  return (
    <>
      <div className="mb-4 text-md-center">
        A fixed frequency subscription means your orders will be sent at fixed intervals. There is a risk of oversupply
        or undersupply.
      </div>
      <Form
        initialValues={{ dumb_period: 14 }}
        validationSchema={Schema}
        onSubmit={onSetDumbPeriod}
        onSuccess={onSuccess}
        className="mw-300 form-fixed-period"
      >
        {({ isSubmitting, setFieldValue, setFieldTouched, values }) => (
          <>
            <FrequencyInput
              name="dumb_period"
              value={values.dumb_period}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
            />
            <div className="d-flex justify-content-between mt-3 mb-4">
              <div className="font-weight-bolder order-heading">Scheduled fulfillment</div>
              <div>
                <DateFormat date={getNextOrderDateFromNow(values.dumb_period)} format={READABLE_FORMAT} />
              </div>
            </div>
            <div className="mb-4">
              <SubmitButton block size="default" isSubmitting={isSubmitting}>
                Switch to Fixed Frequency
              </SubmitButton>
            </div>
            <Button block color="link" onClick={toggle}>
              Stay on Subscribe By Usage
            </Button>
          </>
        )}
      </Form>
    </>
  );
};

Step1.headingText = 'Switch to fixed frequency subscription?';

Step1.propTypes = {
  setDumbPeriod: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  getNextOrderDateFromNow: PropTypes.func.isRequired,
};
