import React from 'react';
import PropTypes from 'prop-types';
import { Orders } from './Orders';
import { PanelSection } from '../../../components/PanelSection';
import { OrderEmptyState } from './OrderEmptyState';

export const UpcomingOrders = ({ orders, user, vendor, triggerOrder, getNextOrderDate, credits, queue }) => {
  if (orders.length > 0) {
    return (
      <PanelSection title="Upcoming Orders">
        <Orders user={user} orders={orders} />
      </PanelSection>
    );
  }

  return (
    <OrderEmptyState
      user={user}
      orders={orders}
      vendor={vendor}
      triggerOrder={triggerOrder}
      getNextOrderDate={getNextOrderDate}
      credits={credits}
      queue={queue}
    />
  );
};

UpcomingOrders.propTypes = {
  orders: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  vendor: PropTypes.object.isRequired,
  triggerOrder: PropTypes.func.isRequired,
  getNextOrderDate: PropTypes.func.isRequired,
  credits: PropTypes.shape({
    granted: PropTypes.number,
  }),
  queue: PropTypes.array.isRequired,
};
