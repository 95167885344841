import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import moment from 'moment';
import { SubmitButton, Form, Field, DateFormat } from '@bottomless/common/components';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useToggle } from '@bottomless/common/hooks';
import { useModal } from '../../../hooks/use-modal.hook';
import { useIsSimpleSubscription } from '../../../hooks/use-is-simple-subscribtion.hook';
import { utcDate } from '@bottomless/common/utils';

const Schema = Yup.object().shape({
  pausedUntil: Yup.date().required('This field is required'),
});

export const PauseOrdering = ({ me, onSubmit }) => {
  const isSimpleSubscription = useIsSimpleSubscription();
  const userCouldHaveScale = useMemo(() => !isSimpleSubscription || me.base_id, [isSimpleSubscription, me]);

  const handleSubmit = useCallback(
    data => {
      const pausedUntil = data.pausedUntil ? moment(data.pausedUntil).format('YYYY-MM-DD') : null;

      return onSubmit({ ...data, pausedUntil });
    },
    [onSubmit]
  );

  const modalProps = useModal();
  const [isOpen, rawToggle] = useToggle();
  const [finished, toggleFinished, setFinished] = useToggle();

  const { now, nextYear } = useMemo(() => {
    const now = new Date();

    const nextYear = new Date();
    nextYear.setMonth(nextYear.getMonth() + 12);

    return { now, nextYear };
  }, []);

  const toggle = useCallback(() => {
    rawToggle();

    setTimeout(() => {
      setFinished(false);
    }, [300]);
  }, [rawToggle, setFinished]);

  return (
    <>
      {!me.paused && (
        <Button color="primary" outline onClick={toggle}>
          Pause Automatic Re-ordering
        </Button>
      )}
      <Modal isOpen={isOpen} size="md" toggle={toggle} {...modalProps}>
        <ModalHeader toggle={toggle} className="text-capitalize">
          {finished ? 'Automatic ordering paused' : 'Pause automatic ordering?'}
        </ModalHeader>
        <ModalBody>
          {!finished && (
            <>
              <div className="mb-4 text-md-center">Orders in progress will be processed as scheduled.</div>
              <Form
                initialValues={{ paused: true, pausedUntil: me.pausedUntil ? utcDate(me.pausedUntil) : null }}
                validationSchema={Schema}
                onSubmit={handleSubmit}
                onSuccess={toggleFinished}
                className="mw-300"
              >
                {({ isSubmitting }) => (
                  <>
                    <Field
                      name="pausedUntil"
                      type="date"
                      label="Pause automatic re-ordering until"
                      min={now}
                      max={nextYear}
                    />
                    <div className="mb-4">
                      <SubmitButton block size="default" isSubmitting={isSubmitting}>
                        Pause Automatic Re-ordering
                      </SubmitButton>
                    </div>
                  </>
                )}
              </Form>
              {userCouldHaveScale && (
                <div className="text-md-center small">
                  <div>Have an issue with your subscription or scale?</div>
                  <a href="mailto:support@bottomless.com" title="Support">
                    Contact Support
                  </a>
                </div>
              )}
            </>
          )}
          {finished && (
            <div className="text-md-center">
              <p>
                Automatic re-ordering paused until <DateFormat date={me.pausedUntil} fromZeroed />.
              </p>
              <p>Orders in progress will be processed as scheduled.</p>
            </div>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

PauseOrdering.propTypes = {
  me: PropTypes.shape({
    paused: PropTypes.bool,
    pausedUntil: PropTypes.string,
    base_id: PropTypes.string,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
};
