import React from 'react';
import PropTypes from 'prop-types';
import { BackButton } from '../BackButton/BackButton';

export const Heading = ({ back, children }) => (
  <div className="mb-3">
    <h2 className="mb-0">{children}</h2>
    {back && <BackButton to={back} />}
  </div>
);

Heading.propTypes = {
  back: PropTypes.string,
  children: PropTypes.node.isRequired,
};
