import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import * as Yup from 'yup';
import { Form, Field, SubmitButton } from '@bottomless/common/components';
import { useModal } from '../../../hooks/use-modal.hook';

const Schema = Yup.object().shape({
  reason: Yup.string().required('This field is required'),
});

export const RequestScaleAssistanceModal = ({ isOpen, toggle, reasonOptions, onSubmit }) => {
  const modalProps = useModal();

  return (
    <Modal isOpen={isOpen} toggle={toggle} {...modalProps}>
      <ModalHeader toggle={toggle}>Request Scale Assistance</ModalHeader>
      <ModalBody>
        <Form
          initialValues={{ comment: undefined, reason: undefined, needs_support: true }}
          validationSchema={Schema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => (
            <>
              <Field name="needs_support" hidden />
              <Field name="reason" type="select" label="Select Reason" options={reasonOptions} required />
              <Field type="textarea" name="comment" label="Add a Comment" />
              <div className="text-center">
                <SubmitButton color="primary" isSubmitting={isSubmitting}>
                  Request
                </SubmitButton>
              </div>
            </>
          )}
        </Form>
      </ModalBody>
    </Modal>
  );
};

RequestScaleAssistanceModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  reasonOptions: PropTypes.object.isRequired,
};
