import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import BubbleChart from './BubbleChart';
import { isOrder } from './filters';
import * as d3 from 'd3';

export const OriginGraph = ({ data }) => {
  const ref = useRef();

  useEffect(() => {
    const dimension = data.dimension(item => item.origin || 'Unknown');
    const group = dimension.group().reduceSum(item => Number(isOrder(item)));

    const bubbleChart = BubbleChart(group.all(), {
      label: d => d.key,
      value: d => d.value,
      group: d => d.key,
      title: d => d.key,
      width: 300,
      colors: d3.schemeTableau10,
    });

    if (ref && ref.current) {
      ref.current.innerHTML = '';
      ref.current.append(bubbleChart);
    }
  }, [ref, data]);

  return (
    <div className="data-origin text-center">
      <div className="mb-2">Coffee Origin</div>
      <div ref={ref}></div>
    </div>
  );
};

OriginGraph.propTypes = {
  data: PropTypes.object.isRequired,
};
