import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDataEffect } from '@bottomless/common/hooks';
import {
  getInTransitionOrdersAction,
  getUpcomingOrderAction,
  triggerOrderAction,
} from '../../../store/order/order.actions';

export const useUpcomingOrder = () => {
  const dispatch = useDispatch();
  const action = useCallback(() => dispatch(getUpcomingOrderAction()), [dispatch]);
  const { data } = useSelector(({ order }) => ({ data: order.upcomingOrders }));

  const triggerOrder = useCallback(
    async data => {
      const result = await dispatch(triggerOrderAction(data));

      await Promise.all([dispatch(getUpcomingOrderAction()), dispatch(getInTransitionOrdersAction())]);

      return result;
    },
    [dispatch]
  );

  const { error, isFetching: isLoading } = useDataEffect(action);

  return { data, isLoading, error, triggerOrder };
};
