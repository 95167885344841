import React from 'react';
import PropTypes from 'prop-types';
import { Form, Image, SubmitButton } from '@bottomless/common/components';
import { Button } from 'reactstrap';

export const ScaleReturn = ({ requestScaleReturn, onSuccess, children, toggle }) => {
  return (
    <>
      <h5 className="text-center">What about my scale?</h5>

      <div className="text-center">
        <Image src="shopify-public/scale-return.png" alt="Scale" height="140" useSrcset />
      </div>

      <p>{children}</p>
      <p>Alternatively, you could request for a shipping label to send the scale to us.</p>

      <div className="mw-300 mb-3 mt-4">
        <Button block color="primary" onClick={toggle}>
          Okay, got it.
        </Button>
      </div>
      <Form onSubmit={requestScaleReturn} onSuccess={onSuccess} className="mw-300 form-fixed-period">
        {({ isSubmitting }) => (
          <>
            <div className="mb-4">
              <SubmitButton block outline size="default" isSubmitting={isSubmitting}>
                Request Shipping Label
              </SubmitButton>
            </div>
          </>
        )}
      </Form>
    </>
  );
};

ScaleReturn.propTypes = {
  requestScaleReturn: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  children: PropTypes.node,
};

ScaleReturn.defaultProps = {
  children: (
    <>
      You can keep the scale in case you&apos;d like to switch back to <strong>Subscribe By Usage</strong>.
    </>
  ),
};
