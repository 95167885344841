import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import * as dc from 'dc';
import { useMediaQuery } from 'react-responsive';
import { useAppContainer } from '../../../../hooks/use-app-container.hook';
import { useMemo } from 'react';

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const MonthsConsumptionGraph = ({ data }) => {
  const container = useAppContainer();
  const isMobile = useMediaQuery({ maxDeviceWidth: 991 });
  const ref = useRef();

  const primaryColor = useMemo(() => getComputedStyle(container).getPropertyValue('--primary'), [container]);

  useEffect(() => {
    const dimension = data.dimension(item => item.timestamp.getMonth());
    const group = dimension.group().reduceSum(item => (item.diff > 0 ? item.diff : 0));

    const chart = dc
      .barChart(ref.current)
      .margins({ right: 0, left: 30, bottom: 30, top: 0 })
      .x(d3.scaleBand())
      .xUnits(dc.units.ordinal)
      .ordinalColors([primaryColor || '#0632c2'])
      .elasticY(true)
      .dimension(dimension)
      .group(group);

    chart.xAxis().tickFormat(index => {
      const skips = isMobile ? 4 : 2;
      const label = isMobile ? months[index].substring(0, 3) : months[index];
      return index % skips === 0 ? label : '';
    });
    chart
      .yAxis()
      .tickFormat(weight => `${weight}oz`)
      .tickSize(1);

    chart.render();
  }, [ref, isMobile, data, primaryColor]);

  return (
    <div className="data-months">
      <div className="text-center mb-2">Monthly estimated usage</div>
      <div ref={ref}></div>
    </div>
  );
};

MonthsConsumptionGraph.propTypes = {
  data: PropTypes.object.isRequired,
};
