import { useCallback } from 'react';
import { useConfigValue } from './use-config-value.hook';

export const useFacebookPixel = () => {
  const isEnabled = useConfigValue('useFacebookPixel');

  const track = useCallback(
    (eventName, data, options) => {
      if (!isEnabled) {
        return;
      }

      if (!window.fbq) {
        // eslint-disable-next-line no-console
        console.log("Can't use Facebook Pixel - no fbq() function.");

        return;
      }

      window.fbq('track', eventName, data, options);
    },
    [isEnabled]
  );

  const purchase = useCallback(
    ({ productId, value, name, checkoutId }) =>
      track(
        'Purchase',
        {
          currency: 'USD',
          value: value.toFixed(2),
          num_items: 1,
          content_type: 'product_group',
          content_ids: [Number(productId)],
          content_name: name,
        },
        { eventID: `purchase-${checkoutId}` }
      ),
    [track]
  );

  return { purchase };
};
