import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { useCallback } from 'react';

export const StepReturnLabelRequested = ({ setNextStep }) => {
  const onClick = useCallback(() => setNextStep(), [setNextStep]);

  return (
    <>
      <div className="mb-4">You should receive an e-mail with a shipping label within the next 2 hours.</div>
      <div className="mw-300">
        <Button color="primary" outline block onClick={onClick}>
          Close
        </Button>
      </div>
    </>
  );
};

StepReturnLabelRequested.headingText = 'Return label requested';

StepReturnLabelRequested.propTypes = {
  setNextStep: PropTypes.func.isRequired,
};
