import React, { useEffect } from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { PanelLayoutGuest } from '../../layouts/PanelLayoutGuest';

export const NewSubscriptionSuccessPage = () => {
  const [, , removeCookie] = useCookies();

  useEffect(() => {
    removeCookie('newCheckout', { path: '/' });
    removeCookie('newFixedCheckout', { path: '/' });
    removeCookie('newSbuCheckout', { path: '/' });
  }, [removeCookie]);

  return (
    <PanelLayoutGuest>
      <h1 className="mb-3 text-center">New Subscription Created</h1>
      <div className="text-center mb-5">
        <div>We&apos;re currently setting up your new account.</div>
        <div>
          You&apos;ll receive an <strong>email</strong> from us as soon as it&apos;s ready to go.
        </div>
      </div>
      <div className="text-center">
        <Link to="/profile">
          <Button color="primary">Go back to your profile</Button>
        </Link>
      </div>
    </PanelLayoutGuest>
  );
};

NewSubscriptionSuccessPage.propTypes = {};
