import React, { useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { Heading, SubmitButton } from '@bottomless/common/components';
import { DataHandler } from '@bottomless/common/components';
import { DataHandlerContent } from '@bottomless/common/components';
import { combineStates } from '../../utils/combine-states';
import { PanelLayoutGuest } from '../../layouts/PanelLayoutGuest';
import { CheckoutForm, Frequency, PaymentInfo, Pickup, Product, ShippingAddress } from './components';
import { useCheckout } from './hooks/checkout.hook';

export const NewSubscriptionSummaryPage = () => {
  const history = useHistory();
  const { id } = useParams();
  const checkout = useCheckout(id);

  const onSuccess = useCallback(() => history.push(`/new-subscription-summary/${id}/complete`), [history, id]);

  return (
    <PanelLayoutGuest>
      <div className="page page-new-subscription-summary">
        <Heading className="mb-5">New Subscription</Heading>
        <DataHandler {...combineStates(checkout)} />
        <DataHandlerContent {...combineStates(checkout)}>
          {checkout.data && (
            <CheckoutForm
              checkout={checkout.data}
              onSubmit={checkout.completeCheckout}
              onSuccess={onSuccess}
              phoneNumbers={checkout.data.phoneNumbers}
            >
              {({ isSubmitting, values, setFieldValue }) => (
                <Row className="row-spaced flex-column-reverse flex-md-row">
                  <Col xs="12" md="6">
                    {checkout.data.verifiedAddress?.pickup && (
                      <Pickup checkout={checkout.data} phoneNumbers={checkout.data.phoneNumbers} />
                    )}
                    {!checkout.data.verifiedAddress?.pickup && (
                      <ShippingAddress
                        values={values}
                        setFieldValue={setFieldValue}
                        phoneNumbers={checkout.data.phoneNumbers}
                      />
                    )}
                    <Frequency checkout={checkout.data} />
                    <PaymentInfo />
                    <SubmitButton
                      color="primary"
                      size="default"
                      block
                      isSubmitting={isSubmitting}
                      loadingText="Processing"
                    >
                      Create New Subscription
                    </SubmitButton>
                  </Col>
                  <Col xs="12" md="6">
                    <Product product={checkout.data.product} checkout={checkout.data} />
                  </Col>
                </Row>
              )}
            </CheckoutForm>
          )}
        </DataHandlerContent>
      </div>
    </PanelLayoutGuest>
  );
};
