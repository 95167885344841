import { Box } from '@bottomless/common/components';
import PropTypes from 'prop-types';
import React from 'react';

export const InfoNumbers = ({ number, children, text, secondary }) => {
  return (
    <Box secondary={secondary} className="info-number flex-grow-1">
      <div className="d-flex justify-content-center mb-2 info-number-value">{children || number}</div>
      <div className="text-center text-primary text-uppercase font-weight-bold info-number-text">{text}</div>
    </Box>
  );
};

InfoNumbers.propTypes = {
  number: PropTypes.number,
  children: PropTypes.node,
  text: PropTypes.string,
  secondary: PropTypes.bool,
};
