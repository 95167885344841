import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import * as dc from 'dc';
import crossfilter from 'crossfilter2';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';
import { EmptyState } from '@bottomless/common/components';
import './DailyConsumptionGraph.scss';
import { useAppContainer } from '../../../../hooks/use-app-container.hook';
import { useMemo } from 'react';

export const DailyConsumptionGraph = ({ data }) => {
  const container = useAppContainer();
  const ref = useRef();
  const isMobile = useMediaQuery({ maxDeviceWidth: 991 });
  const now = moment();
  const date30DaysAgo = moment().subtract(30, 'days');
  const filteredData = data.all().filter(it => moment(it.timestamp).isBetween(date30DaysAgo, now));

  const primaryColor = useMemo(() => getComputedStyle(container).getPropertyValue('--primary'), [container]);

  useEffect(() => {
    const dateFormat = 'YYYY-MM-DD';
    const dimension = crossfilter(filteredData).dimension(it => moment(it.timestamp).format(dateFormat));
    const group = dimension.group().reduceSum(it => (it.diff > 0 ? it.diff : 0));
    const chart = dc
      .barChart(ref.current)
      .margins({ right: 0, left: 30, bottom: 30, top: 0 })
      .x(d3.scaleBand())
      .xUnits(dc.units.ordinal)
      .ordinalColors([primaryColor || '#0632c2'])
      .elasticY(true)
      .dimension(dimension)
      .group(group);

    chart.xAxis().tickFormat((it, i) => {
      const skips = isMobile ? 4 : 2;
      const label = moment(it, dateFormat).format('ddd DD');
      return i % skips === 0 ? label : '';
    });

    chart
      .yAxis()
      .tickFormat(weight => `${weight}oz`)
      .tickSize(1);

    chart.render();
  }, [ref, filteredData, isMobile, primaryColor]);

  return (
    <div className="daily-consumption-graph overflow-auto">
      {filteredData.length === 0 && (
        <div className="position-absolute no-data-view">
          <EmptyState
            title="No data last 30 days"
            description="It will be available after your scale sends more data"
          />
        </div>
      )}

      <div className="text-center mb-2">Daily estimated usage</div>
      <div ref={ref}></div>
    </div>
  );
};

DailyConsumptionGraph.propTypes = {
  data: PropTypes.object.isRequired,
};
