import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Price } from '@bottomless/common/components';
import { useMemo } from 'react';

const DYNAMIC_TEXT = 'Calculated upon order processing';

export const ShippingAndTax = ({ pricingRule }) => {
  const price = useMemo(() => {
    if (!isNaN(pricingRule.first_shipping_price)) {
      if (!pricingRule.first_shipping_price) {
        return 'Free';
      }

      return <Price value={pricingRule.first_shipping_price} cents />;
    }

    if (pricingRule.free_shipping) {
      return 'Free';
    }

    if (pricingRule.shipping_price) {
      return <Price value={pricingRule.shipping_price} cents />;
    }

    return DYNAMIC_TEXT;
  }, [pricingRule]);

  const isDynamicShipping = useMemo(() => price === DYNAMIC_TEXT, [price]);

  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <div>{isDynamicShipping ? 'Shipment and Tax' : 'Shipment'}</div>
        <span className={classNames('text-right', { 'text-sm font-weight-normal text-secondary': isDynamicShipping })}>
          {price}
        </span>
      </div>
      {!isDynamicShipping && (
        <div className="d-flex align-items-center justify-content-between">
          <div>Tax</div>
          <span className="text-right text-sm font-weight-normal text-secondary">{DYNAMIC_TEXT}</span>
        </div>
      )}
    </>
  );
};

ShippingAndTax.propTypes = {
  pricingRule: PropTypes.shape({
    first_shipping_price: PropTypes.number,
    free_shipping: PropTypes.bool,
    shipping_price: PropTypes.bool,
  }).isRequired,
};
