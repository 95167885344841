import React from 'react';
import { UserCategoryManifest } from '../../../../components/CategoryManifest';
import { CoffeeChosenProduct, GenericChosenProduct } from '../../manifest';

const COMPONENTS = {
  CoffeeShopifyChosenProduct: CoffeeChosenProduct,
  GenericShopifyChosenProduct: GenericChosenProduct,
};

export const ChosenProduct = props => (
  <UserCategoryManifest
    manifestKey="shopifyChosenProduct"
    components={COMPONENTS}
    fallbackComponent={GenericChosenProduct}
    {...props}
  />
);
