import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Package, Truck, Home, Map, Check } from 'react-feather';
import { DateFormat } from '@bottomless/common/components';
import { READABLE_FORMAT } from '../../utils/dates';
import './TrackingTimelineElement.scss';
import { useMemo } from 'react';

const Icons = {
  ['Pre transit']: Package,
  ['In transit']: Truck,
  ['Out for delivery']: Map,
  ['Delivered']: Home,
};

export const TrackingTimelineElement = ({ track }) => {
  const status = useMemo(
    () =>
      (track.status || track.message)
        .replace(
          /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
          ''
        )
        .trim(),
    [track]
  );

  const Icon = useMemo(() => Icons[status] || Check, [status]);

  return (
    <div className={classNames('d-flex tracking-timeline-row', { 'text-secondary': !track.originalMessage })}>
      <div className="text-right tracking-timeline-time">
        <div>
          <DateFormat
            date={track.time}
            format={READABLE_FORMAT}
            toTimezone={Intl.DateTimeFormat().resolvedOptions().timeZone}
          />
        </div>
        <div className="small">
          <DateFormat date={track.time} format="h:mm a" toTimezone={Intl.DateTimeFormat().resolvedOptions().timeZone} />
        </div>
      </div>
      <div className="tracking-timeline-data">
        <Icon width={14} height={14} className="text-dark tracking-timeline-icon" strokeWidth={3} />
        <div className="font-weight-bold">{track.originalMessage}</div>
        <div>{status}</div>
        {track.type !== 'note' && <div className="small text-secondary">{track.location}</div>}
      </div>
    </div>
  );
};

TrackingTimelineElement.propTypes = {
  track: PropTypes.shape({
    time: PropTypes.string.isRequired,
    originalMessage: PropTypes.string,
    status: PropTypes.string,
    message: PropTypes.string,
    location: PropTypes.string.isRequired,
    type: PropTypes.string,
  }).isRequired,
};
