import * as momentTimezone from 'moment-timezone';
import { localDate, utcDate } from '@bottomless/common/utils';
import { useCallback, useMemo } from 'react';
import { getNextFulfillmentDate } from '../utils/dates';
import { DumbType, OrderSources, OrderStatuses } from '@bottomless/common/constants';

const DUMB_ORDER_GEN_HOUR = 13;
const pstTimezone = 'America/Los_Angeles';

const getQualifier = user => {
  switch (user?.dumb_type) {
    case DumbType.FirstThursday:
      return date => date.getDay() === 4 && date.getDate() <= 7;
    case DumbType.FirstWednesday:
      return date => date.getDay() === 3 && date.getDate() <= 7;
    default:
      return undefined;
  }
};

const getNextCalculationStartingDay = (user, dumbPeriod) => {
  switch (user?.dumb_type) {
    case DumbType.FirstThursday:
    case DumbType.FirstWednesday:
      return 2;
    default:
      return dumbPeriod;
  }
};

const getNextDayOfMonth = (startDate, dumbDay, cycles) => {
  let currentMonth = startDate.getMonth();
  let currentYear = startDate.getFullYear();

  if (startDate.getDate() > dumbDay) {
    currentMonth += 1;
    if (currentMonth > 11) {
      currentMonth = 0;
      currentYear += 1;
    }
  }

  if (cycles > 1) {
    currentMonth += cycles - 1;
    if (currentMonth > 11) {
      currentMonth = currentMonth - 12;
      currentYear += 1;
    }
  }

  return localDate(new Date(currentYear, currentMonth, dumbDay));
};

export const useLastOrderDate = ({ lastOrder, upcomingOrders, user }) => {
  const nowInSeattle = useMemo(() => momentTimezone().tz(pstTimezone), []);

  const dayAfterPause = useMemo(() => {
    if (!user?.pausedUntil) {
      return null;
    }

    const pausedUntil = utcDate(user.pausedUntil);

    return new Date(pausedUntil.setDate(pausedUntil.getDate() + 1));
  }, [user]);

  const { today, tomorrow, dayAfterTomorrow } = useMemo(() => {
    const today = new Date();

    const tomorrow = new Date(new Date(today.getTime()).setDate(today.getDate() + 1));
    const dayAfterTomorrow = new Date(new Date(today.getTime()).setDate(today.getDate() + 2));

    return { today, tomorrow, dayAfterTomorrow };
  }, []);

  const isFirstThursdayOrWednesday =
    user?.dumb_type === DumbType.FirstThursday || user?.dumb_type === DumbType.FirstWednesday;

  const excludedDatesForFulfillment = useMemo(() => {
    if (isFirstThursdayOrWednesday) {
      return [];
    }

    const { exclude_fulfilment_dates } = upcomingOrders.data?.vendor || {};
    return exclude_fulfilment_dates ? exclude_fulfilment_dates.map(date => utcDate(date)) : [];
  }, [isFirstThursdayOrWednesday, upcomingOrders]);

  const nextAvailableOrderGenAndFulfillmentDate = useMemo(() => {
    const nextOrdergenDate = nowInSeattle.hour() < DUMB_ORDER_GEN_HOUR ? tomorrow : dayAfterTomorrow;

    const startDate = dayAfterPause
      ? new Date(Math.max(dayAfterPause.getTime(), nextOrdergenDate.getTime()))
      : nextOrdergenDate;

    return localDate(
      new Date(
        getNextFulfillmentDate({
          startDate,
          excludedDates: excludedDatesForFulfillment,
          qualifier: getQualifier(user),
        })
      )
    );
  }, [dayAfterPause, tomorrow, dayAfterTomorrow, nowInSeattle, excludedDatesForFulfillment, user]);

  const nextAvailableOrderGenAndFulfillmentDateExcludingPaused = useMemo(() => {
    const nextOrdergenDate = nowInSeattle.hour() < DUMB_ORDER_GEN_HOUR ? tomorrow : dayAfterTomorrow;

    return localDate(
      new Date(
        getNextFulfillmentDate({
          startDate: nextOrdergenDate,
          excludedDates: excludedDatesForFulfillment,
          qualifier: getQualifier(user),
        })
      )
    );
  }, [tomorrow, dayAfterTomorrow, nowInSeattle, excludedDatesForFulfillment, user]);

  const nextAvailableFulfillmentDate = useMemo(() => {
    return new Date(
      getNextFulfillmentDate({
        startDate: today,
        excludedDates: excludedDatesForFulfillment,
        qualifier: getQualifier(user),
        debug: true,
      })
    );
  }, [today, excludedDatesForFulfillment, user]);

  const latestOrderDate = useMemo(() => {
    const [latestOrder] = [
      ...(upcomingOrders.data?.orders
        ?.filter(({ order }) => order.source !== OrderSources.USER_ONE_OFF)
        .map(
          ({ order }) =>
            new Date(order.override_fulfillment_date || order.date_fulfilled || nextAvailableFulfillmentDate)
        ) || []),
      ...(lastOrder.data?.date_fulfilled ? [new Date(lastOrder.data.date_fulfilled)] : []),
    ].sort((a, b) => new Date(b) - new Date(a));

    return latestOrder;
  }, [upcomingOrders, lastOrder, nextAvailableFulfillmentDate]);

  const latestInitiatedOrderDate = useMemo(() => {
    const [latestInitiatedOrder] = [
      ...(upcomingOrders.data?.orders
        ?.filter(
          ({ order }) =>
            [OrderStatuses.Initiated, OrderStatuses.SubproductGenerated].includes(order.status) &&
            order.source !== OrderSources.USER_ONE_OFF
        )
        .map(({ order }) =>
          order.override_fulfillment_date
            ? new Date().getTimezoneOffset() > 0
              ? utcDate(order.override_fulfillment_date)
              : localDate(order.override_fulfillment_date)
            : new Date(order.date_fulfilled || nextAvailableFulfillmentDate)
        ) || []),
    ].sort((a, b) => new Date(b) - new Date(a));

    return latestInitiatedOrder;
  }, [upcomingOrders, nextAvailableFulfillmentDate]);

  const getNextOrderDate = useCallback(
    (rawValue, useOrderDate, cycles = 1, { dumb_type, dumb_day }, ignorePaused) => {
      if (!latestInitiatedOrderDate && !latestOrderDate) {
        if (dumb_type === DumbType.SameDayMonthly) {
          return getNextDayOfMonth(new Date(), dumb_day, cycles);
        }
        return ignorePaused
          ? nextAvailableOrderGenAndFulfillmentDateExcludingPaused
          : nextAvailableOrderGenAndFulfillmentDate;
      }

      if (useOrderDate && latestInitiatedOrderDate) {
        return latestInitiatedOrderDate;
      }

      if (latestInitiatedOrderDate && cycles > 1) {
        if (dumb_type === DumbType.SameDayMonthly) {
          return getNextDayOfMonth(latestInitiatedOrderDate, dumb_day, cycles);
        }
        cycles--;
      }

      if (dumb_type === DumbType.SameDayMonthly) {
        return getNextDayOfMonth(latestInitiatedOrderDate || new Date(), dumb_day, cycles);
      }

      let nextOrderDate;
      const latestDate = latestInitiatedOrderDate ? latestInitiatedOrderDate : latestOrderDate;

      if (isFirstThursdayOrWednesday) {
        nextOrderDate = new Date(
          new Date(latestDate.getFullYear(), latestDate.getMonth(), latestDate.getDate() + 32 * cycles).setDate(0)
        );
      } else {
        const value = getNextCalculationStartingDay(user, rawValue);
        nextOrderDate = new Date(new Date(latestDate).setDate(latestDate.getDate() + Number(value) * cycles));
      }

      if (ignorePaused) {
        if (!latestInitiatedOrderDate && nextOrderDate < nextAvailableOrderGenAndFulfillmentDateExcludingPaused) {
          nextOrderDate = nextAvailableOrderGenAndFulfillmentDateExcludingPaused;
        }
      } else {
        if (!latestInitiatedOrderDate && nextOrderDate < nextAvailableOrderGenAndFulfillmentDate) {
          nextOrderDate = nextAvailableOrderGenAndFulfillmentDate;

          if (isFirstThursdayOrWednesday && cycles === 2) {
            nextOrderDate = new Date(
              new Date(nextOrderDate.getFullYear(), nextOrderDate.getMonth(), nextOrderDate.getDate() + 32).setDate(0)
            );
          }
        }
      }

      if (!ignorePaused && dayAfterPause) {
        nextOrderDate = Math.max(dayAfterPause, nextOrderDate);
      }

      const nextFulfillment = localDate(
        new Date(
          getNextFulfillmentDate({
            startDate: nextOrderDate,
            excludedDates: excludedDatesForFulfillment,
            qualifier: getQualifier(user),
          })
        )
      );

      return nextOrderDate > nextFulfillment ? nextOrderDate : nextFulfillment;
    },
    [
      latestInitiatedOrderDate,
      latestOrderDate,
      excludedDatesForFulfillment,
      nextAvailableOrderGenAndFulfillmentDate,
      user,
      dayAfterPause,
      nextAvailableOrderGenAndFulfillmentDateExcludingPaused,
      isFirstThursdayOrWednesday,
    ]
  );

  const getNextOrderDateFromNow = useCallback(
    (rawValue = 0) => {
      const now = nowInSeattle.toDate();
      const value = getNextCalculationStartingDay(user, rawValue);
      const nextOrderDate = new Date(new Date(now).setDate(now.getDate() + Number(value)));
      const nextFulfillment = new Date(
        getNextFulfillmentDate({
          startDate: nextOrderDate,
          excludedDates: excludedDatesForFulfillment,
          qualifier: getQualifier(user),
        })
      );

      return nextOrderDate > nextFulfillment ? nextOrderDate : nextFulfillment;
    },
    [nowInSeattle, excludedDatesForFulfillment, user]
  );

  return { getNextOrderDate, getNextOrderDateFromNow };
};
