import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Form, SubmitButton } from '@bottomless/common/components';

const Schema = Yup.object().shape({
  dumb_period: Yup.mixed(),
});

export const SwitchForm = ({ setDumbPeriod, onSuccess }) => (
  <Form
    initialValues={{ dumb_period: null, sendNotification: true }}
    validationSchema={Schema}
    onSubmit={setDumbPeriod}
    onSuccess={onSuccess}
    className="form-sbu mb-4"
  >
    {({ isSubmitting }) => (
      <>
        <div className="mw-300">
          <SubmitButton block size="default" isSubmitting={isSubmitting} className="mt-4">
            Switch to Subscribe By Usage
          </SubmitButton>
        </div>
      </>
    )}
  </Form>
);

SwitchForm.propTypes = {
  setDumbPeriod: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  place: PropTypes.string.isRequired,
};
