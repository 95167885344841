import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDataEffect } from '@bottomless/common/hooks';
import { useQueryString } from '@bottomless/common/hooks';
import {
  deleteCustomListItemAction,
  getCustomListAction,
  moveCustomListItemAction,
  updateCustomListAction,
} from '../../../store/product';

export const useQueue = () => {
  const {
    params: { openQueue },
    update,
  } = useQueryString();

  const dispatch = useDispatch();
  const action = useCallback(() => dispatch(getCustomListAction()), [dispatch]);
  const { data } = useSelector(({ product: { wishlist } }) => ({ data: wishlist }));

  const moveCustomListItem = useCallback(
    (sourceIndex, destinationIndex) => dispatch(moveCustomListItemAction(sourceIndex, destinationIndex)),
    [dispatch]
  );

  const deleteCustomListItem = useCallback(index => dispatch(deleteCustomListItemAction(index)), [dispatch]);

  const updateCustomList = useCallback(data => dispatch(updateCustomListAction(data)), [dispatch]);

  const onCloseQueue = useCallback(() => {
    if (openQueue) {
      update({ openQueue: undefined });
    }
  }, [openQueue, update]);

  const { error, isFetching: isLoading } = useDataEffect(action);

  return {
    data,
    isLoading,
    error,
    moveCustomListItem,
    deleteCustomListItem,
    updateCustomList,
    openQueue,
    onCloseQueue,
  };
};
