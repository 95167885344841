import React, { useMemo } from 'react';
import classNames from 'classnames';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { Battery, DateFormat, Weight } from '@bottomless/common/components';
import { ScaleStatus } from '@bottomless/common/src/constants';
import { InfoNumbers } from './InfoNumbers';
import { isWholeNumber } from '@bottomless/common/src/utils';

const STATUS_COLOR = {
  [ScaleStatus.Connected]: 'bg-success',
  [ScaleStatus.Disconnected]: 'bg-danger',
};

export const ScaleData = ({ status, readings = [], lastTare }) => {
  const bgClass = useMemo(() => STATUS_COLOR[status?.scale_status] || 'bg-warning', [status]);
  const isConnected = useMemo(() => status?.scale_status === ScaleStatus.Connected, [status]);
  const isTareLastReading = useMemo(() => {
    if (!lastTare) {
      return false;
    }

    return !readings.length || new Date(readings[0].timestamp) < new Date(lastTare.timestamp);
  }, [lastTare, readings]);

  return (
    <Row className="scale-data-row justify-content-center">
      {status?.scale_status && (
        <Col xs="6" md="4" xl="3" className="mb-2 mb-md-4 flex-grow-1">
          <InfoNumbers secondary text="Status">
            <div className="text-capitalize d-flex align-items-center">
              <div className="scale-icon">
                <i className={classNames('scale-status', bgClass)}></i>
              </div>
              <span>{status.scale_status}</span>
            </div>
          </InfoNumbers>
        </Col>
      )}
      {isTareLastReading && (
        <>
          <Col xs="6" md="4" xl="3" className="mb-2 mb-md-4 text-uppercase">
            <InfoNumbers secondary text="Last connected">
              <DateFormat date={lastTare.timestamp} toSeattle format="MM/DD/YY h:mm a" />
            </InfoNumbers>
          </Col>
          <Col xs="6" md="4" lg="3" className="mb-2 mb-md-4">
            <InfoNumbers secondary text="Weight">
              <Weight value={0} />
            </InfoNumbers>
          </Col>
        </>
      )}
      {!isTareLastReading && (
        <>
          {isConnected && isWholeNumber(status?.scale_last_weight) && (
            <Col xs="6" md="4" xl="3" className="mb-2 mb-md-4 text-uppercase">
              <InfoNumbers secondary text="Last connected">
                <DateFormat date={status.scale_last_connected} toSeattle format="MM/DD/YY h:mm a" />
              </InfoNumbers>
            </Col>
          )}
          {isConnected && isWholeNumber(status?.scale_last_weight) && (
            <Col xs="6" md="4" xl="3" className="mb-2 mb-md-4">
              <InfoNumbers secondary text="Weight">
                <Weight value={status.scale_last_weight} toFixed={1} />
              </InfoNumbers>
            </Col>
          )}
        </>
      )}
      {isConnected && status?.scale_battery_level > 0 && (
        <Col xs="6" md="4" xl="3" className="mb-2 mb-md-4">
          <InfoNumbers secondary text="Battery">
            <div>
              <Battery className="scale-icon" batteryLevel={status.scale_battery_level} />{' '}
              {Math.round(status.scale_battery_level)}%
            </div>
          </InfoNumbers>
        </Col>
      )}
    </Row>
  );
};

ScaleData.propTypes = {
  lastTare: PropTypes.shape({
    timestamp: PropTypes.string,
  }),
  readings: PropTypes.arrayOf(
    PropTypes.shape({
      timestamp: PropTypes.string,
    })
  ),
  status: PropTypes.shape({
    scale_status: PropTypes.string,
    scale_last_weight: PropTypes.number,
    scale_battery_level: PropTypes.number,
    scale_last_connected: PropTypes.string,
  }),
};
