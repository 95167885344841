import React from 'react';
import PropTypes from 'prop-types';
import { useLoadingAction, useToast, useToggle } from '@bottomless/common/hooks';
import { Box } from '@bottomless/common/components';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useModal } from '../../../hooks/use-modal.hook';
import { Loader } from 'react-feather';
import { useCallback } from 'react';

export const PendingPhoneNumber = ({ me, acceptShopifyPhone, rejectShopifyPhone }) => {
  const [isOpen, toggle, setOpen] = useToggle();
  const modalProps = useModal();

  const acceptToast = useToast('Your phone number has been verified.');
  const rejectToast = useToast('Your phone number has been deleted.');

  const handleAccept = useCallback(async () => {
    const result = await acceptShopifyPhone();
    setOpen(false);
    acceptToast();
    return result;
  }, [setOpen, acceptShopifyPhone, acceptToast]);

  const handleReject = useCallback(async () => {
    const result = await rejectShopifyPhone();
    setOpen(false);
    rejectToast();
    return result;
  }, [setOpen, rejectShopifyPhone, rejectToast]);

  const { isLoading: isAcceptLoading, execute: onAcceptClick } = useLoadingAction(handleAccept);
  const { isLoading: isRejectLoading, execute: onRejectClick } = useLoadingAction(handleReject);

  if (me?.phone || !me?.local.pendingPhoneNumber || me?.local.phoneVerificationToken) {
    return null;
  }

  return (
    <>
      <div className="mb-5">
        <div className="mb-2">We detected your phone number:</div>
        <Box className="mb-3" innerClassName="d-flex align-items-center justify-content-between">
          <div>{me.local.pendingPhoneNumber}</div>
          <Button color="primary" outline size="sm" onClick={toggle}>
            Verify
          </Button>
        </Box>
        <hr className="mt-5" />
      </div>
      <Modal isOpen={isOpen} size="md" toggle={toggle} {...modalProps} className="modal-change-product">
        <ModalHeader toggle={toggle}>Verify your phone number</ModalHeader>
        <ModalBody>
          <div className="text-center">
            <div>We detected the following phone number might be yours:</div>
            <div className="mb-5">{me.local.pendingPhoneNumber}</div>
            <div className="mb-2">
              <Button onClick={onAcceptClick} color="primary" disabled={isAcceptLoading || isRejectLoading}>
                {isAcceptLoading ? (
                  <>
                    Saving <Loader size="13" className="spin ml-2" />
                  </>
                ) : (
                  'Yes, this is my phone number'
                )}
              </Button>
            </div>
            <div>
              <Button onClick={onRejectClick} color="link" disabled={isAcceptLoading || isRejectLoading}>
                {isRejectLoading ? (
                  <>
                    Saving <Loader size="13" className="spin ml-2" />
                  </>
                ) : (
                  'No, please delete it'
                )}
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

PendingPhoneNumber.propTypes = {
  me: PropTypes.shape({
    phone: PropTypes.string,
    local: PropTypes.shape({
      pendingPhoneNumber: PropTypes.string,
      phoneVerificationToken: PropTypes.string,
    }).isRequired,
  }),
  acceptShopifyPhone: PropTypes.func.isRequired,
  rejectShopifyPhone: PropTypes.func.isRequired,
};
