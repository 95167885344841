import React from 'react';
import PropTypes from 'prop-types';
import { ChevronLeft } from 'react-feather';
import { Link } from 'react-router-dom';

export const BackButton = ({ to }) => (
  <Link to={to} className="d-flex align-items-center text-decoration-none">
    <ChevronLeft size="14" /> Back
  </Link>
);

BackButton.propTypes = {
  to: PropTypes.string.isRequired,
};
