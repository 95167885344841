import React, { useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import * as Yup from 'yup';
import { Box, Form, RadioBox, SubmitButton } from '@bottomless/common/components';
import { useToast, useToggle } from '@bottomless/common/hooks';
import { useModal } from '../../../../hooks/use-modal.hook';
import { useCallback } from 'react';
import { Edit } from 'react-feather';

const Schema = Yup.object().shape({
  ordering_aggression: Yup.string().required('This field is required'),
});

export const levels = [
  {
    level: 1,
    shortDes: 'Never Run Out',
    description: 'I always want to have my product in stock. Some overlap is okay.',
  },
  {
    level: 2,
    shortDes: 'Just Right',
    description: 'I want a balance between not running out and not overlapping orders.',
  },
  {
    level: 3,
    shortDes: 'Later Is Okay',
    description: "I don't want any overlap. It's okay if I run out.",
  },
];

export const UpdateOrderingStrategy = ({ me, onSubmit }) => {
  const modalProps = useModal();
  const [isOpen, toggle] = useToggle();
  const form = useRef();

  const initialValues = useMemo(() => ({ ordering_aggression: (me?.ordering_aggression || 2).toString() }), [me]);
  const currentLevel = useMemo(
    () => levels.find(({ level }) => Number(level) === Number(initialValues.ordering_aggression)),
    [initialValues]
  );

  const toggleAndClear = useCallback(() => {
    toggle();
    setTimeout(() => form.current?.resetForm(initialValues), 300);
  }, [toggle, form, initialValues]);

  const successToast = useToast('Your ordering strategy has been changed');

  const onSuccess = useCallback(() => {
    toggleAndClear();
    successToast();
  }, [toggleAndClear, successToast]);

  return (
    <>
      <Box onClick={toggle} className="box-no-arrow small box-ordering-strategy">
        <div className="font-weight-bold mb-2">{currentLevel.shortDes}</div>
        <div>{currentLevel.description}</div>
        <Edit width="12" height="12" className="box-ordering-edit" />
      </Box>
      <Modal isOpen={isOpen} size="lg" toggle={toggleAndClear} {...modalProps} className="modal-ordering-strategy">
        <ModalHeader toggle={toggleAndClear}>Change ordering strategy</ModalHeader>
        <ModalBody>
          <Form
            innerRef={form}
            initialValues={initialValues}
            validationSchema={Schema}
            onSubmit={onSubmit}
            onSuccess={onSuccess}
          >
            {({ isSubmitting }) => {
              return (
                <>
                  <dl>
                    {levels.map(level => (
                      <dt key={level.level}>
                        <RadioBox
                          name="ordering_aggression"
                          type="radio"
                          value={level.level.toString()}
                          label={level.shortDes}
                          description={level.description}
                        />
                      </dt>
                    ))}
                  </dl>
                  <SubmitButton size="default" isSubmitting={isSubmitting}>
                    Save
                  </SubmitButton>
                </>
              );
            }}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

UpdateOrderingStrategy.propTypes = {
  me: PropTypes.shape({
    ordering_aggression: PropTypes.number,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
};
