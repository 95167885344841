import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Image } from '@bottomless/common/components';
import { ChevronRight } from 'react-feather';
import { ListGroup, ListGroupItem } from 'reactstrap';

export const Products = ({ products, onChoose }) => {
  const onClick = useCallback(product => () => onChoose(product), [onChoose]);

  return (
    <ListGroup flush>
      {products.map(product => (
        <ListGroupItem
          key={product._id}
          className="py-3 pl-0 pr-1 d-flex align-items-center justify-content-between cursor-pointer"
          onClick={onClick(product)}
        >
          <div className="d-flex align-items-center">
            <Image src={product.small_image_src} alt={product.name} width="50" height="50" className="mr-2" />
            <div>{product.name}</div>
          </div>
          <div className="text-sm text-secondary">
            <ChevronRight />
          </div>
        </ListGroupItem>
      ))}
    </ListGroup>
  );
};

Products.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({ _id: PropTypes.string.isRequired })).isRequired,
  onChoose: PropTypes.func.isRequired,
};
