import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { DateFormat } from '@bottomless/common/components';
import { READABLE_FORMAT } from '../../utils/dates';
import { SafeTooltip } from '../SafeTooltip';

export const TrackingHistoryRow = ({ track, showOriginal, withPadding }) => (
  <div className={classNames('d-flex justify-content-between', { 'py-2': withPadding })}>
    <div className="d-flex flex-column flex-lg-row flex-grow-1">
      <div className="d-flex justify-content-between">
        <span className="text-nowrap">
          <DateFormat date={track.time} format={READABLE_FORMAT} />
        </span>
        <span className="d-block d-lg-none ml-3 text-secondary text-sm text-nowrap">{track.location}</span>
      </div>
      <div>
        <span className="ml-lg-3">
          {((showOriginal && track.originalMessage) || track.status || track.message)
            .replace(
              /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
              ''
            )
            .trim()}
        </span>
        {track.type !== 'track' && (
          <span className="ml-2">
            <SafeTooltip
              content="This order was reviewed by Bottomless to ensure it is still on track! We will take action if we see
                  issues or delays."
            >
              <span role="img" aria-label="info">
                ℹ️
              </span>
            </SafeTooltip>
          </span>
        )}
      </div>
    </div>
    <span className="d-none d-lg-block ml-3 text-secondary text-sm text-nowrap">{track.location}</span>
  </div>
);

TrackingHistoryRow.propTypes = {
  order: PropTypes.object,
  track: PropTypes.object,
  showOriginal: PropTypes.bool,
  withPadding: PropTypes.bool,
};
