import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { PanelSection } from '../../../components/PanelSection';
import { Box, ChargeFailedReason, DateFormat, Field, Form, SubmitButton } from '@bottomless/common/components';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useToast, useToggle } from '@bottomless/common/hooks';
import { useModal } from '../../../hooks/use-modal.hook';
import { OrderStatuses } from '@bottomless/common/constants';

const initialValues = { token: '' };

const Schema = Yup.object().shape({
  token: Yup.string().required('This field is required'),
});

export const ChargeFailure = ({ me, order, verifyStripe, sendPaymentUpdateEmail }) => {
  const form = useRef();
  const [isOpen, toggle] = useToggle();
  const [isUpdated, setUpdated] = useState(false);
  const modalProps = useModal();

  const stripeRef = useRef();

  const [error, setError] = useState(null);
  const [isProcessing, setProcessing] = useState(false);
  const [linkSent, setLinkSent] = useState(false);

  const toggleAndClear = useCallback(() => {
    toggle();
    setTimeout(() => form.current?.resetForm(initialValues), 300);
  }, [toggle, form]);

  const successToast = useToast('Your card details have been updated');

  const onSuccess = useCallback(() => {
    toggleAndClear();
    successToast();
    setUpdated(false);
  }, [toggleAndClear, successToast]);

  const onClick = useCallback(async () => {
    if (!me.shopifySubscriptionContractId) {
      return toggle();
    }

    try {
      setError('');
      setLinkSent(false);
      setProcessing(true);
      await sendPaymentUpdateEmail();
      setProcessing(false);
      setLinkSent(true);
    } catch (e) {
      setProcessing(false);
      setError(e.message);
    }
  }, [sendPaymentUpdateEmail, toggle, me]);

  const onSaveCardClick = useCallback(async () => {
    try {
      setProcessing(true);
      const { token } = await stripeRef.current.createToken();
      form.current.setFieldValue('token', token.id);
      form.current.submitForm();
      setProcessing(false);
    } catch (e) {
      setProcessing(false);
      setError(e.message);
    }
  }, [stripeRef]);

  if (
    [OrderStatuses.Cancelled, OrderStatuses.Refunded].includes(order.status) ||
    order.date_fulfilled ||
    !order.fulfillmentErrors?.charge?.error
  ) {
    return null;
  }

  return (
    <>
      <PanelSection
        title={
          <div className="d-flex align-items-center">
            Charge failed <span className="charge-failure-icon">!</span>
          </div>
        }
      >
        <Box>
          {me?._id && (
            <div className="mb-2">
              <div>Reason</div>
              <div className="text-secondary">
                <ChargeFailedReason reason={order.fulfillmentErrors.charge.error} />
              </div>
            </div>
          )}
          <div className={me?._id ? 'mb-2' : undefined}>
            <div>Last try</div>
            <div className="text-secondary">
              <DateFormat date={order.fulfillmentErrors.charge.date} withTime />
            </div>
          </div>
          {me?._id && (
            <Button color="primary" size="sm" block outline onClick={onClick}>
              Update Payment Method
            </Button>
          )}
          {linkSent && (
            <div className="text-success mt-3 text-center">
              We sent you secure link via email. Use it to update your payment details.
              <br />
              <br />
              Once you update, we&apos;ll reprocess your order the next morning so it&apos;s fulfilled on the earliest
              available date.
            </div>
          )}
          {error && me.shopifySubscriptionContractId && <div className="text-danger mt-1">{error}</div>}
          {isUpdated && (
            <div className="text-success mt-3 text-center">
              Your card has been updated. We&apos;ll reprocess your order tomorrow morning so it&apos;s fulfilled on the
              earliest available date.
            </div>
          )}
        </Box>
      </PanelSection>
      <Modal isOpen={isOpen} size="md" toggle={toggleAndClear} {...modalProps} className="modal-change-product">
        <ModalHeader toggle={toggleAndClear}>Update payment method</ModalHeader>
        <ModalBody>
          <Form
            innerRef={form}
            initialValues={initialValues}
            validationSchema={Schema}
            onSubmit={verifyStripe}
            onSuccess={onSuccess}
          >
            {({ isSubmitting }) => (
              <>
                <div className="mb-3">
                  <stripe-elements ref={stripeRef} publishable-key={process.env.REACT_APP_STRIPE_KEY}></stripe-elements>
                  {error && <div className="text-sm text-danger mt-1">{error}</div>}
                  <Field type="hidden" name="token" />
                </div>
                <div className="text-center">
                  <SubmitButton
                    type="button"
                    onClick={onSaveCardClick}
                    size={null}
                    isSubmitting={isSubmitting || isProcessing}
                  >
                    Save
                  </SubmitButton>
                </div>
              </>
            )}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

ChargeFailure.propTypes = {
  me: PropTypes.shape({
    _id: PropTypes.string,
    shopifySubscriptionContractId: PropTypes.string,
  }),
  order: PropTypes.shape({
    status: PropTypes.string,
    date_fulfilled: PropTypes.string,
    fulfillmentErrors: PropTypes.shape({
      charge: PropTypes.shape({
        error: PropTypes.string,
        date: PropTypes.string,
      }),
    }),
  }).isRequired,
  verifyStripe: PropTypes.func.isRequired,
  sendPaymentUpdateEmail: PropTypes.func.isRequired,
};
