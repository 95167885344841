import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { SubmitButton, Form, Field, DateFormat } from '@bottomless/common/components';
import { utcDate } from '@bottomless/common/utils';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useToggle } from '@bottomless/common/hooks';
import { useMediaQuery } from 'react-responsive';
import { useModal } from '../../hooks/use-modal.hook';

const OverrideDateSchema = Yup.object().shape({
  override_fulfillment_date: Yup.date().required('This field is required'),
});

export const DelayOrder = ({
  order,
  overrideDate,
  product,
  block,
  outline,
  className,
  delayOrderText = 'Delay',
  delayOrderButtonText,
}) => {
  const modalProps = useModal();
  const [isOpen, rawToggle] = useToggle();
  const [finished, toggleFinished, setFinished] = useToggle();
  const isMobile = useMediaQuery({ maxDeviceWidth: 1180 });

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const fullFillmentDate = order.override_fulfillment_date ? utcDate(order.override_fulfillment_date) : tomorrow;

  const excludedDates = useMemo(() => {
    if (product.product && product.product.exclude_fulfilment_dates) {
      return product.product.exclude_fulfilment_dates.map(date => utcDate(date));
    } else if (product.exclude_fulfilment_dates) {
      return product.exclude_fulfilment_dates.map(date => utcDate(date));
    } else if (order.exclude_fulfilment_dates) {
      return order.exclude_fulfilment_dates.map(date => utcDate(date));
    }
    return [];
  }, [order, product]);

  const toggle = useCallback(() => {
    rawToggle();

    setTimeout(() => {
      setFinished(false);
    }, [300]);
  }, [rawToggle, setFinished]);

  const onSubmit = useCallback((...data) => overrideDate(order._id, ...data), [order, overrideDate]);

  return (
    <>
      <Button color="primary" block={block} outline={outline} onClick={toggle} className={className}>
        {delayOrderText}
      </Button>
      <Modal isOpen={isOpen} size="md" toggle={toggle} {...modalProps}>
        <ModalHeader toggle={toggle}>{finished ? 'Order Scheduled' : 'Choose New Fulfillment Date'}</ModalHeader>
        <ModalBody className="text-center">
          {!finished && (
            <Form
              initialValues={{ override_fulfillment_date: fullFillmentDate }}
              validationSchema={OverrideDateSchema}
              onSubmit={onSubmit}
              onSuccess={toggleFinished}
              className="mw-300"
            >
              {({ isSubmitting }) => (
                <>
                  <Field
                    type="date"
                    name="override_fulfillment_date"
                    min={tomorrow}
                    max={new Date(Date.now() + 56 * 86400000)}
                    excludeDates={excludedDates}
                    excludeWeekends={true}
                    inline={isMobile}
                  />
                  <div className="mb-3">
                    <SubmitButton size="default" block isSubmitting={isSubmitting}>
                      {delayOrderButtonText || delayOrderText}
                    </SubmitButton>
                  </div>
                </>
              )}
            </Form>
          )}
          {finished && (
            <div>
              Your order will be scheduled for fulfillment on{' '}
              <DateFormat date={order.override_fulfillment_date} correctTimezone />.
            </div>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

DelayOrder.propTypes = {
  order: PropTypes.object.isRequired,
  overrideDate: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
  delayOrderText: PropTypes.string,
  delayOrderButtonText: PropTypes.string,
  block: PropTypes.bool,
  outline: PropTypes.bool,
  className: PropTypes.string,
};
