import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { DateFormat, Form, SubmitButton } from '@bottomless/common/components';
import { ScaleReturn } from '../../../../components/ScaleReturn';
import { READABLE_FORMAT } from '../../../../utils/dates';
import { STEPS } from './steps.constants';
import { useIsSimpleSubscription } from '../../../../hooks/use-is-simple-subscribtion.hook';
import { SubscriptionType } from '@bottomless/common/constants';

export const StepSubscriptionUpdated = ({
  me,
  originalMe,
  setNextStep,
  requestScaleReturn,
  getNextOrderDateFromNow,
  setDumbPeriod,
}) => {
  const isSimpleSubscription = useIsSimpleSubscription();
  const isPrepaidSubscription = useMemo(() => originalMe.subscriptionType === SubscriptionType.Prepaid, [originalMe]);
  const userCouldHaveScale = useMemo(() => !isPrepaidSubscription && (!isSimpleSubscription || me.base_id), [
    isPrepaidSubscription,
    isSimpleSubscription,
    me,
  ]);

  const period = useMemo(() => (!me.dumb_period ? '---' : me.dumb_period / 7), [me]);

  const toggle = useCallback(() => setNextStep(), [setNextStep]);
  const onScaleReturnSuccess = useCallback(() => setNextStep(STEPS.ReturnLabelRequested), [setNextStep]);
  const onUndoSuccess = useCallback(() => setNextStep(STEPS.Undone), [setNextStep]);

  return (
    <>
      <div className="d-flex justify-content-between mt-3 mb-4">
        <div className="font-weight-bolder order-heading">Frequency</div>
        <div>
          {period} week{period > 1 && 's'}
        </div>
      </div>
      <div className="d-flex justify-content-between mt-3 mb-4">
        <div className="font-weight-bolder order-heading">Scheduled fulfillment</div>
        <div>
          <DateFormat date={getNextOrderDateFromNow(me.dumb_period)} format={READABLE_FORMAT} />
        </div>
      </div>
      <Form
        initialValues={{ dumb_period: null }}
        onSubmit={setDumbPeriod}
        onSuccess={onUndoSuccess}
        className="mw-300 form-fixed-period"
      >
        {({ isSubmitting }) => (
          <>
            <div className="mb-4 mt-5">
              <SubmitButton block size="default" outline isSubmitting={isSubmitting}>
                Undo Switch
              </SubmitButton>
            </div>
          </>
        )}
      </Form>
      {userCouldHaveScale && (
        <>
          <hr className="my-5" />
          <ScaleReturn toggle={toggle} onSuccess={onScaleReturnSuccess} requestScaleReturn={requestScaleReturn} />
        </>
      )}
    </>
  );
};

StepSubscriptionUpdated.headingText = 'Your subscription has been updated';

StepSubscriptionUpdated.propTypes = {
  me: PropTypes.shape({
    dumb_period: PropTypes.number,
    base_id: PropTypes.string,
  }).isRequired,
  originalMe: PropTypes.shape({
    subscriptionType: PropTypes.string,
  }).isRequired,
  setNextStep: PropTypes.func.isRequired,
  requestScaleReturn: PropTypes.func.isRequired,
  getNextOrderDateFromNow: PropTypes.func.isRequired,
  setDumbPeriod: PropTypes.func.isRequired,
};
