import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { OrderShippingStatuses, OrderStatuses } from '@bottomless/common/constants';
import { DateFormat } from '@bottomless/common/components';
import moment from 'moment';
import { useStatus } from './order-status.hook';
import { READABLE_FORMAT } from '../../utils/dates';
import { TrackingHistory } from '../TrackingHistory';
import './OrderTracking.scss';

const AVAILABLE_STATUSES = {
  Scheduled: 'scheduled',
  Fulfilling: 'fulfilling',
  InTransit: 'in_transit',
  OutOfDelivery: 'out_for_delivery',
  Delivered: 'delivered',
};

export const OrderTracking = ({
  order,
  expectedDate: expectedDateRaw,
  withHeading = true,
  showScheduledFulfillment,
}) => {
  const status = useStatus({ order });

  const estimatedDelivery = useMemo(() => {
    if (!order?.tracking_updates?.length) {
      return;
    }
    const rawDate = order.tracking_updates[order.tracking_updates.length - 1].est_delivery_date;
    if (!rawDate) {
      return;
    }
    if (moment(rawDate).startOf('day') < moment().startOf('day')) {
      return;
    }
    return rawDate;
  }, [order]);

  const expectedDate = useMemo(() => {
    if (!expectedDateRaw) {
      return;
    }
    if (moment(expectedDateRaw).startOf('day') < moment().startOf('day')) {
      return;
    }
    return expectedDateRaw;
  }, [expectedDateRaw]);

  const getSelectedClass = useCallback(
    (status, expectedStatus) => (status === expectedStatus ? 'status-selected' : null),
    []
  );

  const getProgressSelectedClass = useCallback((status, expectedStatus) => {
    const statusValues = Object.values(AVAILABLE_STATUSES);
    return statusValues.indexOf(status) >= statusValues.indexOf(expectedStatus) ? 'status-dark' : null;
  }, []);

  if (order.status === OrderStatuses.Cancelled) {
    return <div className="text-center text-large text-danger">Order is cancelled</div>;
  }

  if (order.status === OrderStatuses.Refunded) {
    return <div className="text-center text-large text-danger">Order is refunded</div>;
  }

  return (
    <>
      <div>
        {withHeading && <div className="font-weight-bolder order-heading mb-3">Order Status</div>}
        <div className="pt-1 pb-2">
          <div className="transition-slider" data-status={status}>
            {Object.values(AVAILABLE_STATUSES).map(availableStatus => (
              <div
                key={availableStatus}
                className={classNames(
                  'transition-slider-progress',
                  getSelectedClass(status, availableStatus),
                  getProgressSelectedClass(status, availableStatus)
                )}
                data-status={availableStatus}
              />
            ))}
          </div>
          <div className="d-flex text-center transition-slider-status mt-4 mb-2">
            <div className={classNames('status-label px-2', getSelectedClass(status, AVAILABLE_STATUSES.Scheduled))}>
              Created
            </div>
            <div className={classNames('status-label px-2', getSelectedClass(status, AVAILABLE_STATUSES.Fulfilling))}>
              Fulfilling
            </div>
            <div className={classNames('status-label px-2', getSelectedClass(status, AVAILABLE_STATUSES.InTransit))}>
              In Transit
            </div>
            <div
              className={classNames('status-label px-2', getSelectedClass(status, AVAILABLE_STATUSES.OutOfDelivery))}
            >
              Out for delivery
            </div>
            <div className={classNames('status-label px-2', getSelectedClass(status, AVAILABLE_STATUSES.Delivered))}>
              Delivered
            </div>
          </div>
        </div>
      </div>
      {order.shipping_status !== OrderShippingStatuses.Delivered &&
        (estimatedDelivery || (expectedDate && order.status !== OrderStatuses.Initiated)) && (
          <div className="d-flex justify-content-between">
            <div className="font-weight-bolder order-heading">Estimated Delivery</div>
            <div>
              <DateFormat
                date={estimatedDelivery || expectedDate}
                format={READABLE_FORMAT}
                fromZeroed={!!estimatedDelivery}
              />
            </div>
          </div>
        )}
      {!showScheduledFulfillment && <TrackingHistory order={order} />}
    </>
  );
};

OrderTracking.propTypes = {
  order: PropTypes.object,
  expectedDate: PropTypes.string,
  withHeading: PropTypes.bool,
  showScheduledFulfillment: PropTypes.bool,
};
