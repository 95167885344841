import React from 'react';
import PropTypes from 'prop-types';

export const ErrorMessage = ({ error }) => {
  if (!error?.message) {
    return null;
  }

  return (
    <div
      className="text-danger text-center small mt-4"
      dangerouslySetInnerHTML={{ __html: error.message.replace('. ', '.<br />') }}
    ></div>
  );
};

ErrorMessage.propTypes = {
  error: PropTypes.object,
};
