import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { useState } from 'react';
import { useCallback } from 'react';
import { useEffect } from 'react';

export const ChangeAmount = ({ lineItemId, amount: initialAmount, onChange, onlyOneItem, product, isRotation }) => {
  const [amount, setAmount] = useState(initialAmount);

  useEffect(() => {
    if (!amount) {
      setAmount(initialAmount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  const onMinusClick = useCallback(
    () =>
      setAmount(value => {
        const newValue = value - 1;
        onChange(lineItemId, newValue);
        return newValue;
      }),
    [lineItemId, onChange]
  );

  const onPlusClick = useCallback(
    () =>
      setAmount(value => {
        const newValue = value + 1;
        onChange(lineItemId, newValue);
        return newValue;
      }),
    [lineItemId, onChange]
  );

  return (
    <div className="d-flex align-items-center buttons-amount">
      <Button color="text" disabled={amount <= (onlyOneItem ? 1 : 0)} onClick={onMinusClick}>
        -
      </Button>
      <div className="px-2">{amount}</div>
      <Button color="text" onClick={onPlusClick} disabled={isRotation || amount >= 100}>
        +
      </Button>
    </div>
  );
};

ChangeAmount.propTypes = {
  lineItemId: PropTypes.string,
  amount: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onlyOneItem: PropTypes.bool,
  product: PropTypes.string,
  isRotation: PropTypes.bool,
};
