import React from 'react';
import PropTypes from 'prop-types';
import { Field, SubmitButton } from '@bottomless/common/components';
import { SwitchToSubscriptionByUsage } from '../../../../components/SwitchToSubscriptionByUsage';

export const Step1 = ({ options, dumbPeriod, isSubmitting, onSubmitClick, otherOptionsHidden }) => {
  return (
    <div>
      {!otherOptionsHidden && (
        <div className="mw-325">
          <div className="mb-3">You&apos;ve selected to switch to</div>
          <Field name="dumb_period" type="select" options={options} />
        </div>
      )}
      {!dumbPeriod && (
        <SwitchToSubscriptionByUsage
          title={otherOptionsHidden && 'Switch to Deliver Based On Your Usage'}
          place="profile-frequency-form"
          onlyConfirmation
          isSubmitting={isSubmitting}
          onConfirmClick={onSubmitClick}
        />
      )}

      {!!dumbPeriod && (
        <div className="mw-325">
          <SubmitButton block size="default" isSubmitting={isSubmitting} onClick={onSubmitClick}>
            Confirm Frequency Change
          </SubmitButton>
        </div>
      )}
    </div>
  );
};

Step1.headingText = 'Select Frequency';

Step1.propTypes = {
  me: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
  dumbPeriod: PropTypes.number,
  isSubmitting: PropTypes.bool,
  onSubmitClick: PropTypes.func.isRequired,
  otherOptionsHidden: PropTypes.bool,
};
