import { RSAA } from 'redux-api-middleware';
import qs from 'query-string';
import { createAction } from '@bottomless/common/store';

export const SubmitOrderRateTypes = createAction('Rate', 'Submit order rate');

export const submitOrderRateAction = (id, data) => ({
  [RSAA]: {
    endpoint: `/apps/bottomless/rate/${id}`,
    method: 'POST',
    body: data,
    types: [SubmitOrderRateTypes.REQUEST, SubmitOrderRateTypes.SUCCESS, SubmitOrderRateTypes.FAILURE],
  },
});

export const SubmitOrderNoteTypes = createAction('Rate', 'Submit order note');

export const submitOrderNoteAction = (id, data) => ({
  [RSAA]: {
    endpoint: `/apps/bottomless/rate/${id}/note`,
    method: 'POST',
    body: data,
    types: [SubmitOrderNoteTypes.REQUEST, SubmitOrderNoteTypes.SUCCESS, SubmitOrderNoteTypes.FAILURE],
  },
});

export const SendFeedbackTypes = createAction('Rate', 'Send feedback');

export const sendFeedbackAction = (id, data) => ({
  [RSAA]: {
    endpoint: `/apps/bottomless/rate/${id}/product-feedback`,
    method: 'POST',
    body: data,
    types: [SendFeedbackTypes.REQUEST, SendFeedbackTypes.SUCCESS, SendFeedbackTypes.FAILURE],
  },
});

export const OrderRateTypes = createAction('Rate', 'Order rate');

export const orderRateAction = (id, params) => ({
  [RSAA]: {
    endpoint: `/apps/bottomless/rate/${id}${params ? `?${qs.stringify(params)}` : ''}`,
    method: 'GET',
    types: [OrderRateTypes.REQUEST, OrderRateTypes.SUCCESS, OrderRateTypes.FAILURE],
  },
});
