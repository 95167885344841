import { DataHandler, DataHandlerContent } from '@bottomless/common/components';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'query-string';
import { Button } from 'reactstrap';
import { Heading } from '../../components/Heading';
import { PanelLayout } from '../../layouts/PanelLayout';
import { useMe } from './hooks';

export const UpdateShopifyPaymentMethodPage = () => {
  const location = useLocation();
  const history = useHistory();
  const queryParams = qs.parse(location.search);
  const me = useMe();
  const [isEmailAlreadySent, setEmailAlreadySent] = useState(queryParams.sent);
  const [isSendingEmail, setSendingEmail] = useState();
  const [isEmailResent, setEmailResent] = useState(false);

  const sendPaymentUpdateEmail = useCallback(async () => {
    setSendingEmail(true);
    setEmailResent(false);
    await me.sendPaymentUpdateEmail();
    history.replace({ pathname: location.pathname, search: `?${new URLSearchParams({ sent: true })}` });
    setSendingEmail(false);
    if (isEmailAlreadySent) {
      setEmailResent(true);
    }
    setEmailAlreadySent(true);
  }, [history, isEmailAlreadySent, location.pathname, me]);

  useEffect(() => {
    if (!me.data?.shopifySubscriptionContractId) {
      history.push('/account?payment_update=true');
    } else if (!isEmailAlreadySent && me?.sendPaymentUpdateEmail) {
      sendPaymentUpdateEmail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PanelLayout>
      <div className="page page-order">
        <Heading back="/">Link Sent</Heading>
        <DataHandler data={isEmailAlreadySent} isLoading={!isEmailAlreadySent} />
        <DataHandlerContent data={isEmailAlreadySent}>
          <div className="mt-5 text-center">
            <div className="text-md mb-3">
              We sent you secure link via email. Use it to update your payment details.
            </div>
            <Button color="primary" disabled={isSendingEmail} onClick={sendPaymentUpdateEmail}>
              Resend
            </Button>
            {isEmailResent && <div className="text-success mt-1">Link resent</div>}
          </div>
        </DataHandlerContent>
      </div>
    </PanelLayout>
  );
};
