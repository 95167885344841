import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useToggle } from '@bottomless/common/hooks';
import { Field, Form, SubmitButton } from '@bottomless/common/components';
import { useModal } from '../../../hooks/use-modal.hook';

const Schema = Yup.object().shape({
  reason: Yup.string().required('This field is required'),
  comment: Yup.string(),
});

const problemOptions = {
  '': '-----------------',
  order_stuck: 'Order was not delivered',
  wrong_product: 'The product is not what I ordered',
  broken_product: 'There is something wrong with the product',
  wrong_status: 'Order marked as delivered but was not',
};

export const ReportProblem = ({ order, reportProblem }) => {
  const modalProps = useModal();
  const [isOpen, toggle] = useToggle();
  const disabled = useMemo(() => !!order.reportProblem?.reason, [order]);

  return (
    <>
      <Button color="primary" outline className="mr-3 mb-3" onClick={toggle}>
        Report Problem
      </Button>
      <Modal isOpen={isOpen} toggle={toggle} {...modalProps}>
        <ModalHeader toggle={toggle}>Report a problem</ModalHeader>
        <ModalBody>
          <Form
            initialValues={{ reason: order.reportProblem?.reason || '', comment: order.reportProblem?.comment || '' }}
            validationSchema={Schema}
            onSubmit={reportProblem}
            onSuccess={toggle}
          >
            {({ isSubmitting, values }) => (
              <>
                <Field
                  name="reason"
                  label="Report problem with order"
                  type="select"
                  disabled={disabled}
                  options={problemOptions}
                />
                {values.reason && <Field name="comment" label="Add a comment" type="text" disabled={disabled} />}
                <div className="text-center">
                  <SubmitButton
                    color="primary"
                    isSubmitting={isSubmitting}
                    loadingText="Reporting"
                    className="mt-1"
                    disabled={disabled}
                  >
                    Report
                  </SubmitButton>
                </div>
              </>
            )}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

ReportProblem.propTypes = {
  order: PropTypes.object.isRequired,
  reportProblem: PropTypes.func.isRequired,
};
