import React, { useCallback, useMemo } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { SubmitButton, Form, Field, DateFormat, Box } from '@bottomless/common/components';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useToggle } from '@bottomless/common/hooks';
import { READABLE_FORMAT } from '../../../../utils/dates';
import { Edit } from 'react-feather';
import { useModal } from '../../../../hooks/use-modal.hook';
import { utcDate } from '@bottomless/common/utils';

const Schema = Yup.object().shape({
  pausedUntil: Yup.date().required('This field is required'),
});

export const EditPausedUntil = ({ me, onSubmit }) => {
  const modalProps = useModal();
  const [isOpen, rawToggle] = useToggle();
  const [finished, toggleFinished, setFinished] = useToggle();

  const handleSubmit = useCallback(
    data => {
      const pausedUntil = data.pausedUntil ? moment(data.pausedUntil).format('YYYY-MM-DD') : null;

      return onSubmit({ ...data, pausedUntil });
    },
    [onSubmit]
  );

  const { now, nextYear } = useMemo(() => {
    const now = new Date();

    const nextYear = new Date();
    nextYear.setMonth(nextYear.getMonth() + 12);

    return { now, nextYear };
  }, []);

  const toggle = useCallback(() => {
    rawToggle();

    setTimeout(() => {
      setFinished(false);
    }, [300]);
  }, [rawToggle, setFinished]);

  return (
    <>
      <Box className="mb-3 box-no-arrow" onClick={toggle}>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <DateFormat date={me.pausedUntil} format={READABLE_FORMAT} fromZeroed />
          </div>
          <div className="pl-2 pl-md-3 text-secondary">
            <Edit width="20" height="20" strokeWidth="1.5" />
          </div>
        </div>
      </Box>
      <Modal isOpen={isOpen} size="md" toggle={toggle} {...modalProps}>
        <ModalHeader toggle={toggle} className="text-capitalize">
          {finished ? 'Date changed' : 'Resume automatic re-ordering on'}
        </ModalHeader>
        <ModalBody>
          {!finished && (
            <Form
              initialValues={{ paused: true, pausedUntil: me.pausedUntil ? utcDate(me.pausedUntil) : null }}
              validationSchema={Schema}
              onSubmit={handleSubmit}
              onSuccess={toggleFinished}
              className="mw-300"
            >
              {({ isSubmitting }) => (
                <>
                  <Field name="pausedUntil" type="date" min={now} max={nextYear} />
                  <SubmitButton block size="default" isSubmitting={isSubmitting}>
                    Save
                  </SubmitButton>
                </>
              )}
            </Form>
          )}
          {finished && (
            <div>
              Automatic re-ordering will resume on <DateFormat date={me.pausedUntil} fromZeroed />.
            </div>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

EditPausedUntil.propTypes = {
  me: PropTypes.shape({
    paused: PropTypes.bool,
    pausedUntil: PropTypes.string,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
};
