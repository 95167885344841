import { EmptyState } from '@bottomless/common/components';
import 'chartjs-plugin-annotation';
import PropTypes from 'prop-types';
import React from 'react';
import { Line } from 'react-chartjs-2';
import { useAppContainer } from '../../../hooks/use-app-container.hook';

export const Graph = ({ stats, datasets, options }) => {
  const container = useAppContainer();
  const style = getComputedStyle(container);
  const height = window.innerWidth > 772 ? 80 : 150;

  const data = stats
    .filter(record => record.adj_weight > -10 && record.adj_weight < 960) // 60 pounds limit
    .map(record => ({
      x: new Date(record.timestamp),
      y: record.adj_weight.toFixed(1),
    }));

  const defaults = {
    legend: { display: false },
    scales: {
      xAxes: [{ type: 'time', time: { unit: 'day' }, gridLines: { display: false } }],
      yAxes: [{ ticks: { callback: item => `${item} oz`, suggestedMax: 12, suggestedMin: 0 } }],
    },
    tooltips: { callbacks: { title: () => null } },
    elements: { line: { tension: 0 } }, // Bezier Curve parameter,
  };

  return (
    <div className="pofile-graph position-relative">
      {!stats.length && (
        <div className="profile-graph-empty-state position-absolute d-flex align-items-center justify-content-center p-5">
          <div className="flex-grow-0 mt-n4 ml-4">
            <EmptyState title="No data yet" description="It will be available after your scale sends more data" />
          </div>
        </div>
      )}
      <Line
        data={{
          datasets: [
            {
              data,
              pointRadius: 1,
              borderColor: style.getPropertyValue('--primary') || '#000000',
              backgroundColor: '#f5f7fa',
              ...(datasets.length > 0
                ? {
                    label: 'Weight',
                  }
                : {}),
            },
            ...datasets,
          ],
        }}
        options={{ ...defaults, ...options }}
        height={height}
      />
    </div>
  );
};

Graph.defaultProps = {
  datasets: [],
  options: {},
};

Graph.propTypes = {
  stats: PropTypes.array.isRequired,
  datasets: PropTypes.array,
  options: PropTypes.object,
};
