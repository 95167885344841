import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { Order } from './Order';
import { Box } from '@bottomless/common/components';

export const Orders = ({ orders, user }) => (
  <Row>
    {orders.map(order => (
      <Col key={order._id} xs="12" md="6" className="mb-4 d-flex">
        <Box
          to={`/order/${order._id}/status?back=/orders`}
          className="position-relative flex-grow-1 box-order"
          wrapperClassName="d-flex flex-grow-1"
        >
          <Order order={order} user={user} />
        </Box>
      </Col>
    ))}
  </Row>
);

Orders.propTypes = {
  orders: PropTypes.array.isRequired,
  user: PropTypes.object,
};
