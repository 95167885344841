import React from 'react';
import { UserCategoryManifest } from '../../../components/CategoryManifest';
import { CoffeeDetailedGraphs, GenericDetailedGraphs } from '../manifest';

const COMPONENTS = {
  CoffeeDetailedGraphs: CoffeeDetailedGraphs,
  GenericDetailedGraphs: GenericDetailedGraphs,
};

export const DetailedGraphs = props => (
  <UserCategoryManifest
    manifestKey="dataDetailedGraphs"
    components={COMPONENTS}
    fallbackComponent={GenericDetailedGraphs}
    {...props}
  />
);
