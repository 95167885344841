import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { Box, ScaleStatus } from '@bottomless/common/components';
import { UpdateOrderingStrategy } from './Actions/UpdateOrderingStrategy';
import { useLocalStorage } from '../../../hooks/use-local-storage.hook';
import { useAppContainer } from '../../../hooks/use-app-container.hook';

export const YourScale = ({ me, stats, status, cleanData, setOrderingAggression }) => {
  const [{ bottomless_access_token: authToken }] = useLocalStorage(['bottomless_access_token']);
  const container = useAppContainer();
  const style = getComputedStyle(container);

  return (
    <div className="box-no-arrow">
      <div className="mb-3">
        <h5>Current Status</h5>
        <ScaleStatus
          me={me}
          stats={stats}
          status={status}
          borderColor={style.getPropertyValue('--primary')}
          backgroundColor="#f5f7fa"
          noScaleImage
        />
      </div>
      <Row>
        <Col xs={cleanData?.data?.adjusted_weight ? 6 : 12} className="mb-3 d-flex flex-column">
          <h5>Ordering Strategy</h5>
          <UpdateOrderingStrategy me={me} onSubmit={setOrderingAggression} />
        </Col>
        {cleanData?.data?.adjusted_weight && (
          <Col xs="6" className="mb-3 d-flex flex-column box-heatmap">
            <h5>Usage Patterns</h5>
            <Box size="sm" innerClassName="h-100" to="/data">
              <img
                height="70"
                className="img-fluid image-heatmap"
                src={`/apps/bottomless/users/heatmap?authorization=${authToken}`}
                alt="Graph"
              />
            </Box>
          </Col>
        )}
      </Row>
    </div>
  );
};

YourScale.propTypes = {
  me: PropTypes.object.isRequired,
  setOrderingAggression: PropTypes.func.isRequired,
  stats: PropTypes.array.isRequired,
  status: PropTypes.object,
  cleanData: PropTypes.shape({
    data: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        adjusted_weight: PropTypes.object,
      }),
    ]),
  }),
};
