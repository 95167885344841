import { useDataEffect } from '@bottomless/common/hooks';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  closeAccountAction,
  getMeAction,
  pauseAccountAction,
  sendPaymentUpdateEmailAction,
  setDumbPeriodAction,
  verifyAddressAction,
  verifyStripeAction,
} from '../../../store/user';
import { requestScaleReturnAction } from '../../../store/scale';
import { getUpcomingOrderAction } from '../../../store/order';

export const useMe = () => {
  const dispatch = useDispatch();
  const action = useCallback(() => dispatch(getMeAction()), [dispatch]);
  const { data } = useSelector(({ user }) => ({ data: user.me }));

  const { error, isFetching: isLoading } = useDataEffect(action);

  const verifyAddress = useCallback(data => dispatch(verifyAddressAction(data)), [dispatch]);
  const verifyStripe = useCallback(data => dispatch(verifyStripeAction(data)), [dispatch]);
  const pauseAccount = useCallback(data => dispatch(pauseAccountAction(data)), [dispatch]);
  const requestScaleReturn = useCallback(() => dispatch(requestScaleReturnAction()), [dispatch]);
  const sendPaymentUpdateEmail = useCallback(data => dispatch(sendPaymentUpdateEmailAction(data)), [dispatch]);

  const closeAccount = useCallback(
    async data => {
      const result = await dispatch(closeAccountAction(data));

      await dispatch(getMeAction());

      return result;
    },
    [dispatch]
  );

  const setDumbPeriod = useCallback(
    async (data, place) => {
      const result = await dispatch(setDumbPeriodAction(data, place));

      await dispatch(getUpcomingOrderAction());

      return result;
    },
    [dispatch]
  );

  return {
    data,
    isLoading,
    error,
    verifyAddress,
    verifyStripe,
    pauseAccount,
    closeAccount,
    requestScaleReturn,
    setDumbPeriod,
    sendPaymentUpdateEmail,
  };
};
