import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { Box } from '@bottomless/common/components';

export const ShopifyFixedSubscriptionPaymentDetails = ({ me, onSubmit }) => {
  const [error, setError] = useState(null);
  const [isProcessing, setProcessing] = useState(false);
  const [linkSent, setLinkSent] = useState(false);

  const onClick = useCallback(async () => {
    try {
      setError('');
      setLinkSent(false);
      setProcessing(true);
      await onSubmit();
      setProcessing(false);
      setLinkSent(true);
    } catch (e) {
      setProcessing(false);
      setError(e.message);
    }
  }, [onSubmit]);

  return (
    <>
      <Box innerClassName="d-flex flex-column">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <div className="font-weight-bold">
              {me.first_name} {me.last_name}
            </div>
            <div>XXXX XXXX XXXX {me.stripe_last_four || 'XXXX'}</div>
            {me.stripe_brand && <div className="text-secondary">{me.stripe_brand}</div>}
          </div>
          <Button color="primary" size="sm" onClick={onClick} disabled={isProcessing}>
            Edit
          </Button>
        </div>
        {linkSent && (
          <div className="text-success mt-1">
            We sent you secure link via email. Use it to update your payment details.
          </div>
        )}
        {error && <div className="text-danger mt-1">{error}</div>}
      </Box>
    </>
  );
};

ShopifyFixedSubscriptionPaymentDetails.propTypes = {
  me: PropTypes.shape({
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    stripe_last_four: PropTypes.string,
    stripe_brand: PropTypes.string,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
};
