import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useConditionalDataEffect } from '@bottomless/common/hooks';
import { getProductsAction } from '../../../store/product/product.actions';
import { useExecute } from '../../../hooks/use-execute.hook';
import {
  addLineItemAction,
  getMeAction,
  makeBottomlessProductAction,
  updateLineItemAction,
} from '../../../store/user/user.actions';

export const useProducts = ({ me }) => {
  const { execute, counter } = useExecute();
  const dispatch = useDispatch();
  const action = useCallback(
    () => dispatch(getProductsAction({ vendorId: me.vendor_id._id, category: me.product.product.category?.slug })),
    [me.vendor_id, me.product.product.category?.slug, dispatch]
  );
  const { data } = useSelector(({ product }) => ({ data: product.data || [] }));

  const { error, isFetching: isLoading } = useConditionalDataEffect(counter, action);

  return { data, isLoading, error, execute };
};

export const useMakeBottomless = () => {
  const dispatch = useDispatch();

  const action = useCallback(
    async (id, data) => {
      const result = await dispatch(makeBottomlessProductAction(id, data));
      await dispatch(getMeAction());

      return result;
    },
    [dispatch]
  );

  return action;
};

export const useAddLineItems = () => {
  const dispatch = useDispatch();

  const action = useCallback(
    async data => {
      const result = await dispatch(addLineItemAction(data));
      await dispatch(getMeAction());

      return result;
    },
    [dispatch]
  );

  return action;
};

export const useUpdateLineItems = () => {
  const dispatch = useDispatch();

  const action = useCallback(
    async data => {
      const { id, isDelete, ...body } = data;
      if (isDelete) {
        body.quantity = 0;
      }
      const result = await dispatch(updateLineItemAction(id, body));
      await dispatch(getMeAction());

      return result;
    },
    [dispatch]
  );

  return action;
};
