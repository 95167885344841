import { useDataEffect } from '@bottomless/common/hooks';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAlertSettingsAction,
  setAlertSettingsAction,
  textAlertsAction,
  verifyPhoneAction,
} from '../../../store/user';

export const useAlertSettings = () => {
  const dispatch = useDispatch();
  const action = useCallback(() => dispatch(getAlertSettingsAction()), [dispatch]);
  const { data } = useSelector(({ user }) => ({ data: user.alertSettings }));

  const { error, isFetching: isLoading } = useDataEffect(action);

  const setAlertSettings = useCallback(data => dispatch(setAlertSettingsAction(data)), [dispatch]);
  const textAlerts = useCallback(data => dispatch(textAlertsAction(data)), [dispatch]);
  const verifyPhone = useCallback(
    async data => {
      const response = await dispatch(verifyPhoneAction(data));

      await dispatch(getAlertSettingsAction());

      return response;
    },
    [dispatch]
  );

  return { data, isLoading, error, setAlertSettings, textAlerts, verifyPhone };
};
