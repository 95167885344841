import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, DateFormat } from '@bottomless/common/components';
import { OrderSources, OrderStatuses, SubscriptionType } from '@bottomless/common/constants';
import { Badge, Col, Row } from 'reactstrap';
import { OrderTracking } from '../../../components/OrderTracking';
import { CancelOrder } from './Actions/CancelOrder';
import { READABLE_FORMAT } from '../../../utils/dates';
import { DelayOrder } from '../../../components/DelayOrder/DelayOrder';
import { AddressDetails } from './AddressDetails';
import { OrderProduct } from './OrderProduct';

import './Order.scss';

const CANCELLABLE_SOURCES = [
  OrderSources.USER,
  OrderSources.DUMB_SUBSCRIPTION,
  OrderSources.USER_ONE_OFF,
  OrderSources.OPERATIONS,
];

const CANCELLABLE_STATUSES = [OrderStatuses.Initiated, OrderStatuses.SubproductGenerated];

const DELAYABLE_STATUSES = [OrderStatuses.Initiated, OrderStatuses.SubproductGenerated, OrderStatuses.Paid];

export const Order = ({
  order,
  me,
  queue,
  cancelOrder,
  overrideDate,
  expectedDate,
  delayOrderText,
  getNextOrderDateFromNow,
  index,
  verifyAddress,
}) => {
  const isOrderOneOff = useMemo(() => order.source === OrderSources.USER_ONE_OFF, [order]);
  const isCancellable = useMemo(
    () =>
      (order.source === OrderSources.USER_ONE_OFF && CANCELLABLE_STATUSES.includes(order.status)) ||
      (!me?.dumb_period && CANCELLABLE_SOURCES.includes(order.source) && CANCELLABLE_STATUSES.includes(order.status)),
    [me, order]
  );
  const isDelayable = useMemo(
    () => me?.subscriptionType !== SubscriptionType.Prepaid && DELAYABLE_STATUSES.includes(order.status),
    [order, me]
  );

  const orderProduct = useMemo(() => {
    if (order.subproduct_id?.product) {
      return order.subproduct_id;
    }

    if (order.source === OrderSources.USER_ONE_OFF && order.product_id) {
      return order.product_id;
    }

    return queue?.length > index ? queue[index] : me.product;
  }, [order, me, queue, index]);

  const orderQuantity = useMemo(
    () => (!isOrderOneOff && (!order._id || isCancellable) ? me?.quantity || 1 : order.quantity),
    [isOrderOneOff, me, order, isCancellable]
  );

  const onCancel = useCallback(() => cancelOrder(order._id), [order, cancelOrder]);

  const showScheduledFulfillment = useMemo(() => {
    if (me?.dumb_period) {
      return !order.tracking_number;
    }

    return !order.date_fulfilled && order.override_fulfillment_date;
  }, [me, order]);

  return (
    <Box className="position-relative box-order">
      {isOrderOneOff && (
        <Badge color="primary" pill className="badge-one-off">
          One-Off
        </Badge>
      )}
      <OrderProduct
        me={me}
        order={order}
        orderProduct={orderProduct}
        isOrderOneOff={isOrderOneOff}
        orderQuantity={orderQuantity}
      />
      <OrderTracking order={order} expectedDate={expectedDate} showScheduledFulfillment={showScheduledFulfillment} />
      {showScheduledFulfillment && (
        <div className="d-flex justify-content-between mt-4">
          <div className="font-weight-bolder order-heading">Scheduled fulfillment</div>
          <div>
            <DateFormat
              date={order.override_fulfillment_date || (getNextOrderDateFromNow && getNextOrderDateFromNow())}
              correctTimezone={!!order._id}
              format={READABLE_FORMAT}
            />
          </div>
        </div>
      )}
      <AddressDetails order={order} me={me} verifyAddress={verifyAddress} />
      <Row>
        {isDelayable && overrideDate && (
          <Col className="mt-4 order-action-btn-wrapper">
            <DelayOrder
              overrideDate={overrideDate}
              order={order}
              product={orderProduct}
              delayOrderText={delayOrderText}
              block
              outline
            />
          </Col>
        )}
        {isCancellable && cancelOrder && (
          <Col className="mt-4 order-action-btn-wrapper">
            <CancelOrder onSubmit={onCancel} />
          </Col>
        )}
      </Row>
    </Box>
  );
};

Order.propTypes = {
  order: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    source: PropTypes.string,
    subproduct_id: PropTypes.shape({
      product: PropTypes.object.isRequired,
      variant: PropTypes.string.isRequired,
    }),
    product_id: PropTypes.shape({
      product: PropTypes.object.isRequired,
      variant: PropTypes.string.isRequired,
    }),
    status: PropTypes.string.isRequired,
    override_fulfillment_date: PropTypes.string,
    date_fulfilled: PropTypes.string,
    tracking_number: PropTypes.string,
    quantity: PropTypes.number,
  }).isRequired,
  me: PropTypes.shape({
    product: PropTypes.shape({
      product: PropTypes.object.isRequired,
      variant: PropTypes.string.isRequired,
    }).isRequired,
    dumb_period: PropTypes.number,
    quantity: PropTypes.number,
    subscriptionType: PropTypes.string,
  }),
  queue: PropTypes.array,
  expectedDate: PropTypes.string,
  cancelOrder: PropTypes.func,
  overrideDate: PropTypes.func,
  delayOrderText: PropTypes.text,
  getNextOrderDateFromNow: PropTypes.func,
  index: PropTypes.number,
  verifyAddress: PropTypes.func.isRequired,
};
