import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Field, Image, SubmitButton, VariantPrice } from '@bottomless/common/components';
import { fromOz } from '@bottomless/common/src/utils';
import { Col, Row } from 'reactstrap';
import { X } from 'react-feather';
import { useEffect } from 'react';

export const GenericChosenProduct = ({ product, variant: variantId, isSubmitting, setFieldValue, onClear, user }) => {
  const variantOptions = useMemo(
    () =>
      product.variants.reduce(
        (all, variant) => ({ ...all, [variant._id]: fromOz({ oz: variant.size, unit: variant.unit }).formatted }),
        {}
      ),
    [product]
  );

  const selectedVariant = useMemo(() => product.variants.find(v => v._id === variantId), [variantId, product]);

  useEffect(() => {
    setFieldValue('grind', undefined);
  }, [setFieldValue]);

  return (
    <>
      <div key={product._id} className="d-flex align-items-center justify-content-between mb-3">
        <div className="d-flex align-items-center">
          <Image src={product.small_image_src} alt={product.name} width="75" height="75" className="mr-2" />
          <div>
            <div>{product.name}</div>
            <div className="text-secondary">
              <VariantPrice user={user} variant={selectedVariant} pricingRule={user.pricing_rule} hideDiscount />
            </div>
          </div>
        </div>
        <div className="text-sm text-secondary cursor-pointer" onClick={onClear}>
          <X width="16" height="16" strokeWidth="3.5" />
        </div>
      </div>
      <Row>
        <Col xs="12" md="6">
          <Field label="Size" type="select" name="variant" options={variantOptions} />
        </Col>
      </Row>
      <SubmitButton isSubmitting={isSubmitting} size="default">
        Select
      </SubmitButton>
    </>
  );
};

GenericChosenProduct.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  product: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    small_image_src: PropTypes.string.isRequired,
    variants: PropTypes.arrayOf(PropTypes.shape({ size: PropTypes.number })).isRequired,
  }).isRequired,
  variant: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool,
  onClear: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};
