import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import * as dc from 'dc';
import * as d3 from 'd3';
import { isOrder } from './filters';
import { useAppContainer } from '../../../../hooks/use-app-container.hook';
import { useMemo } from 'react';

export const RoastGraph = ({ data }) => {
  const ref = useRef();
  const container = useAppContainer();

  const primaryColor = useMemo(() => getComputedStyle(container).getPropertyValue('--primary'), [container]);

  useEffect(() => {
    const dimension = data.dimension(item => item.roast || 'Unknown');
    const group = dimension.group().reduceSum(item => Number(isOrder(item)));

    dc.pieChart(ref.current)
      .width(300)
      .height(300)
      .ordinalColors(['#2f97ca', ...d3.schemeSet2])
      .dimension(dimension)
      .group(group)
      .render();
  }, [ref, data, primaryColor]);

  return (
    <div className="data-roast text-center">
      <div className="mb-2">Coffee Roast</div>
      <div ref={ref}></div>
    </div>
  );
};

RoastGraph.propTypes = {
  data: PropTypes.object.isRequired,
};
