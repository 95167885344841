import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { SubmitButton, Form } from '@bottomless/common/components';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useToggle } from '@bottomless/common/hooks';
import { useModal } from '../../../../hooks/use-modal.hook';

const Schema = Yup.object().shape({
  paused: Yup.bool().required('This field is required'),
});

export const ResumeOrdering = ({ onSubmit }) => {
  const modalProps = useModal();
  const [isOpen, toggle] = useToggle();

  return (
    <>
      <div className="mw-300">
        <Button color="primary" block outline onClick={toggle}>
          Resume Automatic Re-ordering
        </Button>
      </div>
      <Modal isOpen={isOpen} size="md" toggle={toggle} {...modalProps}>
        <ModalHeader toggle={toggle} className="text-capitalize">
          Resume automatic re-ordering?
        </ModalHeader>
        <ModalBody>
          <Form
            initialValues={{ paused: false }}
            validationSchema={Schema}
            onSubmit={onSubmit}
            onSuccess={toggle}
            className="mw-300"
          >
            {({ isSubmitting }) => (
              <>
                <SubmitButton block size="default" isSubmitting={isSubmitting}>
                  Confirm Resume
                </SubmitButton>
              </>
            )}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

ResumeOrdering.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};
