import get from 'lodash-es/get';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import { Form, Field, SubmitButton, DataLoading } from '@bottomless/common/components';
import { useDataEffect } from '@bottomless/common/hooks';
import { addToastAction } from '@bottomless/common/store';
import { orderRateAction, sendFeedbackAction, submitOrderNoteAction, submitOrderRateAction } from '../../store/rate';
import { Order } from './components/Order';
import { PanelLayout } from '../../layouts/PanelLayout';
import './OrderRate.scss';
import { Heading } from '../../components/Heading';

const Schema = Yup.object().shape({
  content: Yup.string(),
});

const OrderRatePageComponent = ({ getRate, storeRate, createNote, sendFeedback, addToast }) => {
  const [order, setOrder] = useState(null);
  const location = useLocation();
  const queryParams = qs.parse(location.search);

  const { isFetching } = useDataEffect(getRate, setOrder, queryParams);

  const onOrderFeedback = async data => {
    await createNote(data);
    addToast('Thank you for your feedback!');
  };

  const onTiming = async (field, value) => {
    const { payload } = await storeRate({
      [field]: value,
    });

    if (value === 'late') addToast('Oh no! Thanks for letting us know.');
    else if (value === 'early') addToast('Thanks for letting us know.');
    else addToast('Glad it arrived just in time!');

    setOrder(payload);
  };

  const onFeedback = async data => {
    const { payload: order } = await sendFeedback(data);

    setOrder(order);

    if (data.like) addToast('Glad you loved it!');
    else if (data.dislike) addToast("Oh no! Sorry about that. We won't send you this again.");
  };

  return (
    <PanelLayout>
      <Heading>Rate your order</Heading>
      {isFetching && <DataLoading count={order ? 1 : 0} isLoading={isFetching} />}
      {order && Object.keys(order).length !== 0 && (
        <div className="page-rate-order">
          <Order onFeedback={onFeedback} order={order} onTiming={onTiming}>
            <Form
              initialValues={{ content: get(order, 'note.content', '') }}
              validationSchema={Schema}
              onSubmit={onOrderFeedback}
            >
              {({ isSubmitting }) => (
                <>
                  <Field name="content" type="textarea" label="Any thoughts?" />
                  <SubmitButton size={null} isSubmitting={isSubmitting}>
                    Send
                  </SubmitButton>
                </>
              )}
            </Form>
          </Order>
        </div>
      )}
      {!order ||
        (Object.keys(order).length === 0 && (
          <div className="mt-5">
            <h2 className="h2-landing text-center">Order Not Found</h2>
          </div>
        ))}
    </PanelLayout>
  );
};

OrderRatePageComponent.propTypes = {
  getRate: PropTypes.func.isRequired,
  storeRate: PropTypes.func.isRequired,
  createNote: PropTypes.func.isRequired,
  sendFeedback: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
};

export const OrderRatePage = connect(null, (dispatch, { match: { params: { id } } }) => ({
  getRate: queryParams => dispatch(orderRateAction(id, queryParams)),
  storeRate: data => dispatch(submitOrderRateAction(id, data)),
  createNote: data => dispatch(submitOrderNoteAction(id, data)),
  sendFeedback: data => dispatch(sendFeedbackAction(id, data)),
  addToast: message => dispatch(addToastAction(message)),
}))(OrderRatePageComponent);
