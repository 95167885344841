import React from 'react';
import PropTypes from 'prop-types';
import { TrackingTimelineElement } from './TrackingTimelineElement';

export const TrackingTimeline = ({ order }) => {
  if (!order.tracking?.length) {
    return null;
  }

  return (
    <div className="order-timeline">
      {order.tracking.map((track, i) => (
        <TrackingTimelineElement key={i} track={track} />
      ))}
    </div>
  );
};

TrackingTimeline.propTypes = {
  order: PropTypes.shape({ tracking: PropTypes.array }).isRequired,
};
