import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { DateFormat } from '@bottomless/common/src/components/DateFormat';
import { OrderStatuses } from '@bottomless/common/constants';

const SHOW_OVERRIDE_DATE_STATUSES = [OrderStatuses.Initiated, OrderStatuses.SubproductGenerated, OrderStatuses.Paid];

export const CoffeeOrderDates = ({ order }) => {
  const showOverrideDate = useMemo(() => SHOW_OVERRIDE_DATE_STATUSES.includes(order.status), [order.status]);

  return (
    <>
      {showOverrideDate && order.override_fulfillment_date && (
        <div className="text-center mb-4">
          <div>Will be roasted and shipped on:</div>
          <strong>
            <DateFormat
              date={order.override_fulfillment_date}
              correctTimezone={!!order._id && order._id !== 'pending'}
            />
          </strong>
        </div>
      )}
    </>
  );
};

CoffeeOrderDates.propTypes = {
  order: PropTypes.shape({
    _id: PropTypes.string,
    override_fulfillment_date: PropTypes.string,
    status: PropTypes.string.isRequired,
  }),
};
