import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Form, Field, SubmitButton, RadioBox, Box } from '@bottomless/common/components';
import * as Yup from 'yup';
import { Col, Row } from 'reactstrap';
import { PanelSection } from '../../../components/PanelSection';

const Schema = Yup.object().shape({
  timing: Yup.string().required('This field is required'),
  content: Yup.string()
    .min(10)
    .optional(),
});

const OPTIONS = [
  { value: 'early', label: 'Early', icon: '📉' },
  { value: 'perfect', label: 'Perfect', icon: '👌🏼' },
  { value: 'late', label: 'Late', icon: '📈' },
];

export const OrderTiming = ({ me, order, onOrderFeedback }) => {
  const disabled = useMemo(() => !me || order.rate?.timing, [me, order]);

  if (!order.date_arrived) {
    return null;
  }

  if (!me) {
    return null;
  }

  if (!order.rate?.timing && !me) {
    return null;
  }

  return (
    <PanelSection title="Order Feedback">
      <Box>
        <div className="text-secondary text-center mb-4">My order arrived...</div>
        <Form
          initialValues={{ timing: order.rate?.timing, content: order?.user_note?.content }}
          validationSchema={Schema}
          onSubmit={onOrderFeedback}
        >
          {({ isSubmitting }) => (
            <>
              <div className="form-input form-feedback">
                <Row>
                  {OPTIONS.map((option, i) => (
                    <Col key={i} xs={12 / OPTIONS.length}>
                      <RadioBox
                        size="sm"
                        name="timing"
                        labelClassName="bg-white"
                        labelInnerClassName="flex-grow-1"
                        label={
                          <div className="text-center">
                            <div className="mb-2">{option.icon}</div>
                            {option.label}
                          </div>
                        }
                        value={option.value}
                        type="radio"
                        disabled={disabled}
                      />
                    </Col>
                  ))}
                </Row>
                {!disabled && (
                  <span>
                    {' '}
                    <Field name="content" label="Any thoughts?" className={`any-thoughts-field`} />
                  </span>
                )}
              </div>
              {!disabled && (
                <div className="send-btn-wrapper text-center">
                  <SubmitButton size="default" isSubmitting={isSubmitting}>
                    Save
                  </SubmitButton>
                </div>
              )}
            </>
          )}
        </Form>
      </Box>
    </PanelSection>
  );
};

OrderTiming.propTypes = {
  me: PropTypes.object,
  order: PropTypes.object,
  onOrderFeedback: PropTypes.func,
};
