import React, { useCallback, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Flag from 'react-country-flag';
import * as Yup from 'yup';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { useToggle } from '@bottomless/common/hooks';
import { Image, Form, SubmitButton } from '@bottomless/common/components';
import { PanelSection } from '../../../components/PanelSection';
import { useIsProcessed } from '../../../hooks/use-is-processed.hook';
import { useModal } from '../../../hooks/use-modal.hook';
import { SafeTooltip } from '../../../components/SafeTooltip';

const Schema = Yup.object().shape({
  product: Yup.string().required('This field is required'),
  variant: Yup.string().required('This field is required'),
});

export const SubproductSelection = ({ me, order, selections, setProduct }) => {
  const modalProps = useModal();
  const form = useRef();
  const isProcessed = useIsProcessed({ order });
  const [modalOpen, toggleModal] = useToggle();
  const [selectedProduct, setSelectedProduct] = useState();

  const onProductClick = useCallback(
    product => () => {
      if (form.current) {
        setSelectedProduct(product);
        form.current.resetForm({ product: product.product._id, variant: product.variant._id });
        toggleModal();
      }
    },
    [setSelectedProduct, toggleModal]
  );

  if (!me || isProcessed || !selections?.length) {
    return null;
  }

  return (
    <>
      <PanelSection>
        <header className="d-flex align-items-center mb-2">
          <h4 className="mb-0 mr-2">Select a recommended product</h4>
          <SafeTooltip content="Products are recommended based on your custom rotation preferences, roaster schedules, and your location.">
            <span role="button" className="d-inline-block information-badge information-badge-lg">
              i
            </span>
          </SafeTooltip>
        </header>
        <Row>
          {selections.map(product => (
            <Col
              xs="4"
              key={product?.product?._id}
              className={classNames('order-subproduct cursor-pointer text-center d-flex', {
                selected: product.product._id === order.subproduct_id?.product?._id,
              })}
              onClick={onProductClick(product)}
            >
              <div className="order-subproduct-wrapper small rounded p-2 flex-grow-1 border">
                <Image
                  className="img-fluid order-subproduct-image mb-2"
                  src={product.product.small_image_src}
                  alt={product.product.name}
                />
                <div>{product.product.name}</div>
              </div>
            </Col>
          ))}
        </Row>
      </PanelSection>
      <Form
        innerRef={form}
        initialValues={{ product: '', variant: '' }}
        schema={Schema}
        onSubmit={setProduct}
        onSuccess={toggleModal}
      >
        {({ isSubmitting, submitForm }) => (
          <>
            {selectedProduct && (
              <Modal isOpen={modalOpen} toggle={toggleModal} {...modalProps} className="modal-select-subproduct">
                <ModalHeader toggle={toggleModal}>Select your product</ModalHeader>
                <ModalBody>
                  <div className="d-flex align-items-center mb-3">
                    <Image
                      className="img-fluid mr-3 product-image"
                      src={selectedProduct.product.small_image_src}
                      alt={selectedProduct.product.name}
                      height="100"
                    />
                    <div>
                      <div className="font-weight-bold">
                        {selectedProduct.product.name}{' '}
                        {selectedProduct.product.origin?.code === 'global' ? null : (
                          <Flag className="ml-2 origin-flag" countryCode={selectedProduct.product.origin.code} />
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className="mt-3 mb-4"
                    dangerouslySetInnerHTML={{ __html: selectedProduct.product.description }}
                  />
                  <div className="text-center">
                    <SubmitButton color="primary" size="default" isSubmitting={isSubmitting} onClick={submitForm}>
                      Select this product
                    </SubmitButton>
                  </div>
                </ModalBody>
              </Modal>
            )}
          </>
        )}
      </Form>
    </>
  );
};

SubproductSelection.propTypes = {
  me: PropTypes.object,
  order: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    subproduct_id: PropTypes.shape({
      product: PropTypes.shape({
        _id: PropTypes.string.isRequired,
      }),
    }),
  }).isRequired,
  selections: PropTypes.array,
  setProduct: PropTypes.func,
};
