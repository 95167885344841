import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Field, Form, SubmitButton } from '@bottomless/common/components';

const Schema = Yup.object().shape({
  code: Yup.number().required('This field is required'),
});

export const VerifyCodeForm = ({ onSubmit, onSuccess }) => {
  return (
    <Form initialValues={{ code: '' }} validationSchema={Schema} onSubmit={onSubmit} onSuccess={onSuccess}>
      {({ isSubmitting, dirty }) => (
        <>
          <div className="text-secondary text-center mb-3">We sent you a confirmation code. Please type it below.</div>
          <Field type="number" name="code" label="Code" />
          <div className="text-center">
            <SubmitButton isSubmitting={isSubmitting} disabled={!dirty}>
              Save
            </SubmitButton>
          </div>
        </>
      )}
    </Form>
  );
};

VerifyCodeForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};
