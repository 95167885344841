import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { IsAuth } from '../guards/auth.guard';
import { SubscriptionPage } from '../pages/Profile';
import { OrderPage } from '../pages/Order';
import { OrdersPage } from '../pages/Orders';
import { SettingsPage } from '../pages/Settings';
import { LogoutPage } from '../pages/Logout';
import { PortalSelectPage } from '../pages/PortalSelect';
import { ChangeScalePage } from '../pages/ChangeScale/ChangeScalePage';
import { OrderRatePage } from '../pages/OrderRate/OrderRate';
import { UpdateShopifyPaymentMethodPage } from '../pages/UpdateShopifyPaymentMethod';
import { SwitchToSubscriptionByUsagePage } from '../pages/SwitchToSubscriptionByUsage';
import { ContinueGiftSubscription } from '../pages/ContinueGiftSubscription';
import { SetPasswordPage } from '../pages/SetPassword';
import { SetupTextPage } from '../pages/SetupText/SetupTextPage';
import { NewSubscriptionSummaryPage } from '../pages/NewSubscriptionSummary';
import { NewSubscriptionSuccessPage } from '../pages/NewSubscriptionSuccess';
import { SubscriptionsPage } from '../pages/Account';
import { DataPage } from '../pages/Data';

export const AuthenticatedRoutes = () => (
  <IsAuth>
    <Switch>
      <Route path="/" exact component={SubscriptionPage} />
      <Redirect from="/profile" to="/subscription" />
      <Route path="/subscriptions" exact component={SubscriptionsPage} />
      <Route path="/subscription" exact component={SubscriptionPage} />
      <Route path="/switch-to-subscription-by-usage" exact component={SwitchToSubscriptionByUsagePage} />
      <Route path="/order/:id/status/:productId/:variantId" exact component={OrderPage} />
      <Route path="/orders" exact component={OrdersPage} />
      <Route path="/account" exact component={SettingsPage} />
      <Route path="/data" exact component={DataPage} />
      <Route path="/logout" exact component={LogoutPage} />
      <Route path="/portal-select" exact component={PortalSelectPage} />
      <Route path="/change-scale/:id" exact component={ChangeScalePage} />
      <Route path="/rate/:id" exact component={OrderRatePage} />
      <Route path="/update_stripe" exact component={UpdateShopifyPaymentMethodPage} />
      <Route path="/continue" exact component={ContinueGiftSubscription} />
      <Route path="/set-password" exact component={SetPasswordPage} />
      <Route path="/setup_alerts" exact component={SetupTextPage} />
      <Route path="/new-subscription-summary/:id/complete" exact component={NewSubscriptionSuccessPage} />
      <Route path="/new-subscription-summary/:id" exact component={NewSubscriptionSummaryPage} />
    </Switch>
  </IsAuth>
);
