import React from 'react';
import PropTypes from 'prop-types';
import './PanelLayout.scss';

export const PanelLayoutGuest = ({ children }) => {
  return (
    <div className="layout-panel d-flex">
      <div className="layout-content">{children}</div>
    </div>
  );
};

PanelLayoutGuest.propTypes = { children: PropTypes.node.isRequired };
