import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { Feature } from './Feature';
import { ConfirmationSection } from './ConfirmationSection';

export const AboutSusbscroptionByUsage = ({
  buttonOutline,
  withCofirmation,
  setDumbPeriod,
  onSuccess,
  showMore = false,
}) => {
  const [show, setShow] = useState(showMore);
  const onLearnMore = useCallback(() => setShow(true), []);

  return (
    <>
      {!show && (
        <div className="mw-300">
          <Button color="primary" block outline={buttonOutline} onClick={onLearnMore} className="mt-4">
            Learn More
          </Button>
        </div>
      )}
      {show && (
        <>
          <hr className="mt-4" />
          <div className="mw-360">
            <div className="switch-to-sbu-title my-4 text-center">About Subscribe By Usage</div>
            <Feature
              title="Receive a smart scale when you switch to Subscribe By Usage"
              description="Store your product on the smart scale to get automatic re-orders."
              image="/shopify-account/promote-sbu/promot-sbu-image-1.jpg"
              align="left"
            />
            <Feature
              title="Personalized features and alerts"
              description="Build a queue. Get alerts 8-12h before each order. Use any container with the scale."
              image="/shopify-account/promote-sbu/promot-sbu-image-2.jpg"
              align="right"
            />
            <Feature
              title="No hidden fees"
              description="You'll only be charged for your product when the re-order is processed."
              image="/shopify-account/promote-sbu/promot-sbu-image-3.jpg"
              align="left"
            />
          </div>
          {withCofirmation && (
            <>
              <hr />
              <div className="mw-360">
                <ConfirmationSection setDumbPeriod={setDumbPeriod} onSuccess={onSuccess} />
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

AboutSusbscroptionByUsage.propTypes = {
  buttonOutline: PropTypes.bool,
  withCofirmation: PropTypes.bool,
  setDumbPeriod: PropTypes.func,
  onSuccess: PropTypes.func,
  showMore: PropTypes.bool,
};
