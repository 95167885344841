import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useToast, useToggle } from '@bottomless/common/hooks';
import { useModal } from '../../../../hooks/use-modal.hook';
import { EditNumberForm } from './EditNumberForm';
import { VerifyCodeForm } from './VerifyCodeForm';

export const PhoneNumberEdit = ({ isOpen, toggle: rawToggle, me, alertSettings, textAlerts, verifyPhone }) => {
  const modalProps = useModal();
  const [verification, toggleVerification, setVerification] = useToggle();

  const toggle = useCallback(() => {
    setVerification(false);
    rawToggle();
  }, [setVerification, rawToggle]);

  const onSetupClick = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();
      toggle();
    },
    [toggle]
  );

  const successToast = useToast('Your phone number has been updated');

  const onSuccess = useCallback(() => {
    toggle();
    successToast();
  }, [toggle, successToast]);

  const onEditSuccess = useCallback(
    payload => {
      if (payload.confirmationSent) {
        toggleVerification();
      } else {
        onSuccess();
      }
    },
    [toggleVerification, onSuccess]
  );

  return (
    <>
      <Button color="primary" outline size="sm" onClick={onSetupClick}>
        {me.phone ? 'Edit' : 'Setup'}
      </Button>
      <Modal isOpen={isOpen} size="md" toggle={toggle} {...modalProps} className="modal-change-product">
        <ModalHeader toggle={toggle}>{me.phone ? 'Edit' : 'Set up'} your phone number</ModalHeader>
        <ModalBody>
          {isOpen && !verification && <EditNumberForm me={me} onSubmit={textAlerts} onSuccess={onEditSuccess} />}
          {isOpen && verification && <VerifyCodeForm onSubmit={verifyPhone} onSuccess={onSuccess} />}
          {alertSettings?.unsubscribed && (
            <div className="text-sm text-warning">
              Your text messages are stopped. Message us &quot;START&quot; to turn them back on.
            </div>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

PhoneNumberEdit.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  me: PropTypes.object.isRequired,
  alertSettings: PropTypes.shape({
    unsubscribed: PropTypes.bool,
  }),
  textAlerts: PropTypes.func.isRequired,
  verifyPhone: PropTypes.func.isRequired,
};
