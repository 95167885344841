import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { PanelSection } from '../../../../components/PanelSection';
import { MonthsConsumptionGraph } from '../../components/Graphs/MonthsConsumptionGraph';
import { DailyConsumptionGraph } from '../../components/Graphs/DailyConsumptionGraph';
import { OriginGraph } from '../../components/Graphs/OriginGraph';
import { ReadingsGraph } from '../../components/Graphs/ReadingsGraph';
import { RoastGraph } from '../../components/Graphs/RoastGraph';

export const CoffeeDetailedGraphs = ({ orders, adjustedWeight, cleanData }) => {
  return (
    <>
      {(cleanData.data || orders.length) && (
        <PanelSection title="Statistics">
          {orders?.length > 0 && (
            <Row>
              <Col xs="12" md="6" className="mb-4">
                <RoastGraph data={adjustedWeight} />
              </Col>
              <Col xs="12" md="6" className="mb-4">
                <OriginGraph data={adjustedWeight} />
              </Col>
            </Row>
          )}
          {cleanData?.data && (
            <>
              <div className="mb-5 graph-readings">
                <ReadingsGraph data={adjustedWeight} />
              </div>
              <Row>
                <Col xs="12" md="8" className="mb-4">
                  <DailyConsumptionGraph data={adjustedWeight} />
                </Col>
                <Col xs="12" md="4" className="mb-4">
                  <MonthsConsumptionGraph data={adjustedWeight} />
                </Col>
              </Row>
            </>
          )}
        </PanelSection>
      )}
    </>
  );
};

CoffeeDetailedGraphs.propTypes = {
  orders: PropTypes.array,
  cleanData: PropTypes.shape({
    data: PropTypes.any,
  }),
  adjustedWeight: PropTypes.any,
};
