import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { DateFormat, Form, SubmitButton } from '@bottomless/common/components';
import { READABLE_FORMAT } from '../../../../utils/dates';

export const UndoSwitch = ({ me, setDumbPeriod, toggle, getNextOrderDateFromNow }) => {
  const period = useMemo(() => (!me.dumb_period ? null : me.dumb_period / 7), [me]);

  return (
    <Form
      initialValues={{ dumb_period: null }}
      onSubmit={setDumbPeriod}
      onSuccess={toggle}
      className="mw-300 form-fixed-period"
    >
      {({ isSubmitting }) => (
        <>
          {period && (
            <div className="d-flex justify-content-between mt-3 mb-4">
              <div className="font-weight-bolder order-heading">Frequency</div>
              <div>
                {period} week{period > 1 && 's'}
              </div>
            </div>
          )}
          <div className="d-flex justify-content-between mt-3 mb-4">
            <div className="font-weight-bolder order-heading">Scheduled fulfillment</div>
            <div>
              <DateFormat date={getNextOrderDateFromNow(me.dumb_period)} format={READABLE_FORMAT} />
            </div>
          </div>
          <div className="mb-4">
            <SubmitButton block size="default" isSubmitting={isSubmitting}>
              Undo switch
            </SubmitButton>
          </div>
        </>
      )}
    </Form>
  );
};

UndoSwitch.propTypes = {
  me: PropTypes.shape({ dumb_period: PropTypes.number }),
  setDumbPeriod: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  getNextOrderDateFromNow: PropTypes.func.isRequired,
};
