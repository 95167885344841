import { DataHandler, DataHandlerContent } from '@bottomless/common/components';
import React from 'react';
import { Link } from 'react-router-dom';
import { PanelLayout } from '../../layouts/PanelLayout';
import { Heading } from '../../components/Heading';
import { useAlertSettings, useLastSubscriptionOrder, useMe, useUpcomingOrder } from './hooks';
import { AccountActions, Address, BillingDetails, FaqModal, Notifications } from './components';
import { Col, Row } from 'reactstrap';
import { PanelSection } from '../../components/PanelSection';
import { StripeLoaded, StripeLoader } from '../../components/StripeLoader';
import { useLastOrderDate } from '../../hooks/use-last-order-date';
import './SettingsPage.scss';
import { ShopifyFixedSubscriptionPaymentDetails } from './components/ShopifyFixedSubscriptionPaymentDetails';

export const SettingsPage = () => {
  const me = useMe();
  const alertSettings = useAlertSettings();
  const upcomingOrders = useUpcomingOrder();
  const lastSubscriptionOrder = useLastSubscriptionOrder();

  const { getNextOrderDateFromNow, getNextOrderDate } = useLastOrderDate({
    lastOrder: lastSubscriptionOrder,
    upcomingOrders,
    user: me?.data,
  });

  return (
    <PanelLayout>
      <StripeLoader>
        <div className="page page-settings">
          <Heading>Settings</Heading>
          <DataHandler {...me}>You haven&apos;t had any orders yet.</DataHandler>
          <DataHandlerContent {...me}>
            <Row>
              <Col xs="12" md="6">
                <PanelSection>
                  <Address me={me.data} onSubmit={me.verifyAddress} />
                </PanelSection>
                <PanelSection title="Notifications">
                  <DataHandler {...alertSettings}>You haven&apos;t had any orders yet.</DataHandler>
                  <DataHandlerContent {...alertSettings}>
                    <Notifications
                      me={me.data}
                      alertSettings={alertSettings.data}
                      textAlerts={alertSettings.textAlerts}
                      verifyPhone={alertSettings.verifyPhone}
                      onSubmit={alertSettings.setAlertSettings}
                    />
                  </DataHandlerContent>
                </PanelSection>
                <PanelSection title="Billing Details">
                  {me?.data?.shopifySubscriptionContractId ? (
                    <ShopifyFixedSubscriptionPaymentDetails me={me.data} onSubmit={me.sendPaymentUpdateEmail} />
                  ) : (
                    <StripeLoaded>
                      <BillingDetails me={me.data} onSubmit={me.verifyStripe} />
                    </StripeLoaded>
                  )}
                </PanelSection>
                <AccountActions
                  me={me.data}
                  pauseAccount={me.pauseAccount}
                  closeAccount={me.closeAccount}
                  requestScaleReturn={me.requestScaleReturn}
                  getNextOrderDateFromNow={getNextOrderDateFromNow}
                  getNextOrderDate={getNextOrderDate}
                  setDumbPeriod={me.setDumbPeriod}
                />
                <FaqModal me={me.data} />
                <div className="text-center mt-3">
                  <Link to="/logout" className="btn btn-outline-primary">
                    Log Out
                  </Link>
                </div>
              </Col>
            </Row>
          </DataHandlerContent>
        </div>
      </StripeLoader>
    </PanelLayout>
  );
};
