import React, { useState, useCallback } from 'react';
import { DataHandler, DataHandlerContent } from '@bottomless/common/components';
import { Heading } from '../../components/Heading';
import { PanelLayout } from '../../layouts/PanelLayout';
import { useCanSwitchToSbu } from '../../hooks/use-can-switch-to-sbu.hook';
import { SwitchToSubscriptionByUsage } from '../../components/SwitchToSubscriptionByUsage';
import { useMe } from './hooks';
import { AlreadySwitched, SwitchSuccess } from './components';
import './SwitchToSubscriptionByUsagePage.scss';

export const SwitchToSubscriptionByUsagePage = () => {
  const me = useMe();
  const [switched, setSwitched] = useState(false);

  const canSwitchToSbu = useCanSwitchToSbu();
  const onSuccess = useCallback(() => setSwitched(true), []);

  return (
    <PanelLayout>
      <div className="page page-subscription-by-usage">
        <Heading back="/">Switch to Subscription by Usage</Heading>
        <DataHandler {...me}>You haven&apos;t had any orders yet.</DataHandler>
        <DataHandlerContent {...me}>
          {switched && <SwitchSuccess />}
          {!switched && canSwitchToSbu && (
            <SwitchToSubscriptionByUsage onSuccess={onSuccess} place="page-sbu" showMore />
          )}
          {!switched && !canSwitchToSbu && <AlreadySwitched me={me.data} />}
        </DataHandlerContent>
      </div>
    </PanelLayout>
  );
};
