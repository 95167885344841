import React from 'react';
import PropTypes from 'prop-types';
import { EditPausedUntil } from './Actions/EditPausedUntil';
import { ResumeOrdering } from './Actions/ResumeOrdering';

export const PausedOrdering = ({ me, pauseAccount }) => {
  return (
    <div className="mb-5">
      <div className="mb-2">Automatic re-ordering paused until:</div>
      <EditPausedUntil me={me} onSubmit={pauseAccount} />
      <div className="text-center">
        <ResumeOrdering onSubmit={pauseAccount} />
      </div>
      <hr className="mt-5" />
    </div>
  );
};

PausedOrdering.propTypes = {
  me: PropTypes.shape({ pausedUntil: PropTypes.string.isRequired }).isRequired,
  pauseAccount: PropTypes.func.isRequired,
};
