import { useDataEffect } from '@bottomless/common/hooks';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUpcomingOrderAction } from '../../../store/order/order.actions';

export const useUpcomingOrder = () => {
  const dispatch = useDispatch();
  const action = useCallback(() => dispatch(getUpcomingOrderAction()), [dispatch]);
  const { data } = useSelector(({ order }) => ({ data: order.upcomingOrders }));

  const { error, isFetching: isLoading } = useDataEffect(action);

  return { data, isLoading, error };
};
