import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Link2 } from 'react-feather';
import { useToggle } from '@bottomless/common/hooks';
import { getTrackingLink } from '@bottomless/common/utils';
import { useIsProcessed } from '../../hooks/use-is-processed.hook';
import { TrackingHistoryRow } from './TrackingHistoryRow';
import { useModal } from '../../hooks/use-modal.hook';
import { TrackingTimeline } from './TrackingTimeline';

export const TrackingHistory = ({ order }) => {
  const modalProps = useModal();
  const isProcessed = useIsProcessed({ order });
  const [open, toggle] = useToggle();

  if ((!order.tracking?.length && !order.tracking_number) || !isProcessed) {
    return null;
  }

  const firstRealTrackingIndex = order.tracking?.findIndex(({ type }) => type !== 'note') || 0;

  return (
    <div className="mt-4">
      <div className="font-weight-bolder order-heading mb-2">Delivery Details</div>
      {order.tracking?.length > 0 && (
        <div className="order-history order-history-first small mb-3">
          <TrackingHistoryRow showOriginal track={order.tracking[firstRealTrackingIndex]} />
        </div>
      )}
      <div className="text-center">
        <Button color="link" size="sm" onClick={toggle} className="text-underline">
          Show Tracking Link{order.tracking?.length >= 0 && ' and Full History'}
        </Button>
      </div>
      <Modal isOpen={open} toggle={toggle} size="lg" {...modalProps}>
        <ModalHeader toggle={toggle}>Delivery Details</ModalHeader>
        <ModalBody>
          <TrackingTimeline order={order} />
          {order.tracking_number && (
            <div className="mt-2 text-center font-weight-bolder">
              <a
                href={getTrackingLink(order.shipping_service, order.tracking_number, order.tracking_url)}
                rel="noopener noreferrer"
                target="_blank"
                className="mb-2"
              >
                <Link2 width="12" height="12" className="mr-1" strokeWidth="3" /> Tracking Link
              </a>
            </div>
          )}
        </ModalBody>
      </Modal>
    </div>
  );
};

TrackingHistory.propTypes = {
  order: PropTypes.shape({
    tracking: PropTypes.array,
    tracking_number: PropTypes.string,
    shipping_service: PropTypes.string,
    tracking_url: PropTypes.string,
  }).isRequired,
};
