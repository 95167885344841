import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQueryString } from '@bottomless/common/hooks';
import { DataHandler } from '@bottomless/common/components';
import { PanelLayout } from '../../layouts/PanelLayout';
import { useScaleChange } from './hooks';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useLocalStorage } from '../../hooks/use-local-storage.hook';

const LOCAL_STORAGE_KEY_ACCESS_TOKEN = 'bottomless_access_token';

export const ChangeScalePage = () => {
  const {
    params: { redirect },
  } = useQueryString();
  const history = useHistory();
  const { id } = useParams();
  const [, setLocalStorageValue] = useLocalStorage([LOCAL_STORAGE_KEY_ACCESS_TOKEN]);

  const changeScale = useScaleChange(id, redirect);

  useEffect(() => {
    if (!changeScale.data) {
      return;
    }

    if (changeScale.data.token) {
      setLocalStorageValue(LOCAL_STORAGE_KEY_ACCESS_TOKEN, changeScale.data.token);
      history.push(redirect || '');
    } else if (changeScale.data.redirect) {
      window.location.href = changeScale.data.redirect;
    } else {
      history.push('/');
    }
  }, [changeScale.data, history, redirect, setLocalStorageValue]);

  return (
    <PanelLayout>
      <DataHandler {...changeScale} />
    </PanelLayout>
  );
};
