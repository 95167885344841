import { useCallback, useState } from 'react';

export const useActionLoading = (actionFunction, withErrorHandling = false) => {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const execute = useCallback(
    async (...data) => {
      try {
        setError(null);
        setLoading(true);
        const result = await actionFunction(...data);
        setLoading(false);
        return result;
      } catch (e) {
        setError(e);
        setLoading(false);

        if (!withErrorHandling) {
          throw e;
        }
      }
    },
    [actionFunction, withErrorHandling]
  );

  return { execute, isLoading, ...(withErrorHandling ? { error } : {}) };
};
