import React, { useMemo, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import * as Yup from 'yup';
import {
  AddressInput,
  Box,
  Field,
  Form,
  GoogleMapsLoaded,
  GoogleMapsLoader,
  SubmitButton,
} from '@bottomless/common/components';
import { useToggle, useToast } from '@bottomless/common/hooks';
import { useModal } from '../../../hooks/use-modal.hook';

const Schema = Yup.object().shape({
  first_name: Yup.string().required('This field is required'),
  last_name: Yup.string().required('This field is required'),
  verifiedAddress: Yup.object()
    .shape({
      street1: Yup.string().required('This field is required'),
      city: Yup.string().required('This field is required'),
      zip: Yup.string().required('This field is required'),
      state: Yup.string().required('This field is required'),
      street2: Yup.string(),
    })
    .required(),
});

export const Address = ({ me, onSubmit }) => {
  const modalProps = useModal();
  const [isOpen, toggle] = useToggle();
  const form = useRef();

  const initialValues = useMemo(
    () => ({
      first_name: me.first_name,
      last_name: me.last_name,
      verifiedAddress: me.verifiedAddress,
    }),
    [me]
  );

  const toggleAndClear = useCallback(() => {
    toggle();
    setTimeout(() => form.current?.resetForm(initialValues), 300);
  }, [toggle, form, initialValues]);

  const successToast = useToast('Your address has been changed');

  const onSuccess = useCallback(() => {
    toggleAndClear();
    successToast();
  }, [toggleAndClear, successToast]);

  return (
    <>
      <Box innerClassName="d-flex justify-content-between align-items-center">
        <div>
          <div className="font-weight-bold">
            {me.first_name} {me.last_name}
          </div>
          {me.verifiedAddress.pickup && <div className="mb-1 mt-1">Pickup at {me.verifiedAddress.title}</div>}
          <div>
            {me.verifiedAddress.street1}
            {!!me.verifiedAddress.street2 && ` ${me.verifiedAddress.street2}`}
          </div>
          <div>
            {me.verifiedAddress.city}, {me.verifiedAddress.state}, {me.verifiedAddress.zip}
          </div>
        </div>
        {!me.verifiedAddress.pickup && (
          <Button color="primary" size="sm" onClick={toggle}>
            Edit
          </Button>
        )}
      </Box>
      {!me.verifiedAddress.pickup && (
        <GoogleMapsLoader>
          <Modal isOpen={isOpen} size="lg" toggle={toggleAndClear} {...modalProps} className="modal-change-product">
            <ModalHeader toggle={toggleAndClear}>Change your address</ModalHeader>
            <ModalBody>
              <Form
                innerRef={form}
                initialValues={initialValues}
                validationSchema={Schema}
                onSubmit={onSubmit}
                onSuccess={onSuccess}
              >
                {({ isSubmitting, values, setFieldValue }) => {
                  return (
                    <>
                      <Row>
                        <Col xs="12" sm="6">
                          <Field name="first_name" label="First name" />
                        </Col>
                        <Col xs="12" sm="6">
                          <Field name="last_name" label="Last name" />
                        </Col>
                      </Row>
                      <GoogleMapsLoaded>
                        <AddressInput {...{ setFieldValue, values }} />
                      </GoogleMapsLoaded>
                      <SubmitButton size="default" isSubmitting={isSubmitting}>
                        Save
                      </SubmitButton>
                    </>
                  );
                }}
              </Form>
            </ModalBody>
          </Modal>
        </GoogleMapsLoader>
      )}
    </>
  );
};

Address.propTypes = {
  me: PropTypes.shape({
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    verifiedAddress: PropTypes.object.isRequired,
  }),
  onSubmit: PropTypes.func.isRequired,
};
