import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import { ChevronRight } from 'react-feather';
import { UserAvatar } from '@bottomless/common/components';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Badge } from 'reactstrap';
import { AccountName } from './AccountName';

const DAYS_IN_WEEK = 7;
export const BottomlessAccount = ({ account, selected }) => {
  const lastOrderDate = useMemo(() => {
    const orderDate = account?.lastOrderCreatedAt;
    if (!orderDate) {
      return null;
    }
    return moment.duration(moment().diff(moment(orderDate, 'YYYY-MM-DD HH:mm:ss Z'))).humanize();
  }, [account]);

  const frequencyText = useMemo(() => {
    if (account.subscriptionType === 'dynamic') {
      return 'Based on usage';
    }
    if (!account.dumb_period) {
      return '';
    }
    if (account.dumb_period % DAYS_IN_WEEK === 0) {
      return `Every ${account.dumb_period / DAYS_IN_WEEK} week${account.dumb_period > 7 ? 's' : ''}`;
    }

    return `Every ${account.dumb_period} day${account.dumb_period > 1 ? 's' : ''}`;
  }, [account]);

  return (
    <Link
      to={`/change-scale/${account._id}`}
      className={classNames(
        { 'account-container-selected': selected },
        'account-container d-flex align-items-center pl-0 pt-2 pb-2 mb-2 pr-2'
      )}
    >
      <div className="d-flex align-items-center">
        <UserAvatar user={account} width="" className="account-avatar" />
        <div className="ml-3 info-wrapper">
          <div className="font-weight-bold account-name">
            <AccountName account={account} withMoreText />
          </div>
          <div className="small">{frequencyText}</div>
          {lastOrderDate && <div className="small text-secondary font-italic">Last order: {lastOrderDate} ago</div>}
        </div>
      </div>
      <div className="pl-2 pl-md-3 text-secondary box-chevron d-flex align-items-center">
        {account.hibernate && (
          <Badge color="secondary" pill className="inactive-badge mr-2">
            Inactive
          </Badge>
        )}
        <ChevronRight strokeWidth="2" width="24" height="24" />
      </div>
    </Link>
  );
};

BottomlessAccount.propTypes = {
  account: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    subscriptionType: PropTypes.string,
    dumb_period: PropTypes.number,
    lastOrderCreatedAt: PropTypes.string,
    hibernate: PropTypes.bool,
    product: PropTypes.shape({
      product: PropTypes.shape({
        name: PropTypes.string.isRequired,
        category: PropTypes.shape({
          name: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  selected: PropTypes.bool,
};
