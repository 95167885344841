import React, { useCallback, useEffect, useMemo, useState } from 'react';
import qs from 'query-string';
import PropTypes from 'prop-types';
import { Trash2, MoreVertical } from 'react-feather';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { Plus } from 'react-feather';
import classNames from 'classnames';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { VariantPrice } from '@bottomless/common/src/components/VariantPrice/VariantPrice';

import './CustomList.scss';
import { VariantAttributes } from '@bottomless/common/src/components/VariantAttributes/VariantAttributes';

export const CustomList = ({
  wishlist: originalWishlist,
  shopFilters,
  productPricingRule,
  moveCustomListItem,
  deleteCustomListItem,
  isFirstBag,
  withAddNew = true,
  me,
}) => {
  const [orderedWishlist, setOrderedWishlist] = useState(originalWishlist);

  useEffect(() => setOrderedWishlist(originalWishlist), [originalWishlist, setOrderedWishlist]);

  const wishlist = useMemo(
    () =>
      orderedWishlist &&
      Array.isArray(orderedWishlist) &&
      orderedWishlist.map(({ product, proposed, variant: variantId, _id, grind }) => ({
        _id,
        product,
        grind,
        proposed,
        variant: product.variants.find(variant => variant._id === variantId),
      })),
    [orderedWishlist]
  );

  const onDeleteClick = useCallback(index => () => deleteCustomListItem(index), [deleteCustomListItem]);

  const onDragEnd = useCallback(
    ({ source, destination }) => {
      if (source && destination) {
        const newOrderedWishList = [...orderedWishlist];
        const movableItem = newOrderedWishList.splice(source.index, 1);
        newOrderedWishList.splice(destination.index, 0, ...movableItem);
        setOrderedWishlist(newOrderedWishList);

        moveCustomListItem(source.index, destination.index);
      }
    },
    [moveCustomListItem, orderedWishlist, setOrderedWishlist]
  );

  const onProductClick = useCallback(
    product => () => {
      if (product.proposed?.reference_url) {
        const win = window.open(`${product.proposed.reference_url}?subscribe-by-usage=true`, '_blank');
        win.focus();
      }
    },
    []
  );

  const shopFiltersString = useMemo(() => {
    if (!shopFilters) {
      return '';
    }

    const params = Object.entries(shopFilters)
      .filter(([, value]) => value || value?.length)
      .map(([key, value]) => [
        key,
        value.length ? value.map(val => (typeof val === 'object' ? val._id : val)).join(',') : value,
      ])
      .reduce((par, [key, value]) => ({ ...par, [key]: value }), {});

    return `?${qs.stringify(params).replace(/%2C/g, ',')}`;
  }, [shopFilters]);

  return (
    <div className="wishlist mb-1">
      {wishlist?.length === 0 && (
        <div className="py-4 text-center text-secondary">
          Your queue is empty, you can add new products using &quot;Add To Queue&quot; button.
        </div>
      )}

      {withAddNew && (
        <Link
          to={`/shop${shopFiltersString}`}
          className="mb-1 text-left wishlist-new-product d-flex align-items-center btn btn-outline-transparent btn-block"
        >
          <div className="wishlist-new-product-plus d-flex align-items-center justify-content-center mr-2">
            <Plus />
          </div>
          <span>Add Coffee to Custom Rotation</span>
        </Link>
      )}

      {wishlist?.length > 0 && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="main-list">
            {(provided, snapshot) => (
              <>
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className={snapshot.isDraggingOver ? 'dragging-over' : undefined}
                >
                  {wishlist.map(({ product, proposed, variant, _id, grind }, i) => (
                    <Draggable key={_id} draggableId={_id} index={i}>
                      {provided => (
                        <div
                          className="d-flex justify-content-between px-0 px-md-2 py-2 wishlist-row"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <div className="d-flex align-items-stretch wishlist-row-product">
                            <div className="d-flex align-items-center" {...provided.dragHandleProps}>
                              <div className="d-flex align-items-stretch wishlist-icons">
                                <div className="d-flex align-items-center">
                                  <div
                                    className={classNames('mr-1 mr-md-3 wishlist-move', {
                                      'd-none': wishlist.length <= 1,
                                    })}
                                  >
                                    <MoreVertical size="14" />
                                    <MoreVertical size="14" />
                                  </div>
                                </div>
                                {wishlist.length <= 1 && <div className="mr-1 mr-md-3 wishlist-move-empty" />}
                                <div className="mr-1 mr-md-3 small wishlist-number d-flex align-items-center">
                                  {i + 1}
                                </div>
                              </div>
                              <div className="wishlist-image-wrapper">
                                <div className="wishlist-number-overlay d-none">{i + 1}</div>
                                <img
                                  className="img-fluid wishlist-image"
                                  key={product._id}
                                  src={product.small_image_src}
                                  alt={product.name}
                                />
                              </div>
                            </div>
                            <div
                              className="mr-2 mr-md-4 ml-2 wishlist-row-product-name cursor-pointer"
                              onClick={onProductClick({ _id, product, proposed, variant, grind })}
                            >
                              <div className="text-secondary text-uppercase small wishlist-vendor-name">
                                {product.vendor_name}
                              </div>
                              <div className="wishlist-product-name">{product.name}</div>
                              <div className="wishlist-vendor-name text-secondary small">
                                <VariantAttributes productVariant={{ product, variant: variant._id }} grind={grind} />
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <div className="wishlist-price">
                              <VariantPrice
                                user={me}
                                variant={variant}
                                pricingRule={productPricingRule}
                                isFirstBag={isFirstBag}
                                discountClass="d-none"
                              />
                            </div>
                            <Button
                              color="transparent"
                              className="text-danger ml-1 ml-md-4 wishlist-delete"
                              onClick={onDeleteClick(i)}
                            >
                              <Trash2 size="16" />
                            </Button>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                </div>
                <div className={snapshot.isDraggingOver ? 'dragging-over' : undefined}>{provided.placeholder}</div>
              </>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </div>
  );
};

CustomList.propTypes = {
  wishlist: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      product: PropTypes.object.isRequired,
      grind: PropTypes.object.isRequired,
      variant: PropTypes.string.isRequired,
    })
  ).isRequired,
  shopFilters: PropTypes.object,
  productPricingRule: PropTypes.object,
  moveCustomListItem: PropTypes.func.isRequired,
  deleteCustomListItem: PropTypes.func.isRequired,
  attributes: PropTypes.shape({ grinds: PropTypes.array.isRequired }),
  updateCustomListElement: PropTypes.func,
  isFirstBag: PropTypes.bool,
  withAddNew: PropTypes.bool,
  me: PropTypes.object,
};
