import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDataEffect } from '@bottomless/common/hooks';
import { getProductAttributesAction } from '../../../store/product';

export const useAttributes = () => {
  const dispatch = useDispatch();
  const action = useCallback(() => dispatch(getProductAttributesAction()), [dispatch]);
  const { data } = useSelector(({ product }) => ({ data: product.attributes }));

  const { error, isFetching: isLoading } = useDataEffect(action);

  return { data, isLoading, error };
};
