import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Button } from 'reactstrap';
import { DateFormat, Form, SubmitButton } from '@bottomless/common/components';
import { READABLE_FORMAT } from '../../../../utils/dates';
import { FrequencyInput } from '../../../../components/FrequencyInput';
import { useCallback } from 'react';
import { STEPS } from './steps.constants';

const Schema = Yup.object().shape({
  dumb_period: Yup.number().required('This field is required'),
});

export const StepSwitchToFixed = ({ setNextStep, getNextOrderDateFromNow, setDumbPeriod }) => {
  const onSuccess = useCallback(() => setNextStep(STEPS.SubscriptionUpdatedFixed), [setNextStep]);
  const onClick = useCallback(() => setNextStep(STEPS.PauseReordering), [setNextStep]);

  return (
    <>
      <div className="mb-4">
        A fixed frequency subscription means your orders will be sent at fixed intervals. There is a risk of oversupply
        or undersupply.
      </div>
      <Form
        initialValues={{ dumb_period: 14 }}
        validationSchema={Schema}
        onSubmit={setDumbPeriod}
        onSuccess={onSuccess}
        className="mw-300 form-fixed-period"
      >
        {({ isSubmitting, setFieldValue, setFieldTouched, values }) => (
          <>
            <FrequencyInput
              name="dumb_period"
              value={values.dumb_period}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
            />
            <div className="d-flex justify-content-between mt-3 mb-4">
              <div className="font-weight-bolder order-heading">Scheduled fulfillment</div>
              <div>
                <DateFormat date={getNextOrderDateFromNow(values.dumb_period)} format={READABLE_FORMAT} />
              </div>
            </div>
            <div className="mb-4">
              <SubmitButton block size="default" isSubmitting={isSubmitting}>
                Switch to Fixed Subscription
              </SubmitButton>
            </div>
          </>
        )}
      </Form>
      <div className="mw-300">
        <Button onClick={onClick} color="primary" outline block>
          No, I want to cancel
        </Button>
      </div>
    </>
  );
};

StepSwitchToFixed.headingText = 'Switch to Fixed Subscription instead?';

StepSwitchToFixed.propTypes = {
  setNextStep: PropTypes.func.isRequired,
  setDumbPeriod: PropTypes.func.isRequired,
  getNextOrderDateFromNow: PropTypes.func.isRequired,
};
