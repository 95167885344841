import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const PanelSection = ({ title, subtitle, children, className, asideClassName }) => (
  <section className={classNames('mb-5 panel-section', className)}>
    {title && (
      <header className="d-flex flex-column flex-md-row align-items-md-center mb-2">
        <h4 className="mb-0">{title}</h4>
        {subtitle && <div className="ml-md-2 mt-1 text-secondary">{subtitle}</div>}
      </header>
    )}
    <aside className={asideClassName}>{children}</aside>
  </section>
);

PanelSection.propTypes = {
  title: PropTypes.node,
  subtitle: PropTypes.node,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  asideClassName: PropTypes.string,
};
