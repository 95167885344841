import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash-es';
import { Price, ProductImage, VariantAttributes, VariantPrice } from '@bottomless/common/components';
import { ShippingAndTax } from './ShippingAndTax';
import { PanelSection } from '../../../components/PanelSection';
import './Product.scss';

export const Product = ({ checkout }) => {
  const variant = useMemo(() => checkout.product.product.variants.find(v => v._id === checkout.product.variant), [
    checkout,
  ]);

  const total = useMemo(
    () =>
      variant && getTotal(variant, checkout.product, checkout.pricing_rule, checkout.dynamicPricing, 0, 0, checkout),
    [checkout, variant]
  );

  const pricingRuleDescription = useMemo(() => {
    const pricingRule = checkout.pricing_rule;
    if (!pricingRule) {
      return;
    }

    if (pricingRule?.description) {
      return checkout.pricing_rule?.description;
    }

    const discount =
      pricingRule.discount_proportion !== null && pricingRule.discount_proportion !== undefined
        ? 1 - pricingRule.discount_proportion
        : 0;

    const firstDiscount =
      pricingRule.first_bag_discount !== null && pricingRule.first_bag_discount !== undefined
        ? 1 - pricingRule.first_bag_discount
        : 0;

    if (!firstDiscount && !discount) {
      return;
    }

    if (!firstDiscount || firstDiscount === discount) {
      return `${Math.round(discount * 100)}% off all orders`;
    }

    if (!discount) {
      return `${Math.round(firstDiscount * 100)}% off first order`;
    }

    return `${Math.round(firstDiscount * 100)}% off first order and ${Math.round(discount * 100)}% off next orders`;
  }, [checkout]);

  return (
    <PanelSection>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <div className="d-flex align-items-center">
          <div className="mr-3 border rounded position-relative">
            <ProductImage product={checkout.product.product} width="70" />
            <span className="position-absolute checkout-quantity small">{checkout.quantity || 1}</span>
          </div>
          <div>
            <div>{checkout.product.product.name}</div>
            <div>
              <div className="text-secondary small">
                <VariantAttributes productVariant={checkout.product} grind={checkout.grind} hideSize />
              </div>
            </div>
          </div>
        </div>
        <div className="ml-2 d-flex flex-column justify-content-end">
          <VariantPrice checkout={checkout} variant={variant} pricingRule={checkout.pricing_rule} isFirstBag />
        </div>
      </div>

      <ShippingAndTax pricingRule={checkout.pricing_rule} />

      <hr />
      <div className="d-flex align-items-center justify-content-between font-weight-bold">
        <div>Subtotal</div>
        <div>
          <Price value={total.total} cents />
        </div>
      </div>
      {pricingRuleDescription && (
        <div className="small text-secondary mt-5">Your subscription includes {pricingRuleDescription}.</div>
      )}
    </PanelSection>
  );
};

Product.propTypes = {
  checkout: PropTypes.shape({
    dynamicPricing: PropTypes.object,
    quantity: PropTypes.number.isRequired,
    grind: PropTypes.shape({
      name: PropTypes.string,
    }),
    product: PropTypes.shape({
      product: PropTypes.shape({
        name: PropTypes.string.isRequired,
        variants: PropTypes.arrayOf(
          PropTypes.shape({
            _id: PropTypes.string.isRequired,
            size: PropTypes.number.isRequired,
          })
        ).isRequired,
      }).isRequired,
      variant: PropTypes.string.isRequired,
    }).isRequired,
    pricing_rule: PropTypes.object.isRequired,
  }).isRequired,
  shippingPrice: PropTypes.object,
};

const getTotal = (variant, orderProduct, pricingRule, dynamicPricing, shippingPrice, tax, checkout) => {
  const signup = pricingRule.signup_fee || 0;
  const shipping = shippingPrice || getShippingPrice(pricingRule, variant, dynamicPricing, !!checkout.vendor_id);
  const product = getProductPrice(pricingRule, variant, orderProduct, dynamicPricing, checkout);
  const taxInCents = tax ? Math.round(tax * 100) : 0;
  const total = signup + product + shipping + taxInCents;

  return { signup, product, shipping, total };
};

const getProductPaymentPrice = (variant, dynamicPricing, isVendorLocked) => {
  const price = dynamicPricing ? dynamicPricing.price : isVendorLocked ? variant.website_pricing : variant.price;
  const productPriceInCents = Math.round(Number(price) * 100); // convert to cents

  return productPriceInCents;
};

// eslint-disable-next-line no-unused-vars
const getProductPrice = (pricingRule, variant, product, dynamicPricing, checkout) => {
  const productCost = getProductPaymentPrice(variant, dynamicPricing, !!checkout.vendor_id);

  if (!isNil(pricingRule.first_bag_price) && variant.size < 17) {
    return pricingRule.first_bag_price;
  }

  if (pricingRule.first_bag_discount) {
    return Math.round(productCost * (pricingRule.first_bag_discount || 1));
  }

  if (pricingRule.discount_fixed) {
    return Math.max(0, productCost - pricingRule.discount_fixed);
  }

  if (pricingRule.discount_proportion) {
    return Math.round(productCost * pricingRule.discount_proportion);
  }

  return productCost;
};

const getShippingPrice = (pricingRule, variant, dynamicPricing, isVendorLocked) => {
  if (!isNaN(pricingRule.first_shipping_price)) {
    return pricingRule.first_shipping_price;
  }

  const variantPaymentPrice = getProductPaymentPrice(variant, dynamicPricing, isVendorLocked);
  if (
    isVendorLocked &&
    !isNaN(pricingRule.free_shipping_threshold) &&
    variantPaymentPrice >= pricingRule.free_shipping_threshold
  ) {
    return 0;
  }

  if (pricingRule.free_shipping) {
    return 0;
  }

  if (pricingRule.shipping_price) {
    return pricingRule.shipping_price;
  }

  return 0;
};
