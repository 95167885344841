import React, { useCallback, useState } from 'react';
import * as Yup from 'yup';
import { SubmitButton, Form, Field, DataHandlerContent } from '@bottomless/common/components';
import { useToast, useToggle } from '@bottomless/common/hooks';
import { Badge } from 'reactstrap';
import { X } from 'react-feather';
import { PanelSection } from '../../../../components/PanelSection';
import { useDiscountCode } from '../../hooks/discount-code.hook';
import { DiscountRemoveConfirmation } from './DiscountRemoveConfirmation';
import './DiscountCode.scss';

const Schema = Yup.object().shape({
  discountCode: Yup.string().required('This field is required'),
});

const initialValues = { discountCode: '' };

export const DiscountCode = () => {
  const discountCode = useDiscountCode();
  const successToast = useToast('Discount code added!');
  const [selectedDiscount, setSelectedDiscount] = useState();
  const [isModalOpen, toggleModal, setModalOpen] = useToggle(false);

  const onSuccess = useCallback(
    (data, { resetForm }) => {
      successToast();
      resetForm(initialValues);
    },
    [successToast]
  );

  const onDiscountCodeClick = useCallback(
    discount => {
      setSelectedDiscount(discount);
      setModalOpen(true);
    },
    [setModalOpen, setSelectedDiscount]
  );

  return (
    <PanelSection className="discount-code-wrapper" title="Discount Codes">
      <Form
        validateOnBlur={false}
        initialValues={initialValues}
        validationSchema={Schema}
        onSubmit={discountCode.addDiscountCode}
        onSuccess={onSuccess}
      >
        {({ isSubmitting }) => (
          <div className="d-flex flex-gap-1">
            <div className="flex-grow-1">
              <Field type="text" name="discountCode" placeholder="Enter discount code" />
            </div>
            <div className="mb-3">
              <SubmitButton size="default" outline block isSubmitting={isSubmitting}>
                Add
              </SubmitButton>
            </div>
          </div>
        )}
      </Form>
      <DataHandlerContent {...discountCode}>
        <div className="d-flex flex-gap-1 flex-wrap">
          {(discountCode?.data || []).map(code => (
            <div key={code._id} className="d-flex discount-code">
              <Badge color="primary">
                <div className="text-md">{code.title}</div>
              </Badge>
              <Badge color="primary" onClick={() => onDiscountCodeClick(code)}>
                <X size="14" />
              </Badge>
            </div>
          ))}
        </div>
      </DataHandlerContent>
      {selectedDiscount && (
        <DiscountRemoveConfirmation
          isOpen={isModalOpen}
          toggle={toggleModal}
          discount={selectedDiscount}
          onRemove={discountCode.deleteDiscountCode}
        />
      )}
    </PanelSection>
  );
};
