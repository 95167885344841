import React, { useCallback, useMemo } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Field, Form, SubmitButton } from '@bottomless/common/components';
import { STEPS } from './steps.constants';
import { CloseAccountType } from '@bottomless/common/src/constants';
import { Calendar } from 'react-feather';
import { utcDate } from '@bottomless/common/utils';

const pauseSchema = Yup.object().shape({
  pausedUntil: Yup.date()
    .typeError('Invalid Date')
    .required('This field is required'),
});

const closeSchema = Yup.object().shape({
  type: Yup.string().required('This field is required'),
});

export const StepPauseOrdering = ({ me, setNextStep, closeAccount, pauseAccount }) => {
  const onPauseSuccess = useCallback(() => setNextStep(STEPS.ReorderingPaused), [setNextStep]);
  const onCancelSuccess = useCallback(() => setNextStep(STEPS.SubscriptionCancelled), [setNextStep]);

  const handleSubmit = useCallback(
    data => {
      const pausedUntil = data.pausedUntil ? moment(data.pausedUntil).format('YYYY-MM-DD') : null;

      return pauseAccount({ ...data, pausedUntil });
    },
    [pauseAccount]
  );

  const { now, nextYear } = useMemo(() => {
    const now = new Date();

    const nextYear = new Date();
    nextYear.setMonth(nextYear.getMonth() + 12);

    return { now, nextYear };
  }, []);

  return (
    <>
      <div className="mb-4">Did you know that you can pause re-ordering for up to a year?</div>
      <Form
        initialValues={{ paused: true, pausedUntil: me.pausedUntil ? utcDate(me.pausedUntil) : null }}
        validationSchema={pauseSchema}
        onSubmit={handleSubmit}
        onSuccess={onPauseSuccess}
        className="mw-300 form-fixed-period"
      >
        {({ isSubmitting }) => (
          <>
            <div className="text-left input-date-calendar position-relative">
              <Field name="pausedUntil" type="date" label="Pause until" min={now} max={nextYear} />
              <Calendar
                strokeWidth="1"
                width={14}
                height={14}
                className="icon-calendar position-absolute text-secondary"
              />
            </div>
            <div className="mb-4">
              <SubmitButton block size="default" isSubmitting={isSubmitting}>
                Pause Automatic Re-ordering
              </SubmitButton>
            </div>
          </>
        )}
      </Form>
      <div className="mw-300">
        <Form
          initialValues={{ type: CloseAccountType.Hibernation }}
          validationSchema={closeSchema}
          onSubmit={closeAccount}
          onSuccess={onCancelSuccess}
          className="mw-300"
        >
          {({ isSubmitting }) => (
            <SubmitButton outline={true} size="default" block isSubmitting={isSubmitting}>
              No, I want to cancel
            </SubmitButton>
          )}
        </Form>
      </div>
    </>
  );
};

StepPauseOrdering.headingText = 'Pause re-ordering instead?';

StepPauseOrdering.propTypes = {
  me: PropTypes.shape({
    pausedUntil: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  }),
  setNextStep: PropTypes.func.isRequired,
  closeAccount: PropTypes.func.isRequired,
  pauseAccount: PropTypes.func.isRequired,
};
