import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { getMeAction, setDumbPeriodAction } from '../../../store/user';
import { getInTransitionCountAction, getUpcomingOrderAction } from '../../../store/order';

export const useSwitch = ({ place }) => {
  const dispatch = useDispatch();

  const setDumbPeriod = useCallback(
    async data => {
      const result = await dispatch(setDumbPeriodAction(data, place));

      await dispatch(getMeAction());

      await Promise.all([dispatch(getUpcomingOrderAction()), dispatch(getInTransitionCountAction())]);

      return result;
    },
    [dispatch, place]
  );

  return { setDumbPeriod };
};
