export const STEPS = {
  SwitchToFixed: 'switch-to-fixed',
  SwitchToSBU: 'switch-to-sbu',
  SubscriptionUpdatedFixed: 'subscription-updated-fixed',
  SubscriptionUpdatedSBU: 'subscription-updated-sbu',
  SubscriptionCancelled: 'subscription-cancelled',
  ReturnLabelRequested: 'return-label-requested',
  PauseReordering: 'pause-reordering',
  ReorderingPaused: 'reordering-paused',
  Undone: 'undone',
};
