import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ScaleReturn } from '../../../../components/ScaleReturn';
import { STEPS } from './steps.constants';
import { Form, SubmitButton } from '@bottomless/common/components';
import { useIsSimpleSubscription } from '../../../../hooks/use-is-simple-subscribtion.hook';
import { useIsPrepaidSubscription } from '../../../../hooks/use-is-prepaid-subscription.hook';
import { SubscriptionType } from '@bottomless/common/src/constants';

export const StepSubscriptionCancelled = ({
  setNextStep,
  requestScaleReturn,
  pauseAccount,
  originalPausedUntil,
  me,
}) => {
  const isSimpleSubscription = useIsSimpleSubscription();
  const isPrepaidSubscription = useIsPrepaidSubscription();
  const toggle = useCallback(() => setNextStep(), [setNextStep]);
  const onScaleReturnSuccess = useCallback(() => setNextStep(STEPS.ReturnLabelRequested), [setNextStep]);
  const onUndoSuccess = useCallback(() => setNextStep(STEPS.Undone), [setNextStep]);
  const userCouldHaveScale = useMemo(() => !isPrepaidSubscription && (!isSimpleSubscription || me.base_id), [
    isPrepaidSubscription,
    isSimpleSubscription,
    me,
  ]);
  const isDynamic = useMemo(() => me.subscriptionType === SubscriptionType.Dynamic, [me]);

  return (
    <>
      <div>You can resume your subscription any time in the future.</div>
      <Form
        initialValues={{
          paused: !!originalPausedUntil,
          hibernate: false,
          pausedUntil: originalPausedUntil,
          sendNotification: isDynamic,
        }}
        onSubmit={pauseAccount}
        onSuccess={onUndoSuccess}
        className="mw-300 form-fixed-period"
      >
        {({ isSubmitting }) => (
          <>
            <div className={classNames('mt-5', { 'mb-4': userCouldHaveScale })}>
              <SubmitButton block size="default" outline isSubmitting={isSubmitting}>
                Undo Cancellation
              </SubmitButton>
            </div>
          </>
        )}
      </Form>
      {userCouldHaveScale && (
        <>
          <hr className="my-5" />
          <ScaleReturn toggle={toggle} onSuccess={onScaleReturnSuccess} requestScaleReturn={requestScaleReturn}>
            You can keep the scale in case you&apos;d like to resume your subscription.
          </ScaleReturn>
        </>
      )}
    </>
  );
};

StepSubscriptionCancelled.headingText = 'Your subscription has been cancelled';

StepSubscriptionCancelled.propTypes = {
  setNextStep: PropTypes.func.isRequired,
  requestScaleReturn: PropTypes.func.isRequired,
  pauseAccount: PropTypes.func.isRequired,
  originalPausedUntil: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  me: PropTypes.shape({
    base_id: PropTypes.string,
    subscriptionType: PropTypes.string,
  }).isRequired,
};
