import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useOnce, useQueryString, useToggle } from '@bottomless/common/hooks';
import { Modal, ModalBody, ModalHeader, Button } from 'reactstrap';
import { DataHandler, DataHandlerContent, Form } from '@bottomless/common/components';
import { Products } from './Products';
import { ChosenProduct } from './ChosenProduct';
import { useToast } from '@bottomless/common/hooks';
import './ChangeProduct.scss';
import { useModal } from '../../../../hooks/use-modal.hook';
import { useConfigValue } from '../../../../hooks/use-config-value.hook';

const Schema = Yup.object().shape({
  product: Yup.string().required('This field is required'),
  variant: Yup.string().required('This field is required'),
  grind: Yup.string().optional(),
});

const initialValues = { product: '', variant: '', grind: '' };

export const ChangeProduct = ({ user, productsState, attributes, makeBottomless, block, outline, size = 'sm' }) => {
  const useProductAccountWidget = useConfigValue('useProductAccountWidget');
  const {
    params: { updateProduct },
  } = useQueryString();

  const modalProps = useModal();
  const [isOpen, toggle] = useToggle(!!updateProduct);
  const [chosenProduct, setChosenProduct] = useState();
  const form = useRef();
  const successToast = useToast('Your product has been successfully changed.');

  const toggleModal = useCallback(() => {
    productsState.execute();
    toggle();
  }, [toggle, productsState]);

  useOnce(() => {
    if (updateProduct) {
      productsState.execute();
    }
  }, [updateProduct, productsState]);

  const onProductChoose = useCallback(
    product => {
      const userVariant = user.product.product.variants.find(variant => variant._id === user.product.variant);

      const variant =
        product.variants.find(variant => variant.size === userVariant.size)?._id || product.variants[0]?._id || '';
      const grind =
        attributes.grinds.find(grind => grind._id === user.grind?._id)?._id ||
        attributes.grinds.find(grind => grind.default)?._id ||
        '';

      setChosenProduct(product);
      form.current.setFieldValue('product', product._id);
      form.current.setFieldValue('variant', variant);
      form.current.setFieldValue('grind', grind);
    },
    [form, attributes, user]
  );

  const clearProduct = useCallback(() => {
    setChosenProduct(null);
    if (form.current) {
      form.current.resetForm(initialValues);
    }
  }, [form]);

  const toggleAndClear = useCallback(() => {
    toggle();
    setTimeout(clearProduct, 300);
  }, [toggle, clearProduct]);

  const onSubmit = useCallback(({ product: productId, ...data }) => makeBottomless(productId, data), [makeBottomless]);
  const onSuccess = useCallback(() => {
    successToast();
    toggleAndClear();
  }, [successToast, toggleAndClear]);

  const onClick = useCallback(() => {
    if (useProductAccountWidget) {
      window.location.href = '/collections/subscription-by-usage';
    } else {
      toggleModal();
    }
  }, [useProductAccountWidget, toggleModal]);

  return (
    <>
      <Button color="primary" size={size} onClick={onClick} block={block} outline={outline}>
        {useProductAccountWidget ? 'Update' : 'Edit'}
      </Button>
      <Modal isOpen={isOpen} size="lg" toggle={toggleAndClear} {...modalProps} className="modal-change-product">
        <ModalHeader toggle={toggleAndClear}>Change your product</ModalHeader>
        <ModalBody>
          <Form
            innerRef={form}
            initialValues={initialValues}
            validationSchema={Schema}
            onSubmit={onSubmit}
            onSuccess={onSuccess}
          >
            {({ isSubmitting, values, setFieldValue }) => (
              <>
                <DataHandler {...productsState}>There are not other products available.</DataHandler>
                <DataHandlerContent {...productsState}>
                  {chosenProduct && (
                    <ChosenProduct
                      user={user}
                      product={chosenProduct}
                      variant={values.variant}
                      grind={values.grind}
                      attributes={attributes}
                      isSubmitting={isSubmitting}
                      onClear={clearProduct}
                      setFieldValue={setFieldValue}
                    />
                  )}
                  {!chosenProduct && <Products products={productsState.data} onChoose={onProductChoose} />}
                </DataHandlerContent>
              </>
            )}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

ChangeProduct.propTypes = {
  user: PropTypes.shape({
    product: PropTypes.shape({
      product: PropTypes.shape({
        variants: PropTypes.arrayOf(
          PropTypes.shape({
            size: PropTypes.number.isRequired,
          })
        ).isRequired,
      }),
      variant: PropTypes.string.isRequired,
    }).isRequired,
    grind: PropTypes.shape({
      _id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  productsState: PropTypes.shape({
    execute: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired,
  }).isRequired,
  attributes: PropTypes.object.isRequired,
  makeBottomless: PropTypes.func.isRequired,
  block: PropTypes.bool,
  outline: PropTypes.bool,
  size: PropTypes.string,
};
