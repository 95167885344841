import React from 'react';
import PropTypes from 'prop-types';

export const Credits = ({ credits }) => (
  <div>
    <div className="d-flex justify-content-between mb-1">
      <span>Redeemed</span>
      <span>{credits?.redeemed || 0}</span>
    </div>
    <div className="d-flex justify-content-between">
      <span>Pending</span>
      <span>{credits?.granted || 0}</span>
    </div>
    <hr className="my-2" />
    <div className="d-flex justify-content-between font-weight-bold">
      <span>Total</span>
      <span>{credits?.total || 0}</span>
    </div>
  </div>
);

Credits.propTypes = {
  credits: PropTypes.shape({
    redeemed: PropTypes.number.isRequired,
    granted: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
  }),
};
