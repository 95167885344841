import React from 'react';
import { Box } from '@bottomless/common/components';
import { PanelSection } from '../../../components/PanelSection';
import { useMe } from '../../../hooks/use-me.hook';
import './PaymentInfo.scss';

export const PaymentInfo = () => {
  const me = useMe();

  return (
    <PanelSection title="Payment">
      <Box innerClassName="d-flex align-items-center justify-content-between">
        <div>{me.stripe_brand}</div>
        <div>
          <span className="payment-asterisks">**** **** ****</span> {me.stripe_last_four}
        </div>
      </Box>
    </PanelSection>
  );
};
