import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useMediaQuery } from 'react-responsive';
import { SubmitButton, Form, Field } from '@bottomless/common/components';
import { useToggle } from '@bottomless/common/hooks';
import { datesService, utcDate } from '@bottomless/common/utils';
import { useCanSwitchToSbu } from '../../hooks/use-can-switch-to-sbu.hook';
import { SwitchToSubscriptionByUsage } from '../SwitchToSubscriptionByUsage';
import { STEPS } from './steps';
import { useMemo } from 'react';

const Schema = Yup.object().shape({
  override_fulfillment_date: Yup.date().required('This field is required'),
});

export const StepReschedule = ({ vendor, fulfillmentDate, skipOrder, setNextStep }) => {
  const isMobile = useMediaQuery({ maxDeviceWidth: 1180 });
  const canSwitchToSbu = useCanSwitchToSbu();
  const [finished, toggleFinished] = useToggle();
  const onSuccess = useCallback(() => setNextStep(STEPS.SubscriptionUpdatedSBU), [setNextStep]);

  const minDate = useMemo(() => {
    let min = datesService.getTodayInSeattle();

    if (!vendor?.exclude_fulfilment_dates) {
      return min;
    }

    const excludedDates = vendor.exclude_fulfilment_dates.map(date => utcDate(date).getTime());

    while (excludedDates.includes(min.setHours(0, 0, 0, 0))) {
      min = new Date(min.getTime() + 25 * 3600 * 1000);
    }

    return min;
  }, [vendor]);

  return (
    <>
      {!finished && (
        <>
          <Form
            initialValues={{ override_fulfillment_date: fulfillmentDate }}
            validationSchema={Schema}
            onSubmit={skipOrder}
            onSuccess={toggleFinished}
            className="mw-300"
          >
            {({ isSubmitting }) => (
              <>
                <Field type="hidden" name="override_fulfillment_date" />
                <Field
                  label="Next reorder date"
                  type="date"
                  name="override_fulfillment_date"
                  min={minDate}
                  max={new Date(Date.now() + 56 * 86400000)}
                  excludeDates={
                    vendor?.exclude_fulfilment_dates ? vendor.exclude_fulfilment_dates.map(date => utcDate(date)) : []
                  }
                  excludeWeekends={true}
                  inline={isMobile}
                />
                <div className="mb-3">
                  <SubmitButton size="default" outline block isSubmitting={isSubmitting}>
                    Confirm Reschedule
                  </SubmitButton>
                </div>
              </>
            )}
          </Form>
          <div className="text-secondary small mb-3">Note: following orders will be adjusted accordingly.</div>
          {canSwitchToSbu && (
            <>
              <div className="my-4 d-flex align-items-center">
                <hr className="my-0 w-100" />
                <span className="mx-4">or</span>
                <hr className="my-0 w-100" />
              </div>
              <SwitchToSubscriptionByUsage
                onSuccess={onSuccess}
                place="profile-skip-order"
                title="Switch to Subscribe By Usage instead?"
              />
            </>
          )}
        </>
      )}
      {finished && <div>Your order is rescheduled</div>}
    </>
  );
};

StepReschedule.headingText = 'Reschedule Order';

StepReschedule.propTypes = {
  fulfillmentDate: PropTypes.object,
  skipOrder: PropTypes.func.isRequired,
  setNextStep: PropTypes.func.isRequired,
  vendor: PropTypes.shape({
    exclude_fulfilment_dates: PropTypes.array,
  }),
};
