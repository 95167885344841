import React from 'react';
import PropTypes from 'prop-types';
import { FaqItem } from './FaqItem';
import './Faq.scss';

export const Faq = ({ elements }) => (
  <div className="faq">
    {elements.map(({ question, answer }) => (
      <FaqItem key={question} question={question} answer={answer} />
    ))}
  </div>
);

Faq.propTypes = {
  elements: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string.isRequired,
      answer: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    })
  ),
};
