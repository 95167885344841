import React from 'react';
import PropTypes from 'prop-types';
import { AddressInput, GoogleMapsLoaded, GoogleMapsLoader } from '@bottomless/common/components';
import { PanelSection } from '../../../components/PanelSection';
import { PhoneNumber } from './PhoneNumber';

export const ShippingAddress = ({ values, setFieldValue, phoneNumbers }) => (
  <PanelSection title="Delivery">
    <GoogleMapsLoader>
      <GoogleMapsLoaded>
        <AddressInput {...{ setFieldValue, values }} />
      </GoogleMapsLoaded>
    </GoogleMapsLoader>
    <PhoneNumber phoneNumbers={phoneNumbers} />
  </PanelSection>
);

ShippingAddress.propTypes = {
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  phoneNumbers: PropTypes.array.isRequired,
};
