import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Address as AddressComponent, Box } from '@bottomless/common/components';
import { OrderStatuses } from '@bottomless/common/constants';
import { PanelSection } from '../../../components/PanelSection';
import { EditAddressModal } from '../../../components/EditAddressModal';

export const Address = ({ order, user, verifyAddress }) => {
  const address = useMemo(() => (order.address?.street1 ? order.address : user.verifiedAddress), [order, user]);
  const canEditAddress = useMemo(
    () =>
      !address?.pickup &&
      [OrderStatuses.Initiated, OrderStatuses.Paid, OrderStatuses.SubproductGenerated].includes(order.status),
    [address, order]
  );

  if (!address) {
    return null;
  }

  return (
    <PanelSection title={`${address.pickup ? `Pickup at ${address.title || ''}` : 'Delivery Address'}`}>
      <Box>
        <div className="d-flex justify-content-between align-items-center">
          <div className="small">
            <div>
              {user.first_name} {user.last_name}
            </div>
            <div className="text-secondary">
              <AddressComponent address={address} />
            </div>
          </div>
          {canEditAddress && <EditAddressModal user={user} verifyAddress={verifyAddress} />}
        </div>
      </Box>
    </PanelSection>
  );
};

Address.propTypes = {
  order: PropTypes.shape({
    address: PropTypes.shape({
      street1: PropTypes.string,
    }),
    status: PropTypes.string.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    verifiedAddress: PropTypes.object.isRequired,
  }).isRequired,
  verifyAddress: PropTypes.func.isRequired,
};
