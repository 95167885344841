import React from 'react';
import PropTypes from 'prop-types';
import { Hibernate } from './Hibernate';
import { PauseOrdering } from './PauseOrdering';
import { ResumeSubscription } from '../../../components/ResumeSubscription/ResumeSubscription';

export const AccountActions = ({
  me,
  pauseAccount,
  closeAccount,
  requestScaleReturn,
  getNextOrderDateFromNow,
  getNextOrderDate,
  setDumbPeriod,
}) => (
  <div className="text-center account-actions">
    <div className="mb-3">
      <Hibernate
        me={me}
        closeAccount={closeAccount}
        requestScaleReturn={requestScaleReturn}
        getNextOrderDateFromNow={getNextOrderDateFromNow}
        setDumbPeriod={setDumbPeriod}
        pauseAccount={pauseAccount}
      />
      <ResumeSubscription me={me} pauseAccount={pauseAccount} getNextOrderDate={getNextOrderDate} />
    </div>
    <div className="mb-3">
      <PauseOrdering me={me} onSubmit={pauseAccount} />
    </div>
  </div>
);

AccountActions.propTypes = {
  me: PropTypes.object.isRequired,
  pauseAccount: PropTypes.func.isRequired,
  closeAccount: PropTypes.func.isRequired,
  requestScaleReturn: PropTypes.func.isRequired,
  getNextOrderDateFromNow: PropTypes.func.isRequired,
  getNextOrderDate: PropTypes.func.isRequired,
  setDumbPeriod: PropTypes.func.isRequired,
};
