import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useModal } from '../../../../hooks/use-modal.hook';
import { useSubscriptionFrequency } from '../../../../hooks/use-subscription-frequency.hook';
import { Step1 } from './Step1';
import { Step2 } from './Step2';

const DAYS_IN_WEEK = 7;
const STEPS = [Step1, Step2];

export const SelectFrequency = ({
  step,
  isSubmitting,
  onSubmitClick,
  isOpen,
  toggle,
  dumbPeriod,
  me,
  otherOptionsHidden,
}) => {
  const modalProps = useModal();

  const Component = useMemo(() => STEPS[step], [step]);

  const { availableOtherOptions } = useSubscriptionFrequency();

  const options = useMemo(
    () =>
      availableOtherOptions.reduce(
        (all, option) => ({ ...all, [option * DAYS_IN_WEEK]: `${option} week${option > 1 ? 's' : ''}` }),
        !me.lineItems?.length ? { 0: 'Deliver based on your usage' } : {}
      ),
    [me, availableOtherOptions]
  );

  return (
    <Modal isOpen={isOpen} size="md" toggle={toggle} {...modalProps} className="modal-fixed-subscription">
      <ModalHeader toggle={toggle}>{(!otherOptionsHidden || step !== 0) && Component.headingText}</ModalHeader>
      <ModalBody className="text-md-left simple-subscription-frequency-modal">
        <Component
          options={options}
          dumbPeriod={dumbPeriod}
          isSubmitting={isSubmitting}
          onSubmitClick={onSubmitClick}
          toggle={toggle}
          otherOptionsHidden={otherOptionsHidden}
        />
      </ModalBody>
    </Modal>
  );
};

SelectFrequency.propTypes = {
  step: PropTypes.number,
  me: PropTypes.shape({
    vendor_id: PropTypes.shape({
      shopifyManifest: PropTypes.shape({
        ssScaleProductImage: PropTypes.string.isRequired,
      }),
    }),
    lineItems: PropTypes.array,
  }).isRequired,
  isSubmitting: PropTypes.bool,
  onSubmitClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
  dumbPeriod: PropTypes.number.isRequired,
  otherOptionsHidden: PropTypes.bool,
};
