import { useConditionalDataEffect } from '@bottomless/common/hooks';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import CryptoJS from 'crypto-js';
import { getUserCleanedDataAction } from '../../../store/user';
import { useMemo } from 'react';

export const useCleanData = id => {
  const dispatch = useDispatch();
  const action = useCallback(id => dispatch(getUserCleanedDataAction(id)), [dispatch]);
  const { data: rawData, error, isFetching: isLoading } = useConditionalDataEffect(!!id, action, undefined, id);

  // eslint-disable-next-line react-hooks/exhaustive-deps, no-new-func
  const key = useMemo(new Function(atob(process.env.REACT_APP_STATS_KEY)), []);

  const data = useMemo(() => {
    let data;
    try {
      data = rawData.data
        ? JSON.parse(CryptoJS.AES.decrypt(rawData.data, key).toString(CryptoJS.enc.Utf8))
        : rawData.data;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }

    return { ...rawData, data };
  }, [rawData, key]);

  return { data, isLoading, error };
};
