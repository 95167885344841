import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDataEffect } from '@bottomless/common/hooks';
import { addDiscountCodeAction, deleteDiscountCodeAction, getDiscountCodeAction } from '../../../store/discount-code';

export const useDiscountCode = () => {
  const dispatch = useDispatch();

  const action = useCallback(() => dispatch(getDiscountCodeAction()), [dispatch]);
  const { data } = useSelector(({ discountCode }) => ({ data: discountCode.data }));

  const addDiscountCode = useCallback(data => dispatch(addDiscountCodeAction(data)), [dispatch]);
  const deleteDiscountCode = useCallback(data => dispatch(deleteDiscountCodeAction(data)), [dispatch]);

  const { error, isFetching: isLoading } = useDataEffect(action);

  return { data, isLoading, error, addDiscountCode, deleteDiscountCode };
};
