import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'reactstrap';
import { useToggle } from '@bottomless/common/hooks';
import { Field } from '@bottomless/common/components';
import { useSubscriptionFrequency } from '../../hooks/use-subscription-frequency.hook';
import './FrequencyInput.scss';

const DAYS_IN_WEEK = 7;
export const OTHER_OPTIONS = [1, 2, 3, 4, 6, 8];

export const FrequencyInput = ({
  name,
  value,
  setFieldValue,
  setFieldTouched,
  onChange,
  onOtherClick,
  withOther = true,
}) => {
  const { options, availableOtherOptions, hideOthers } = useSubscriptionFrequency();

  const [otherOpen, otherToggle, otherSetoptn] = useToggle();
  const onClick = useCallback(
    value => () => {
      setFieldValue(name, value * DAYS_IN_WEEK);
      setFieldTouched(name, true);
      otherSetoptn(false);

      if (onChange) {
        onChange({ target: { value: value * DAYS_IN_WEEK } });
      }
    },
    [setFieldValue, setFieldTouched, name, otherSetoptn, onChange]
  );
  const handleOtherClick = useCallback(
    e => {
      if (withOther) {
        setFieldValue(name, availableOtherOptions[0] * DAYS_IN_WEEK);
      }

      otherToggle(e);

      if (onOtherClick) {
        onOtherClick(e);
      }
    },
    [setFieldValue, name, otherToggle, onOtherClick, withOther, availableOtherOptions]
  );

  const otherOptions = useMemo(
    () =>
      availableOtherOptions.reduce(
        (all, option) => ({ ...all, [option * DAYS_IN_WEEK]: `${option} week${option > 1 ? 's' : ''}` }),
        {}
      ),
    [availableOtherOptions]
  );

  return (
    <Row className="frequency-input-wrapper">
      {options.map(option => (
        <Col key={option} xs="6" className="mb-3 frequency-btn">
          <Button
            block
            color={!otherOpen && value === option * DAYS_IN_WEEK ? 'dark' : 'light'}
            outline={!otherOpen && value === option * DAYS_IN_WEEK}
            onClick={onClick(option)}
          >
            {option} week{option > 1 && 's'}
          </Button>
        </Col>
      ))}

      {!hideOthers && (
        <Col xs="6" className="mb-3 frequency-btn">
          <Button
            block
            color={
              otherOpen || !options.map(option => option * DAYS_IN_WEEK).includes(Number(value)) ? 'dark' : 'light'
            }
            outline={otherOpen || !options.map(option => option * DAYS_IN_WEEK).includes(Number(value))}
            onClick={handleOtherClick}
          >
            Other
          </Button>
        </Col>
      )}
      {withOther && otherOpen && !hideOthers && (
        <Col xs="12">
          <Field name={name} type="select" options={otherOptions} onChange={onChange} />
        </Col>
      )}
    </Row>
  );
};

FrequencyInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  onOtherClick: PropTypes.func,
  withOther: PropTypes.bool,
};
