import React, { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import '@power-elements/stripe-elements';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useToast, useToggle } from '@bottomless/common/hooks';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import { Box, Field, Form, SubmitButton } from '@bottomless/common/components';
import { useModal } from '../../../hooks/use-modal.hook';

const initialValues = { token: '' };

const Schema = Yup.object().shape({
  token: Yup.string().required('This field is required'),
});

export const BillingDetails = ({ me, onSubmit }) => {
  const location = useLocation();
  const queryParams = qs.parse(location.search);
  const modalProps = useModal();
  const [isOpen, toggle] = useToggle(queryParams.payment_update);
  const form = useRef();

  const [error, setError] = useState(null);
  const [isProcessing, setProcessing] = useState(false);

  const stripeRef = useRef();

  const toggleAndClear = useCallback(() => {
    toggle();
    setTimeout(() => form.current?.resetForm(initialValues), 300);
  }, [toggle, form]);

  const successToast = useToast('Your card details have been updated');

  const onSuccess = useCallback(() => {
    toggleAndClear();
    successToast();
  }, [toggleAndClear, successToast]);

  const onClick = useCallback(async () => {
    try {
      setProcessing(true);
      const { token } = await stripeRef.current.createToken();
      form.current.setFieldValue('token', token.id);
      form.current.submitForm();
      setProcessing(false);
    } catch (e) {
      setProcessing(false);
      setError(e.message);
    }
  }, [stripeRef]);

  return (
    <>
      <Box innerClassName="d-flex justify-content-between align-items-center">
        <div>
          <div className="font-weight-bold">
            {me.first_name} {me.last_name}
          </div>
          <div>XXXX XXXX XXXX {me.stripe_last_four || 'XXXX'}</div>
          {me.stripe_brand && <div className="text-secondary">{me.stripe_brand}</div>}
        </div>
        <Button color="primary" size="sm" onClick={toggle}>
          Edit
        </Button>
      </Box>

      <Modal isOpen={isOpen} size="md" toggle={toggleAndClear} {...modalProps} className="modal-change-product">
        <ModalHeader toggle={toggleAndClear}>Change your credit card</ModalHeader>
        <ModalBody>
          <Form
            innerRef={form}
            initialValues={initialValues}
            validationSchema={Schema}
            onSubmit={onSubmit}
            onSuccess={onSuccess}
          >
            {({ isSubmitting }) => (
              <>
                <div className="mb-3">
                  <stripe-elements ref={stripeRef} publishable-key={process.env.REACT_APP_STRIPE_KEY}></stripe-elements>
                  {error && <div className="text-sm text-danger mt-1">{error}</div>}
                  <Field type="hidden" name="token" />
                </div>
                <SubmitButton type="button" onClick={onClick} size={null} isSubmitting={isSubmitting || isProcessing}>
                  Save
                </SubmitButton>
              </>
            )}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

BillingDetails.propTypes = {
  me: PropTypes.shape({
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    stripe_last_four: PropTypes.string,
    stripe_brand: PropTypes.string,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
};
