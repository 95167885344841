import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

export const AccountLink = ({ children, useEmbedAccountPicker }) => {
  const to = useMemo(() => (useEmbedAccountPicker ? '/subscriptions' : '/portal-select?alreadyLogged=true'), [
    useEmbedAccountPicker,
  ]);

  return (
    <NavLink className="nav-link d-flex align-items-center" to={to}>
      {children}
    </NavLink>
  );
};

AccountLink.propTypes = {
  children: PropTypes.node,
  useEmbedAccountPicker: PropTypes.bool,
};
