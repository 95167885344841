import { useDataEffect } from '@bottomless/common/hooks';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getScaleStatusAction } from '../../../store/scale';

export const useScaleStatus = () => {
  const dispatch = useDispatch();
  const action = useCallback(() => dispatch(getScaleStatusAction()), [dispatch]);
  const { data } = useSelector(({ scale }) => ({ data: scale.status }));

  const { error, isFetching: isLoading } = useDataEffect(action);

  return { data, isLoading, error };
};
