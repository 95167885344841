import React, { useCallback, useState, useMemo, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash-es';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useModal } from '../../../../hooks/use-modal.hook';
import { useQueryString, useToggle } from '@bottomless/common/hooks';
import { Box, DataLoading, UserProduct } from '@bottomless/common/components';
import { ChangeAmount } from './ChangeAmount';
import './ChangeSimpleSubscription.scss';
import { Check } from 'react-feather';

export const ChangeSimpleSubscription = ({
  user,
  updateLineItemsAmounts: rawUpdateLineItemsAmounts,
  optimisticLineItemsAmountsUpdate,
}) => {
  const modalProps = useModal();
  const [payload, setPayload] = useState();
  const [isLoading, setLoading] = useState(false);
  const [requestsLoading, setRequestsLoading] = useState(0);
  const [showCheck, setShowCheck] = useState(false);
  const previousRequestsAmount = useRef(0);

  useEffect(() => {
    if (previousRequestsAmount.current > 0 && !requestsLoading) {
      setShowCheck(true);
      setTimeout(() => setShowCheck(false), 3000);
    }

    previousRequestsAmount.current = requestsLoading;
  }, [setShowCheck, requestsLoading]);

  const updateLineItemsAmounts = useCallback(
    async (...data) => {
      setLoading(true);
      setRequestsLoading(req => req + 1);
      try {
        const result = await rawUpdateLineItemsAmounts(...data);
        setRequestsLoading(req => req - 1);
        setLoading(false);
        return result;
      } catch (e) {
        setRequestsLoading(req => req - 1);
        setLoading(false);
        throw e;
      }
    },
    [rawUpdateLineItemsAmounts]
  );
  const debouncedUpdate = useMemo(() => debounce(updateLineItemsAmounts, 500), [updateLineItemsAmounts]);
  const loadableUpdate = useCallback(
    (...data) => {
      setLoading(true);
      optimisticLineItemsAmountsUpdate(...data);
      return debouncedUpdate(...data);
    },
    [debouncedUpdate, optimisticLineItemsAmountsUpdate]
  );

  const {
    params: { updateProduct },
  } = useQueryString();
  const [isOpen, toggle] = useToggle(!!updateProduct);

  const onChange = useCallback(
    (id, quantity) => {
      const lineItems = payload.map(listItem => (listItem._id === id ? { ...listItem, quantity } : listItem));
      setPayload(lineItems);
      loadableUpdate({ lineItems, timestamp: Date.now() });
    },
    [payload, loadableUpdate]
  );

  const onClick = useCallback(() => {
    setPayload([
      { _id: null, quantity: user.quantity || 1 },
      ...user.lineItems.map(lineItem => ({ _id: lineItem._id, quantity: lineItem.quantity })),
    ]);
    setTimeout(toggle, 0);
  }, [user, toggle]);

  return (
    <div className="change-ss-product-wrapper">
      <Button color="primary" onClick={onClick} block outline>
        Update
      </Button>
      <Modal isOpen={isOpen} size="lg" toggle={toggle} {...modalProps} className="modal-simple-subscription-product">
        <ModalHeader toggle={toggle}>Edit Subscription</ModalHeader>
        <ModalBody>
          {user.quantity > 0 && (
            <Box innerClassName="d-flex align-items-center justify-content-between">
              <UserProduct user={user} avatarProps={{ width: 65, fit: 'clip' }} withPrice />
              {isOpen && (
                <ChangeAmount
                  product={user.product.product._id}
                  lineItemId={null}
                  amount={user.quantity || 1}
                  onChange={onChange}
                  onlyOneItem={!user.lineItems.filter(lineItem => lineItem?.quantity).length}
                  isRotation={user.product.product.rotating}
                />
              )}
            </Box>
          )}
          {user.lineItems
            .filter(lineItem => lineItem.quantity)
            .map(lineItem => (
              <Box key={lineItem._id} innerClassName="d-flex align-items-center justify-content-between">
                <UserProduct
                  user={{ ...user, ...lineItem, product: { product: lineItem.product, variant: lineItem.variant } }}
                  avatarProps={{ width: 65, fit: 'clip' }}
                  withPrice
                />
                {isOpen && (
                  <ChangeAmount
                    lineItemId={lineItem._id}
                    amount={lineItem.quantity || 1}
                    onChange={onChange}
                    onlyOneItem={
                      !(user.lineItems.filter(lineItem => lineItem?.quantity).length - 1 + (user.quantity || 0))
                    }
                    isRotation={lineItem.product.rotating}
                  />
                )}
              </Box>
            ))}
          <div className="d-flex justify-content-end simple-subscription-product-loading pr-2">
            <DataLoading
              isLoading={isLoading || requestsLoading > 0}
              count={0}
              loadingText="Saving"
              withMarginTop={false}
            />
            {!isLoading && !requestsLoading && showCheck && (
              <div className="d-flex align-items-center justify-content-center">
                <Check size="20" className="mr-2" />
                <span>Saved</span>
              </div>
            )}
          </div>
          <div className="text-center">
            <a
              href="/collections/subscription-by-usage"
              className="btn btn-outline-primary add-new-products"
              title="Add new products"
            >
              Choose New Products
            </a>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

ChangeSimpleSubscription.propTypes = {
  user: PropTypes.shape({
    lineItems: PropTypes.array,
    quantity: PropTypes.number.isRequired,
    product: PropTypes.shape({
      product: PropTypes.shape({
        _id: PropTypes.string.isRequired,
        rotating: PropTypes.bool,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  updateLineItemsAmounts: PropTypes.func.isRequired,
  optimisticLineItemsAmountsUpdate: PropTypes.func.isRequired,
};
