import React from 'react';
import PropTypes from 'prop-types';
import { useToggle } from '@bottomless/common/hooks';
import { useCallback } from 'react';
import classNames from 'classnames';
import './SafeTooltip.scss';

export const SafeTooltip = ({ content, children }) => {
  const [show, , setShow] = useToggle();

  const onMouseEnter = useCallback(() => setShow(true), [setShow]);
  const onMouseLeave = useCallback(() => setShow(false), [setShow]);

  return (
    <>
      <span className="position-relative cursor-pointer">
        <span onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
          {children}
        </span>
        <div className={classNames('tooltip tooltip-safe bs-tooltip-top', { show })} role="tooltip">
          <div className="arrow"></div>
          <div className="tooltip-inner">{content}</div>
        </div>
      </span>
    </>
  );
};

SafeTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
};
