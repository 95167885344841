import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { ChevronRight } from 'react-feather';
import { Image } from '@bottomless/common/components';

export const GeneralAccount = ({ me }) => {
  const lastOrderDate = useMemo(() => {
    const shopifyCustomerData = window.BottomlessAccount?.customer?.latestNonBottomlessOrder?.createdAt;

    if (!shopifyCustomerData) {
      return null;
    }

    return moment.duration(moment().diff(moment(shopifyCustomerData, 'YYYY-MM-DD HH:mm:ss Z'))).humanize();
  }, []);

  const onClick = useCallback(() => {
    window.location.href = '/account';
  }, []);

  if (!lastOrderDate) {
    return null;
  }

  return (
    <>
      <div onClick={onClick} className="account-container d-flex align-items-center cursor-pointer pl-0 pt-0 pb-0 mb-3">
        <div className="d-flex align-items-center">
          <Image
            src={me?.vendor_id?.shopifyManifest?.portalSelectImages?.general}
            width="80"
            className="account-avatar"
            alt="other account"
          />
          <div className="ml-3">
            <div className="font-weight-bold">Others</div>
            <div className="small">One-time orders / Other subscriptions</div>
            <div className="small text-secondary font-italic">Last order: {lastOrderDate} ago</div>
          </div>
        </div>

        <div className="pl-2 pl-md-3 text-secondary box-chevron">
          <ChevronRight strokeWidth="2" width="24" height="24" />
        </div>
      </div>
    </>
  );
};

GeneralAccount.propTypes = {
  me: PropTypes.shape({
    vendor_id: PropTypes.shape({
      shopifyManifest: PropTypes.shape({
        portalSelectImages: PropTypes.shape({
          general: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};
