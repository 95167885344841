import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Form, GoogleMapsLoader } from '@bottomless/common/components';
import { CheckoutSource } from '@bottomless/common/src/constants';

const AddressSchema = Yup.object().shape({
  verifiedAddress: Yup.object()
    .shape({
      street1: Yup.string().required('This field is required'),
      city: Yup.string().required('This field is required'),
      zip: Yup.string().required('This field is required'),
      state: Yup.string().required('This field is required'),
      street2: Yup.string(),
    })
    .required(),
  frequency: Yup.string(),
  phoneUserId: Yup.string(),
});

const EMPTY_VALUES = {
  verifiedAddress: {
    state: 'AL',
    street1: '',
    street2: '',
    city: '',
    zip: '',
  },
  frequencyFixed: 7,
  frequencyDynamic: '',
};

export const CheckoutForm = ({ children, checkout, onSubmit, onSuccess, phoneNumbers }) => {
  const {
    verifiedAddress = {
      street1: EMPTY_VALUES.verifiedAddress.street1,
      street2: EMPTY_VALUES.verifiedAddress.street2,
      city: EMPTY_VALUES.verifiedAddress.city,
      zip: EMPTY_VALUES.verifiedAddress.zip,
    },
  } = checkout;

  return (
    <GoogleMapsLoader>
      <Form
        initialValues={{
          verifiedAddress: { state: 'AL', ...(verifiedAddress || {}) },
          frequency:
            checkout.source === CheckoutSource.ShopifySbu ? EMPTY_VALUES.frequencyDynamic : EMPTY_VALUES.frequencyFixed,
          phoneUserId: phoneNumbers?.length ? phoneNumbers[0]._id : '',
        }}
        validationSchema={AddressSchema}
        onSubmit={onSubmit}
        onSuccess={onSuccess}
      >
        {props => <>{children(props)}</>}
      </Form>
    </GoogleMapsLoader>
  );
};

CheckoutForm.propTypes = {
  children: PropTypes.func.isRequired,
  checkout: PropTypes.shape({
    verifiedAddress: PropTypes.object,
    source: PropTypes.string,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  phoneNumbers: PropTypes.array.isRequired,
};
