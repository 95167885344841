import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { DataHandler, DataHandlerContent, Field, Form } from '@bottomless/common/components';
import { Products } from '../ChangeProduct/Products';
import { ChosenProduct } from '../ChangeProduct/ChosenProduct';
import { useOnce, useToast } from '@bottomless/common/hooks';
import { useModal } from '../../../../hooks/use-modal.hook';
import './EditLineModal.scss';

const Schema = Yup.object().shape({
  id: Yup.string().required('This field is required'),
  product: Yup.string().required('This field is required'),
  variant: Yup.string().required('This field is required'),
  quantity: Yup.number().min(0),
  grind: Yup.string().optional(),
});

export const EditLineModal = ({ user, productsState, attributes, lineSelected, updateLineItems, isOpen, toggle }) => {
  const modalProps = useModal();
  const [chosenProduct, setChosenProduct] = useState();
  const [initialValues, setInitialValues] = useState({ id: '', product: '', variant: '', grind: '', quantity: 1 });

  const setLineItem = useCallback(
    index => {
      const lineItems = [
        {
          _id: 'default',
          product: user.product.product,
          variant: user.product.variant,
          grind: user.grind,
          quantity: user.quantity || 1,
        },
        ...user.lineItems,
      ];
      const selectedLineItem = lineItems[index];
      setChosenProduct(selectedLineItem?.product);
      setInitialValues({
        id: selectedLineItem?._id,
        product: selectedLineItem?.product?._id,
        variant: selectedLineItem?.variant,
        quantity: selectedLineItem?.quantity,
        grind: selectedLineItem?.grind?._id,
      });
    },
    [user, setChosenProduct, setInitialValues]
  );

  useEffect(() => {
    setLineItem(lineSelected);
  }, [setLineItem, isOpen, lineSelected]);

  useOnce(() => {
    productsState.execute();
  }, []);

  const form = useRef();
  const successToast = useToast('Your product has been successfully changed.');

  const onProductChoose = useCallback(
    product => {
      const userVariant = user.product.product.variants.find(variant => variant._id === user.product.variant);

      const variant =
        product.variants.find(variant => variant.size === userVariant.size)?._id || product.variants[0]?._id || '';
      const grind =
        attributes.grinds.find(grind => grind._id === user.grind?._id)?._id ||
        attributes.grinds.find(grind => grind.default)?._id ||
        '';

      setChosenProduct(product);
      form.current.setFieldValue('product', product._id);
      form.current.setFieldValue('variant', variant);
      form.current.setFieldValue('grind', grind);
    },
    [form, attributes, user]
  );

  const clearProduct = useCallback(() => {
    setChosenProduct(null);
    if (form.current) {
      form.current.resetForm(initialValues);
    }
  }, [form, initialValues]);

  const onSuccess = useCallback(() => {
    successToast();
    toggle();
  }, [successToast, toggle]);

  const onDelete = useCallback(() => {
    form.current.setFieldValue('isDelete', true);
    form.current.submitForm();
  }, []);

  return (
    <>
      <Modal isOpen={isOpen} size="lg" toggle={toggle} {...modalProps} className="modal-edit-line">
        <ModalHeader toggle={toggle}>Change your product</ModalHeader>
        <ModalBody>
          <Form
            innerRef={form}
            initialValues={initialValues}
            validationSchema={Schema}
            onSubmit={updateLineItems}
            onSuccess={onSuccess}
          >
            {({ isSubmitting, values, setFieldValue }) => (
              <>
                <Field label="Line Id" type="hidden" name="id" />
                <DataHandler {...productsState}>There are no other products available.</DataHandler>
                <DataHandlerContent {...productsState}>
                  {chosenProduct && (
                    <ChosenProduct
                      user={user}
                      product={chosenProduct}
                      variant={values.variant}
                      grind={values.grind}
                      attributes={attributes}
                      isSubmitting={isSubmitting}
                      onClear={clearProduct}
                      setFieldValue={setFieldValue}
                      quantity={values.quantity}
                      onDelete={user.lineItems.length ? onDelete : undefined}
                    />
                  )}
                  {!chosenProduct && <Products products={productsState.data} onChoose={onProductChoose} />}
                </DataHandlerContent>
              </>
            )}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

EditLineModal.propTypes = {
  user: PropTypes.shape({
    product: PropTypes.shape({
      product: PropTypes.shape({
        variants: PropTypes.arrayOf(
          PropTypes.shape({
            size: PropTypes.number.isRequired,
          })
        ).isRequired,
      }),
      variant: PropTypes.string.isRequired,
    }).isRequired,
    grind: PropTypes.shape({
      _id: PropTypes.string.isRequired,
    }),
    lineItems: PropTypes.array,
    quantity: PropTypes.number,
  }).isRequired,
  productsState: PropTypes.shape({
    execute: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired,
  }).isRequired,
  attributes: PropTypes.object.isRequired,
  updateLineItems: PropTypes.func.isRequired,
  lineSelected: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};
