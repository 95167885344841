import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useToggle } from '@bottomless/common/hooks';
import { useLocation } from 'react-router-dom';
import { Play } from 'react-feather';
import qs from 'query-string';
import { useModal } from '../../hooks/use-modal.hook';
import { STEPS } from './steps.constants';
import { StepResumeSubscription } from './StepResumeSubscription';
import { StepResumeDone } from './StepResumeDone';
import './ResumeSubscription.scss';

const COMPONENTS = {
  [STEPS.Resume]: StepResumeSubscription,
  [STEPS.Done]: StepResumeDone,
};

export const ResumeSubscription = ({ me, pauseAccount, getNextOrderDate }) => {
  const location = useLocation();
  const queryParams = qs.parse(location.search);
  const modalProps = useModal();
  const [isOpen, rawToggle] = useToggle(me.hibernate && queryParams.resume_subscription);
  const [step, setStep] = useState(STEPS.Resume);

  const Component = useMemo(() => COMPONENTS[step], [step]);

  const toggle = useCallback(() => {
    rawToggle();
    setTimeout(() => {
      setStep(STEPS.Resume);
    }, 300);
  }, [rawToggle, setStep]);

  const setNextStep = useCallback(nextStep => (nextStep ? setStep(nextStep) : toggle()), [setStep, toggle]);

  return (
    <>
      {me?.hibernate && (
        <Button color="primary" onClick={toggle}>
          <span>Restart Subscription</span>
          <span className="ml-2">
            <Play className="mb-1" width="16" height="16" color="#28a745" fill="#28a745" stroke="none" />
          </span>
        </Button>
      )}
      <Modal isOpen={isOpen} size="md" toggle={toggle} {...modalProps} className="modal-ordering-strategy">
        <ModalHeader toggle={toggle}>{Component.headingText}</ModalHeader>
        <ModalBody className="text-md-center">
          <Component
            me={me}
            setNextStep={setNextStep}
            pauseAccount={pauseAccount}
            getNextOrderDate={getNextOrderDate}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

ResumeSubscription.propTypes = {
  me: PropTypes.shape({
    hibernate: PropTypes.bool,
  }).isRequired,
  pauseAccount: PropTypes.func.isRequired,
  getNextOrderDate: PropTypes.func.isRequired,
};
