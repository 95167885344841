import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Field, Form, SubmitButton, Switch } from '@bottomless/common/components';
import { useVendorLock } from '@bottomless/common/hooks';

const TextAlertsSchema = Yup.object().shape({
  alertSettings: Yup.object()
    .shape({ gifs: Yup.bool() })
    .required('This field is required'),
  phone: Yup.string().matches(/^\+1\s.?[(]?[0-9]{3}[)]\s.?[0-9]{3}[-][0-9]{4}|^\+1[0-9]{10}/i, 'Invalid phone number'),
});

export const EditNumberForm = ({ me, onSubmit, onSuccess }) => {
  const vendor = useVendorLock();

  return (
    <Form
      initialValues={{ alertSettings: me.alertSettings || { gifs: true }, phone: me.phone }}
      validationSchema={TextAlertsSchema}
      onSubmit={onSubmit}
      onSuccess={onSuccess}
    >
      {({ isSubmitting, dirty }) => (
        <>
          <Field name="phone" type="phone" label="Phone number" />
          {(!vendor || vendor.useGifs) && <Switch name="alertSettings.gifs" label="Gif alerts" />}
          <div className="text-center">
            <SubmitButton isSubmitting={isSubmitting} disabled={!dirty}>
              Save
            </SubmitButton>
          </div>
        </>
      )}
    </Form>
  );
};

EditNumberForm.propTypes = {
  me: PropTypes.shape({
    phone: PropTypes.string,
    alertSettings: PropTypes.object,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};
