import * as Sentry from '@sentry/browser';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { DataLoading } from '@bottomless/common/components';
import { useScript } from '@bottomless/common/hooks';
import { StripeLoaderContext, StripeLoaderProvider } from '../../context/stripe-loader.context';
import { StripeProvider } from 'react-stripe-elements';

export const StripeLoader = ({ children }) => {
  const [loaded, error] = useScript('https://js.stripe.com/v3/');

  if (error) {
    Sentry.captureException(error);
  }

  return <StripeLoaderProvider value={{ loaded, error }}>{children}</StripeLoaderProvider>;
};

StripeLoader.propTypes = {
  children: PropTypes.node.isRequired,
};

export const StripeLoaded = ({ children }) => {
  const { loaded, error } = useContext(StripeLoaderContext);
  const [stripe, setStripe] = useState(null);

  useEffect(() => {
    if (loaded && !error && !stripe && window.Stripe) {
      setStripe(window.Stripe(process.env.REACT_APP_STRIPE_KEY));
    }
  }, [error, loaded, stripe]);

  if (loaded && !error) {
    return <StripeProvider stripe={stripe}>{children}</StripeProvider>;
  }

  return <DataLoading count={0} isLoading={true} loadingText="Loading stripe" />;
};

StripeLoaded.propTypes = {
  children: PropTypes.node.isRequired,
};
