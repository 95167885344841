import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SubscriptionType } from '@bottomless/common/constants';
import { useConditionalDataEffect } from '@bottomless/common/hooks';
import { getCreditsAction } from '../../../store/user';

export const useCredits = () => {
  const dispatch = useDispatch();
  const action = useCallback(() => dispatch(getCreditsAction()), [dispatch]);
  const { me } = useSelector(({ user }) => ({ me: user.me }));
  const { data } = useSelector(({ user }) => ({ data: user.credits?.total ? user.credits : null }));

  const { error, isFetching: isLoading } = useConditionalDataEffect(
    me?.subscriptionType === SubscriptionType.Prepaid,
    action
  );

  return { data, isLoading, error };
};
