import moment from 'moment';
import { useDataEffect, useToast } from '@bottomless/common/hooks';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  cancelOrderAction,
  getInTransitionCountAction,
  getUpcomingOrderAction,
  skipOrderAction,
  triggerOrderAction,
} from '../../../store/order/order.actions';
import { overrideDateAction } from '../../../store/order';
import { verifyAddressAction } from '../../../store/user';

export const useUpcomingOrder = () => {
  const dispatch = useDispatch();
  const action = useCallback(() => dispatch(getUpcomingOrderAction()), [dispatch]);
  const { data } = useSelector(({ order }) => ({ data: order.upcomingOrders }));

  const triggerOrder = useCallback(
    async data => {
      const result = await dispatch(triggerOrderAction(data));

      await Promise.all([dispatch(getUpcomingOrderAction()), dispatch(getInTransitionCountAction())]);

      return result;
    },
    [dispatch]
  );

  const cancel = useCallback(
    async id => {
      const result = await dispatch(cancelOrderAction(id));

      await Promise.all([dispatch(getUpcomingOrderAction()), dispatch(getInTransitionCountAction())]);

      return result;
    },
    [dispatch]
  );

  const overrideDate = useCallback(
    async (id, { override_fulfillment_date, ...params }) => {
      const result = await dispatch(
        overrideDateAction(id, {
          override_fulfillment_date: moment(override_fulfillment_date).format('YYYY-MM-DD'),
          ...params,
        })
      );

      return result;
    },
    [dispatch]
  );

  const skipOrder = useCallback(
    async ({ override_fulfillment_date, ...params }) => {
      const result = await dispatch(
        skipOrderAction({
          override_fulfillment_date: moment(override_fulfillment_date).format('YYYY-MM-DD'),
          ...params,
        })
      );

      await dispatch(getInTransitionCountAction());

      return result;
    },
    [dispatch]
  );

  const { error, isFetching: isLoading } = useDataEffect(action);

  const verifyAddressSuccessToast = useToast('Address has been successfully saved');
  const verifyAddress = useCallback(
    async params => {
      const result = await dispatch(verifyAddressAction(params));

      verifyAddressSuccessToast();

      return result;
    },
    [dispatch, verifyAddressSuccessToast]
  );

  return { data, isLoading, error, triggerOrder, cancel, overrideDate, verifyAddress, skipOrder };
};
