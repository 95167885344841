import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { DateFormat } from '@bottomless/common/components';
import { READABLE_FORMAT } from '../../utils/dates';
import { SubscriptionType } from '@bottomless/common/constants';
import { utcDate } from '@bottomless/common/utils';

export const StepResumeDone = ({ setNextStep, me, getNextOrderDate }) => {
  const history = useHistory();
  const isDynamic = useMemo(() => me.subscriptionType === SubscriptionType.Dynamic, [me]);

  const onClose = useCallback(() => setNextStep(), [setNextStep]);

  const onUpdateBilling = useCallback(() => {
    onClose();
    history.push('/account');
  }, [onClose, history]);
  const onManageSubscription = useCallback(() => {
    onClose();
    history.push('/subscription');
  }, [onClose, history]);

  return (
    <>
      <div className="mb-4 font-weight-bold">Welcome back!</div>
      {isDynamic ? (
        <div>
          <div className="mb-3">Your automatic re-ordering has been resumed.</div>
          <div className="mb-5">You can make changes to your subscription to tailor your upcoming orders.</div>
        </div>
      ) : (
        <div>
          <div className="mb-3">Your first order has been placed.</div>
          <div className="mb-5">
            <span>You can make changes to it before it is processed on</span>{' '}
            <span className="font-weight-bold">
              <DateFormat
                date={utcDate(getNextOrderDate(me.dumb_period, false, 1, me, true))}
                format={READABLE_FORMAT}
              />
            </span>
          </div>
        </div>
      )}
      <div className="mw-300">
        <Button className="mb-4" onClick={onManageSubscription} block color="primary">
          Manage Subscription
        </Button>
        <Button className="mb-3" onClick={onUpdateBilling} block color="primary">
          Update Billing
        </Button>
      </div>
    </>
  );
};

StepResumeDone.headingText = 'Subscription Restarted';

StepResumeDone.propTypes = {
  me: PropTypes.shape({
    base_id: PropTypes.string,
    dumb_period: PropTypes.number,
    subscriptionType: PropTypes.string,
  }).isRequired,
  setNextStep: PropTypes.func.isRequired,
  getNextOrderDate: PropTypes.func.isRequired,
};
