import React from 'react';
import { Col, Row } from 'reactstrap';
import { PanelLayoutGuest } from '../../layouts/PanelLayoutGuest';

const NewMagicLinkSentComponent = () => {
  return (
    <PanelLayoutGuest>
      <h1 className="mb-4 text-center">New Link Sent</h1>
      <Row className="text-center">
        <Col>
          <p>This link has expired, but no worries, we sent you a new one!</p>
          <p className="mt-1">Please check your email or text inbox, depending on your account settings.</p>
        </Col>
      </Row>
    </PanelLayoutGuest>
  );
};

export const NewMagicLinkSentPage = NewMagicLinkSentComponent;
