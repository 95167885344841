import get from 'lodash-es/get';
import PropTypes from 'prop-types';
import React from 'react';
import { Loader } from 'react-feather';
import { Button, Col, Row } from 'reactstrap';
import { Box, DateFormat, Image } from '@bottomless/common/components';
import { useLoadingAction } from '@bottomless/common/hooks';
import { PanelSection } from '../../../components/PanelSection';

export const Order = ({ order, onFeedback, onTiming, children }) => {
  const { isLoading, execute: handleTiming } = useLoadingAction(onTiming);

  const image =
    !order.subproduct_id || !order.subproduct_id.product || !order.subproduct_id.product.small_image_src
      ? null
      : order.subproduct_id.product.small_image_src.match(/^http/)
      ? order.subproduct_id.product.small_image_src
      : process.env.REACT_APP_BACKEND_URL + order.subproduct_id.product.small_image_src;

  const isLiked = Boolean(order.product_feedback && order.product_feedback.like);
  const isDisliked = Boolean(order.product_feedback && order.product_feedback.dislike);

  const onLikeClick = e => {
    e.preventDefault();
    onFeedback({ like: !isLiked });
  };

  const onDislikeClick = e => {
    e.preventDefault();
    onFeedback({ dislike: !isDisliked });
  };

  const isTiming = timing => order.rate?.timing === timing;

  return (
    <>
      <PanelSection>
        <Row>
          <Col xs="12" md="6" className="mb-3 mb-md-0">
            <Box>
              <div className="d-flex align-items-center position-relative">
                <Image src={image} alt="Image picture" width="70" height="70" />
                <div className="m-2">
                  <div className="small text-secondary text-uppercase">
                    {get(order, 'subvendor_id.name', 'Bottomless')}
                  </div>
                  <div className="font-weight-bold">{order.subproduct_name}</div>
                </div>
                <div className="d-flex flex-column position-absolute order-likes">
                  <div className="d-flex align-items-center justify-content-end feedback">
                    <a
                      href="/"
                      onClick={onLikeClick}
                      title="I loved this"
                      className={`feedback-like ` + (isLiked ? 'selected' : '')}
                    >
                      <i className="fa fa-heart-o" aria-hidden="true" />
                    </a>
                    <span className="divider d-xs-block d-md-none" />
                    <a
                      href="/"
                      onClick={onDislikeClick}
                      title="Don’t send me this again"
                      className={`d-inline-block ml-2 feedback-dislike ` + (isDisliked ? 'selected' : '')}
                    >
                      <i className="fa fa-ban" aria-hidden="true" />
                    </a>
                  </div>
                </div>
              </div>
            </Box>
          </Col>
          <Col xs="12" md="6" className="d-flex">
            <Box className="flex-grow-1 text-center">
              <Row>
                <Col xs="6">
                  <div className="text-uppercase font-weight-bold small">Date fulfilled</div>
                  <DateFormat date={order.date_fulfilled} />
                </Col>
                <Col xs="6">
                  <div className="text-uppercase font-weight-bold small">Date arrived</div>
                  <DateFormat date={order.date_arrived} />
                </Col>
              </Row>
            </Box>
          </Col>
        </Row>
      </PanelSection>
      <PanelSection title="Rate Order Timing">
        <div className="mb-2">
          <Button
            onClick={() => handleTiming('timing', 'early')}
            className="btn-early"
            outline={!isTiming('early')}
            color={isTiming('early') ? 'success' : 'dark'}
          >
            <span role="img" aria-label="Early">
              📉
            </span>{' '}
            Early
          </Button>
          <Button
            onClick={() => handleTiming('timing', 'perfect')}
            className="btn-perfect mx-2"
            outline={!isTiming('perfect')}
            color={isTiming('perfect') ? 'primary' : 'dark'}
          >
            <span role="img" aria-label="Perfect">
              👌🏼
            </span>{' '}
            Perfect
          </Button>
          <Button
            onClick={() => handleTiming('timing', 'late')}
            className="btn-late"
            outline={!isTiming('late')}
            color={isTiming('late') ? 'danger' : 'dark'}
          >
            <span role="img" aria-label="Late">
              📈
            </span>{' '}
            Late
          </Button>
          {isLoading && <Loader size="20" className="spin ml-2" />}
        </div>
        {children}
      </PanelSection>
    </>
  );
};

Order.propTypes = {
  order: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    source: PropTypes.string,
    rate: PropTypes.shape({
      timing: PropTypes.string.isRequired,
    }),
    subvendor_id: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
    subproduct_name: PropTypes.string,
    subproduct_id: PropTypes.shape({
      variant: PropTypes.string,
      product: PropTypes.shape({
        _id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        small_image_src: PropTypes.string,
        size: PropTypes.number,
        variants: PropTypes.array.isRequired,
      }),
    }),
    product_feedback: PropTypes.shape({
      like: PropTypes.bool,
      dislike: PropTypes.bool,
    }),
    date_fulfilled: PropTypes.string.isRequired,
    date_arrived: PropTypes.string.isRequired,
  }).isRequired,
  onFeedback: PropTypes.func.isRequired,
  onTiming: PropTypes.func.isRequired,
  children: PropTypes.node,
};
