import { useDataEffect } from '@bottomless/common/hooks';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getStatsRecordsAction } from '../../../store/stats';

export const useStats = () => {
  const dispatch = useDispatch();
  const action = useCallback(() => dispatch(getStatsRecordsAction()), [dispatch]);
  const { data } = useSelector(({ stats }) => ({ data: stats.data }));

  const { error, isFetching: isLoading } = useDataEffect(action);

  return { data, isLoading, error };
};
