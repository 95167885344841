import React from 'react';
import PropTypes from 'prop-types';
import { PanelSection } from '../../../components/PanelSection';
import { Box } from '@bottomless/common/components';
import { PhoneNumber } from './PhoneNumber';

export const Pickup = ({ checkout, phoneNumbers }) => (
  <PanelSection title="Delivery">
    <Box>
      <div className="mb-1 mt-1">Pickup at {checkout.verifiedAddress.title}</div>
      <div>
        {checkout.verifiedAddress.street1}
        {!!checkout.verifiedAddress.street2 && ` ${checkout.verifiedAddress.street2}`}
      </div>
      <div>
        {checkout.verifiedAddress.city}, {checkout.verifiedAddress.state}, {checkout.verifiedAddress.zip}
      </div>
    </Box>
    <div className="mt-3">
      <PhoneNumber phoneNumbers={phoneNumbers} />
    </div>
  </PanelSection>
);

Pickup.propTypes = {
  checkout: PropTypes.shape({
    verifiedAddress: PropTypes.shape({
      title: PropTypes.string.isRequired,
      street1: PropTypes.string.isRequired,
      street2: PropTypes.string,
      city: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      zip: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  phoneNumbers: PropTypes.array.isRequired,
};
